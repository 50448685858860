import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";

async function getAll({ params, signal }) {
  try {
    return await axios.get("roles/admin", {
      data: {},
      params,
      signal,
    });
  } catch {
    snackbarStore.openSnackbar("Error al obtener los roles", "error");
  }
}

const RolService = {
  getAll,
};

export default RolService;
