import { useLocation } from "react-router-dom";
import MenuItem from "../../ui/MenuItem";

export default function InstitucionMenuList() {
  const { pathname } = useLocation();
  return (
    <>
      <MenuItem
        to="/gestionar"
        text="Gestionar documentos"
        isActive={pathname === "/" || pathname === "/gestionar"}
      />
      <MenuItem
        to="/moderar"
        text="Moderar comentarios"
        isActive={pathname === "/moderar"}
      />
      <MenuItem
        to="/historico"
        text="Historico de comentarios"
        isActive={pathname === "/historico"}
      />
    </>
  );
}
