//import React from "react";
import { observable, decorate, action } from "mobx";
import axios from "../utils/axios";
import snackbarStore from "./snackbar-store";
//import { Redirect } from "react-router-dom";
//import useUsuario from "../context/UserContext";

class LoginFormData {
  username = "";
  password = "";
  submitting = false;

  setValue(e) {
    this[e.target.name] = e.target.value;
  }
}

decorate(LoginFormData, {
  username: observable,
  password: observable,
  submitting: observable,
  setValue: action
});

class AuthStore {
  validated = false;
  isLoggedIn = false;
  user = {};
  permissions = [];
  token = null;
  form = new LoginFormData();

  /**
   * Realiza la consulta al backend, preguntando si el apikey es valido.
   * El mismo endpoint deberia retornar los datos desencriptados, en caso
   * de que el key sea aun valido
   */
  verifyCurrentStoredKey() {
    this.validationFinished(false);
  }

  validationFinished(isLoggedIn, userData) {
    this.validated = true;
    this.isLoggedIn = isLoggedIn;
  }

  /**
   *
   * @param {string} userName el nombre del usuario
   * @param {string} password  el password
   */
  doLogin(username, password) {

    axios
      .post("/jwt/token", {
        username,
        password,
        grantType: "password"
      })
      .then(response => {
        this.token = response.data.token;
        localStorage.setItem('token',JSON.stringify(response.data.token));
        axios.defaults.headers.common["Authorization"] = `Bearer ${
          response.data.token
        }`;
        //axios.defaults.headers.common["Accept-Encoding"] = `application/gzip`;
        axios.defaults.headers.common["Content-Type"] = `application/json`;
      })
      .then(() => axios.get("/jwt/user", { data: {} }))
      .then(resp => {
        this.validated = true;
        this.isLoggedIn = true;
        //AuthStore.isLoggedIn=this.isLoggedIn; //esto puso enrique
        this.user = resp.data;
        localStorage.setItem('loggedGestorUser',JSON.stringify(resp.data));


      })
      .catch(() =>
        snackbarStore.openSnackbar(
          "Error al realizar el login. Verifique sus credenciales.",
          "error"
        )
      );

  }



  doLogout() {
    this.isLoggedIn = false;
    this.validated = true;
    //TODO borrar local storage
  }
}

decorate(AuthStore, {
  validated: observable,
  isLoggedIn: observable,
  token: observable,
  user: observable,
  permissions: observable,
  form: observable,
  verifyCurrentStoredKey: action,
  validationFinished: action,
  doLogin: action,
  doLogout: action
});

const authStore = new AuthStore();
export default authStore;
