import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../header";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  appFrame: {
    minHeight: "80vh",
    overflow: "auto",
    position: "static",
    display: "flex",
    width: "100%",
    paddingBotton: "10px",
  },
  appBar: {
    position: "relative",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "appBarShift-left": {
    marginLeft: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#F1F1F1", //rgba(0, 28, 84, 0.3)
    //height: "100%",
    //backgroundColor: '#72616e',
    padding: theme.spacing(3),
    //height: "100vh",
    overflow: "auto",

    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  "content-left": {
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "contentShift-left": {
    marginLeft: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  Spacer: theme.mixins.toolbar,
  imgContainer: {
    backgroundColor: "white",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  footer: {
    width: "100px",
    color: "#F1F1F1",
    backgroundColor: "#F1F1F1",
    marginTop: theme.spacing(4),
    padding: `${theme.spacing(3)}px 0`,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  tabs: {
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    borderRadius: `${theme.spacing(0.5, 0.5)} 0 0 `,
  },
  tabIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
});

const ElearningAppBar = (props) => {
  //const [open, setOpen] = useState(false);
  const { classes, width } = props;

  const items = [
    {
      path: "/",
      label: "Conjuntos de datos",
      items: [],
    },
    {
      path: "/visualizaciones",
      label: "Visualizaciones",
    },
    {
      path: "/legal",
      label: "Asuntos Legales",
    },
    {
      path: "/about",
      label: "Acerca de",
    },
  ];
  //verificar que hacia esta funcion
  /*const getTabValue = () => {
    const pathList = props.history.location?.pathname.split('/');
    let path;
    items.forEach(function (item) {
      if(pathList.includes(item.path.substring(1))){
        path = item.path;
      }
    });
    if(!path){
      path = '/';
    }
    return path;
  }*/

  const showSubTabs = () => {
    const pathList = props.history.location?.pathname.split("/");
    let mostrar = false;
    items[0].items.forEach(function (item) {
      if (pathList.length >= 3 && pathList[2] === item.path) {
        mostrar = true;
      }
    });
    return mostrar;
  };

  const getSubTabValue = () => {
    const pathList = props.history.location?.pathname.split("/");
    let path;
    items[0].items.forEach(function (item) {
      if (pathList.includes(item.path)) {
        path = item.path;
      }
    });
    if (!path) {
      path = "datos";
    }
    return path;
  };

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.appFrame}>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {showSubTabs() && (
            <div className={classes.layout}>
              <Paper className={classes.tabs}></Paper>
            </div>
          )}
          {props.children}
        </main>
      </div>
    </div>
  );
};

ElearningAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(withWidth()(ElearningAppBar))
);
