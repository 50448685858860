import MenuItem from "../../ui/MenuItem";
import { useLocation } from "react-router-dom";

export default function AdminMenuList() {
  const { pathname } = useLocation();

  return (
    <>
      <MenuItem
        to="/usuarios"
        text="Usuarios"
        isActive={pathname === "/" || pathname === "/usuarios"}
      />
      <MenuItem
        to="/organizaciones"
        text="Instituciones (Organizaciones)"
        isActive={pathname === "/organizaciones"}
      />
      <MenuItem
        to="/tipoDocumento"
        text="Tipo de documento"
        isActive={
          pathname === "/tipoDocumento" || pathname === "/tipodocumento"
        }
      />
      <MenuItem
        to="/tipoActividad"
        text="Tipo de actividad"
        isActive={
          pathname === "/tipoActividad" || pathname === "/tipoactividad"
        }
      />
    </>
  );
}
