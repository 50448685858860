import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  inputS: {
    borderRadius: "10px",
    marginLeft: "5px",
    marginTop: "0px",
    display: "inline-block",
    backgroundColor: "#FFFFFF",
  },
}));

function RangeDateField(props) {
  const styles = useStyles();
  return (
    <TextField
      className={styles.inputS}
      variant="outlined"
      id="date"
      type="date"
      size="small"
      margin="dense"
      label="Desde"
      sx={{ width: 200 }}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  )
}

export default RangeDateField;
