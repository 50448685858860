import { TextField } from "@material-ui/core";
import React from "react";

function TipoDocumentForm({ formik }) {
  return (
    <>
      <div align="center">
        <TextField
          size="small"
          variant="outlined"
          color="primary"
          multiline
          onChange={formik.handleChange}
          fullWidth
          label="Descripción"
          name="descripcion"
          id="descripcion"
          value={formik.values.descripcion}
          error={formik.errors.descripcion && true}
          helperText={formik.errors.descripcion}
          autoFocus
        />
      </div>
      <div align="center">
        <TextField
          size="small"
          variant="outlined"
          color="primary"
          multiline
          onChange={formik.handleChange}
          fullWidth
          label="Abreviatura"
          name="abreviatura"
          id="abreviatura"
          value={formik.values.abreviatura}
          error={formik.errors.abreviatura && true}
          helperText={formik.errors.abreviatura}
          style={{ marginTop: "1rem" }}
        />
      </div>
    </>
  );
}

export default TipoDocumentForm;
