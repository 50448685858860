import axios from "axios";
import {
  ELEARNING_BASE_URL,
  ELEARNING_CREATE_TOKEN,
  ELEARNING_UPDATE_PASSWORD_TOKEN,
} from "../http-common";

const baseURL = `${ELEARNING_BASE_URL}/`;
const createToken = ELEARNING_CREATE_TOKEN;
const createURL =
  "server.php?wstoken=" +
  createToken +
  "&moodlewsrestformat=json&wsfunction=core_user_create_users";
const updatePasswordToken = ELEARNING_UPDATE_PASSWORD_TOKEN;
const updatePasswordURL =
  "server.php?wstoken=" +
  updatePasswordToken +
  "&moodlewsrestformat=json&wsfunction=core_user_update_users";

export const axiosCreateUserElearning = axios.create({
  baseURL: baseURL + createURL,
});

export const axiosUpdateUserPasswordElearning = axios.create({
  baseURL: baseURL + updatePasswordURL,
});
