import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import file_path from "../../utils/files_path";
import CopyIcon from "../icons/Copy";

export default function CopyArchivoVersionUrlForShare({
  versionNumber,
  versionUrl,
}) {
  const handleShare = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(file_path + versionUrl);
  };

  return (
    <ListItem button id="copy" onClick={handleShare}>
      <ListItemIcon>
        <CopyIcon />
      </ListItemIcon>
      <ListItemText
        primary={`Copiar enlace ${versionUrl} (Version ${versionNumber})`}
      />
    </ListItem>
  );
}
