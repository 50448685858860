import { Button, List, Popper, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ShareIcon from "@material-ui/icons/Share";
import React from "react";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D6EBFF",
    color: "#00529c",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 250,
    textAlign: "center",
  },
}))(Tooltip);

export default function DropdownShareButton({ children, onOpen }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (!anchorEl) {
      onOpen();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClick();
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl]);
  return (
    <div>
      <LightTooltip title="" arrow>
        <Button
          aria-describedby={id}
          type="button"
          onClick={handleClick}
          style={{
            color: "#00529c",
            maxWidth: "35px",
            maxHeight: "35px",
            cursor: "pointer",
            marginLeft: "10px",
            marginRight: "5px",
          }}
        >
          <ShareIcon style={{ fontSize: "1.5rem" }} />
        </Button>
      </LightTooltip>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <List
          dense
          style={{ backgroundColor: "white" }}
          onClick={() => handleClick()}
        >
          {children}
        </List>
      </Popper>
    </div>
  );
}
