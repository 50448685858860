import {
  Button,
  Grid,
  OutlinedInput,
  TextField,
  withStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import descriptorService from "../../services/descriptorservice";
import FilterIcon from "../icons/FilterIcon";
import DenseFormControl from "../material/DenseFormControl";
import DocumentoFilterMenuList from "../sidenav/menus/DocumentoFilterMenuList";
import DocumentoActionButton from "./DocumentoActionButton";
import "./DocumentoFiltro.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

function DocumentoFiltro({ classes, formik, isNormativaVigentes = false }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const fechaIsLoaded = formik.values.fecha_publicacion;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    handleClose();
    formik.handleSubmit();
  };
  const [listDescriptores, setListDescriptores] = React.useState([]);
  React.useEffect(() => {
    descriptorService.getAll().then((resp) => {
      setListDescriptores(resp.data.data);
    });
  }, []);

  return (
    <div>
      <DocumentoActionButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={classes.iconButton}
        startIcon={<FilterIcon className={classes.filterIcon} />}
        style={{ fontFamily: "DM Sans, serif", width: "100%" }}
        onClick={handleClick}
      >
        Busqueda Avanzada
      </DocumentoActionButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ top: "115px" }}
      >
        <li>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
              <DenseFormControl>
                <OutlinedInput
                  placeholder="Título"
                  name="titulo"
                  value={formik.values.titulo}
                  onChange={formik.handleChange}
                />
              </DenseFormControl>
            </Grid>
            <DocumentoFilterMenuList
              isNormativaVigentes={isNormativaVigentes}
            />
            {isNormativaVigentes && (
              <Grid item xs={12}>
                <DenseFormControl>
                  <OutlinedInput
                    placeholder="Asiento"
                    name="asiento"
                    value={formik.values.asiento}
                    onChange={formik.handleChange}
                  />
                </DenseFormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <DenseFormControl>
                <OutlinedInput
                  placeholder="Desde: Fecha de publicación"
                  className={
                    fechaIsLoaded
                      ? classes.dateInputWithValue
                      : classes.dateInput
                  }
                  name="fecha_publicacion"
                  value={formik.values.fecha_publicacion}
                  onChange={formik.handleChange}
                  type="date"
                />
              </DenseFormControl>
            </Grid>
            {isNormativaVigentes && (
              <Grid item xs={12}>
                <DenseFormControl>
                  <Autocomplete
                    size="small"
                    multiple
                    options={listDescriptores}
                    value={formik.values?.documentoDescriptorList ?? []}
                    getOptionSelected={(option, value) => {
                      return option?.id === value?.id;
                    }}
                    onChange={(_, newValue) => {
                      formik.setFieldValue("documentoDescriptorList", [
                        ...newValue,
                      ]);
                    }}
                    getOptionLabel={(option) => option?.descripcion}
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.descripcion}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Descriptores"
                      />
                    )}
                  />
                </DenseFormControl>
              </Grid>
            )}
            {isNormativaVigentes && (
              <Grid item xs={12}>
                <DenseFormControl>
                  <OutlinedInput
                    placeholder="Texto dentro del documento"
                    name="textoDelDocumento"
                    value={formik.values.textoDelDocumento}
                    onChange={formik.handleChange}
                  />
                </DenseFormControl>
              </Grid>
            )}
            <Grid item xs={12} className={classes.btnContainer}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Aplicar
              </Button>
            </Grid>
          </Grid>
        </li>
      </Menu>
    </div>
  );
}

const styles = () => ({
  iconButton: {
    backgroundColor: "#ffc31b",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "8px",
    height: "40px",
    fontFamily: "DM Sans, serif",
    fontWeight: 500,
    lineHeight: 1.75,
    "&:hover": {
      backgroundColor: "rgb(255, 195, 27, 0.7)",
    },
    "& MuiButton-startIcon.MuiButton-iconSizeSmall": {
      margin: 0,
    },
  },
  filterIcon: {
    margin: 0,
  },
  container: {
    width: "100%",
    maxWidth: "360px",
    margin: "0 8px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
  },
  dateInput: {
    "& input::before": {
      content: "attr(placeholder)",
      color: "#aaa",
      marginRight: "0.5em",
    },
    "& input::-webkit-datetime-edit": {
      color: "transparent",
    },
  },
  dateInputWithValue: {
    "& input::before": {
      content: "",
      color: "#aaa",
      marginRight: "0.5em",
    },
    "& input::-webkit-datetime-edit": {
      color: "black !important",
    },
  },
});

export default withStyles(styles)(DocumentoFiltro);
