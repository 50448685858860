import axios from "../utils/axios";

const getAll = () => {
  return axios.get("tipoactividad",{ data: {}});
};

const getAllBasic = () => {
  return axios.get("tiposactividad/basic",{ data: {}});
};

const get = id => {
  return axios.get(`tipoactividad/${id}`);
};

const create = data => {
  return axios.post("tiposactividad/nuevo", data);
};

const update = (id, data) => {
  return axios.put("tiposactividad/modificacion/", data);
};

const getList = async ({ params, signal }) => {
  return axios
    .get("tiposactividad", {
      data: {},
      params,
      signal,
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al obtener el listado de tipo de documentos",
        "error"
      );
    });
};

const TipoActividadService = {
  get,
  getAll,
  getAllBasic,
  getList,
  create,
  update
};

export default TipoActividadService;
