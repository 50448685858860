
const createSortings = (orderData) => {
  let sorting = { 
    sortingConditions : []
  };

  Object.keys(orderData).forEach(function(key) {
    let sort = {
      path: key,
      sortingType: orderData[key]
    }
    if (sort.sortingType) sorting.sortingConditions.push(sort);
  });

  return sorting;
};

export default createSortings;