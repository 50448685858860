import React from "react";
import { Route } from "react-router-dom";
import About from "./About";

const AboutRoute = props => (
  <React.Fragment>
    <Route exact path={`${props.match.url}/`} component={About} />
  </React.Fragment>
);

export default AboutRoute;
