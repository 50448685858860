import {
  Button,
  Chip,
  Checkbox,
  FormControl,
  LinearProgress,
  Paper,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import AuthService from "../../services/auth-service";
import OrganizacionesService from "../../services/organizacionservice";
import TipoActividadService from "../../services/tipoactividadsercice";
import snackbarStore from "../../stores/snackbar-store";
import { getOptionSelected } from "../../utils/autocomple-utils";
import axios from "../../utils/axios";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

let modOrganizaciones = false;
let mmodTipoActividad = false;

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    //display: "flex",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    //display: "flex",
    //flexDirection: "column",
    //alignItems: "auto",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${
      theme.spacing.unit * 3
    }px`,
  },
  orgInfo: {
    color: "#0E1820",
  },
  instituciones: {
    display: "grid",
    margin: "auto",
    padding: "auto",
  },
  institucionesfila: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  table: {
    width: "50%",
    position: "left",
  },
  field: {
    marginTop: "20px",
    marginBottom: "10px",
    display: "block",
  },
  button: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "15%",
  },
  submit: {
    //marginLeft: "auto",
    //marginRight: "auto",
    //marginRight: "auto",
    //marginTop: theme.spacing(2),
    borderRadius: "5px",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
    padding: "0",
  },
  gridContainer2: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    padding: "0",
  },
  gridItem: {
    padding: "0",
    fontSize: "8px",
    textAlign: "left",
  },
}));

export default function MiPerfilSuscrip(props) {
  const classes = useStyles();

  const [lisTipoActividad, setLisTipoActividad] = React.useState([]);
  const [tipoActi, setTipoActividad] = React.useState([]);
  const [organizacion, setOrganizacion] = React.useState([]);
  const [listOrganizacion, setListOrganizacion] = React.useState([]);
  const [keywords, setKeywords] = React.useState([]);

  React.useEffect(() => {
    retrieveTipoActividades();
    retrieveOrganizaciones();
    misSuscripcionesOrganizaciones();
    misSuscripcionesTiposActividades();
    misSuscripcionesKeywords();
  }, []);

  const retrieveTipoActividades = () => {
    TipoActividadService.getAllBasic()
      .then((response) => {
        setTipoActividad(response.data);
      })
      .catch((e) => {});
  };

  const retrieveOrganizaciones = () => {
    OrganizacionesService.getAllBasicNoParticular()
      .then((response) => {
        setOrganizacion(response.data);
      })
      .catch((e) => {});
  };

  const misSuscripcionesOrganizaciones = () => {
    const t = AuthService.getCurrentUserT();
    axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;

    axios
      .get("/suscripcion/organizaciones", {
        data: {},
      })
      .then((resp) => {
        setListOrganizacion([...resp.data]);
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
      });
  };

  const misSuscripcionesTiposActividades = () => {
    const t = AuthService.getCurrentUserT();
    axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;

    axios
      .get("/suscripcion/tipoactividades", {
        data: {},
      })
      .then((resp) => {
        setLisTipoActividad([...resp.data]);
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
      });
  };

  const misSuscripcionesKeywords = () => {
    const t = AuthService.getCurrentUserT();
    axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;

    axios
      .get("/suscripcion/keywords", {
        data: {},
      })
      .then((resp) => {
        if (resp.data == "") {
          setKeywords([]);
        } else {
          // split keywords by space to store as array
          setKeywords(resp.data.split(" "));
        }
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
      });
  };

  const [isLoading, setIsLoading] = useState(false);
  const guardarSuscripciones = () => {
    setIsLoading(true);

    // save keywords as a string from array
    const keywordsString = keywords.join(" ");

    let payload = {
      keywords: keywordsString,
    };

    if (modOrganizaciones) {
      var organizaciones = [];

      for (var i in listOrganizacion) {
        organizaciones.push(listOrganizacion[i].id);
      }
      payload.listOrganizaciones = organizaciones;
    }

    if (mmodTipoActividad) {
      var tipoActividades = [];

      for (var i in lisTipoActividad) {
        tipoActividades.push(lisTipoActividad[i].id);
      }

      payload.listTipoActividades = tipoActividades;
    }

    if (Object.keys(payload).length == 0) {
      snackbarStore.openSnackbar("No ha realizado ningún cambio.", "warning");
    } else {
      const t = AuthService.getCurrentUserT();

      axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
      //axios.defaults.headers.common["Content-Type"] = `application/json`;

      axios
        .post("suscripcion/guardar", payload)
        .then((resp) => {
          snackbarStore.openSnackbar(
            "Cambios guardados exitosamente.",
            "success"
          );
          setIsLoading(false);
        })
        .catch(() => {
          snackbarStore.openSnackbar("Error al guardar los cambios", "error");
          setIsLoading(false);
        });
    }
  };

  // Seleccionar todo
  const [allSelected, setAllSelected] = useState(false);
  const handleChange = (event, newValue) => {
    if (newValue.some((item) => item.descripcion === "Seleccionar todo")) {
      handleSelectAllClick();
    } else {
      setLisTipoActividad(newValue);
      setAllSelected(newValue.length === tipoActi.length);
    }
    mmodTipoActividad = true;
  };
  const handleSelectAllClick = () => {
    if (allSelected) {
      setLisTipoActividad([]);
      setAllSelected(false);
    } else {
      setLisTipoActividad(tipoActi);
      setAllSelected(true);
    }
  };
  const allOption = { descripcion: "Seleccionar todo" };

  const [allSelected2, setAllSelected2] = useState(false);
  const handleChange2 = (event, newValue) => {
    if (newValue.some((item) => item.abreviatura === "Seleccionar todo")) {
      handleSelectAllClick2();
    } else {
      setListOrganizacion(newValue);
      setAllSelected2(newValue.length === organizacion.length);
    }
    modOrganizaciones = true;
  };
  const handleSelectAllClick2 = () => {
    if (allSelected2) {
      setListOrganizacion([]);
      setAllSelected2(false);
    } else {
      setListOrganizacion(organizacion);
      setAllSelected2(true);
    }
  };

  const handleKeywordsChange = (event, newValue) => {
    //concatenar palabras claves con espacio
    // const prevKeywords = keywords;
    // setKeywords(`${prevKeywords} ${event.target.value}`.trim());
    setKeywords(newValue);
  };

  return (
    <React.Fragment>
      <main>
        <Paper className={classes.paper}>
          <LinearProgress color="primary" hidden={!isLoading} />
          <div align="left">
            <h2 style={{ color: "#0E1820" }}>Suscripciones</h2>
          </div>
          <br />
          <div className={classes.orgInfo}>
            <div style={{ overflow: "auto" }}>
              <h4
                style={{
                  float: "left",
                  width: "200px",
                  margin: "0",
                  padding: "0",
                }}
              >
                Instituciones
              </h4>
            </div>
          </div>

          <br />

          <Autocomplete
            multiple
            color="primary"
            id="organizaciones"
            options={[{ abreviatura: "Seleccionar todo" }, ...organizacion]}
            value={listOrganizacion}
            getOptionSelected={getOptionSelected}
            onChange={handleChange2}
            disableCloseOnSelect
            getOptionLabel={(option) => option.abreviatura}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    selected ||
                    (option.abreviatura === "Seleccionar todo" && allSelected2)
                  }
                />
                {option.abreviatura}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="Organismos y Entidades del Estado"
              />
            )}
          />

          <br />
          <div align="left">
            <h4 style={{ color: "#0E1820" }}>Tipo de Actividad</h4>
          </div>
          <br />
          <Autocomplete
            multiple
            color="primary"
            id="tipoActividad2"
            options={[allOption, ...tipoActi]}
            value={lisTipoActividad}
            getOptionSelected={getOptionSelected}
            onChange={handleChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option.descripcion}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    selected ||
                    (option.descripcion === "Seleccionar todo" && allSelected)
                  }
                />
                {option.descripcion}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="Tipo de Actividad"
              />
            )}
          />

          <br />
          <div align="left">
            <h4 style={{ color: "#0E1820" }}>Palabras claves</h4>
          </div>
          <br />
          <Autocomplete
            clearIcon={false}
            options={[]}
            freeSolo
            multiple
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip label={option} {...props({ index })} />
              ))
            }
            value={keywords}
            onChange={handleKeywordsChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="Palabras claves"
              />
            )}
          />

          <br />
          <div className={classes.button}>
            <Button
              type="submit"
              //fullWidth
              variant="contained"
              size="medium"
              color="primary"
              className={classes.submit}
              onClick={guardarSuscripciones}
            >
              GUARDAR
            </Button>
          </div>
        </Paper>
      </main>
    </React.Fragment>
  );
}
