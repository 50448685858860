import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import useIsMobileScreen from "../../hooks/useIsMobileScreen";

const styleSheet = (theme) => ({
  tablePagination: {
    paddingRight: theme.spacing(2),
    marginBottom: 5,
  },
});

/**
 * En los props, debe recibir el estado de paginacion pagerData con el siguiente formato
 *  {
 *      currentPage: number,
 *      pageSize: number,
 *      itemsCount: number
 *  }
 * Debe recibir como props los dispatches
 *  1. onPageChanged: page => {}, page es la pagina a la cual ir,
 *  2. onRowsPerPageChange: itemsCountPerPage => {}, itemsCountPerPage es la cantidad de items
 *  que se desplegaran por pagina.
 *
 * @param {Object} props - Los props del componente.
 * @return {JSX.Element}
 */
const Pager = (props) => {
  const { currentPage, pageSize, itemsCount } = props.pagerData;
  const isMobile = useIsMobileScreen();
  const labelDisplayedRows = isMobile
    ? ({ from, to, count }) => `${from}-${to} de ${count}`
    : ({ from, to, count }) =>
        `Se muestran ítems ${from} al ${to} de un total de ${count}`;

  return (
    <TablePagination
      style={{ paddingRight: "20px" }}
      component="div"
      count={itemsCount}
      rowsPerPage={pageSize}
      page={currentPage}
      labelRowsPerPage={isMobile ? "" : "Items por página:"}
      labelDisplayedRows={labelDisplayedRows}
      onPageChange={(event, page) => {
        if (event != null) {
          props.onPageChanged(page);
        }
      }}
      onRowsPerPageChange={(event) =>
        props.onChangeItemsCountPerPage(event.target.value)
      }
    />
  );
};

Pager.propTypes = {
  /**
   * Estado de paginación
   */
  pagerData: PropTypes.shape({
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    itemsCount: PropTypes.number,
  }).isRequired,

  /**
   * Función invocada cuando se hace click en los botones de paginación.
   *
   * @param {number} page - El número de página.
   */
  onPageChanged: PropTypes.func.isRequired,

  /**
   * Función invocada cuando se cambia el tamaño de página.
   *
   * @param {number} pageSize - El tamaño de página.
   */
  onChangeItemsCountPerPage: PropTypes.func.isRequired,
};

export default withStyles(styleSheet)(Pager);
