import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import { inject, observer } from "mobx-react";
import React from "react";
import DataTable2 from "../../toolkit/DataTable/DataTable2";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import axios from "../../utils/axios";
//import moment from 'moment';
//import { Route } from "react-router-dom";
//import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AuthService from "../../services/auth-service";
import snackbarStore from "../../stores/snackbar-store";
import createParams from "../../utils/tables-params";

const Dataset = (props) => {
  const { classes } = props;

  const setFavorito = (idDocumento) => {
    let esFavorito = false;
    const t = AuthService.getCurrentUserT();
    if (t != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
      axios
        .get(`/favoritos/set/${idDocumento}`, {
          data: {},
        })
        .then((resp) => {
          resp.data == "OK"
            ? window.location.reload()
            : snackbarStore.openSnackbar(
                "Error al marcar el documento como favorito",
                "error"
              );
          esFavorito = resp.data == "OK" ? true : false;
        })
        .catch(() => {
          snackbarStore.openSnackbar(
            "Error al obtener los datos del servidor",
            "error"
          );
        });
      if (esFavorito) {
        window.location.reload();
      }
    }
  };

  const getSettings = () => {
    return {
      "documento.titulo": {
        label: "TITULO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>
              {item?.documento ? item.documento.titulo : ""}
            </p>
          </TableCell>
        ),
      },
      "documento.id_usuario.organizacion.abreviatura": {
        label: "ORGANIZACION",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item?.documento?.usuario?.organizacion
              ? item.documento.usuario.organizacion?.abreviatura
              : ""}
          </TableCell>
        ),
      },
      actions: {
        label: "ACCIONES",
        sortable: false,
        filterable: false,
        cellRendering: (item) => (
          <TableCell className={classes.tableCellAction}>
            <div style={{ display: "flex", padding: 0 }}>
              <Tooltip title="Marcado como favorito" arrow>
                <Button onClick={() => setFavorito(item.documento.id)}>
                  <StarIcon style={{ color: "#00529c" }} />
                </Button>
              </Tooltip>
            </div>
          </TableCell>
        ),
      },
    };
  };
  /*prueba*/
  /*<Link  to={`/${item.id}/datos`} className={classes.button}>*/
  /* <Route exact path={`${props.match.url}/`} component={Dataset} props={props}/>*/

  const onReloadTable = () => {
    const user = AuthService.getCurrentUser();
    props.favoritos.list.queryData.setQuerys([
      {
        key: "usuario.id",
        value: user.id,
        condition: "equals",
        type: "string",
      },
    ]);

    let settings = getSettings();
    let params = createParams({ ...props.favoritos.list, settings });

    axios
      .get(`favorito`, {
        data: {},
        params,
      })
      .then((resp) => {
        props.favoritos.list.dataLoaded(resp.data.data, {
          currentPage: resp.data.meta.currentPage - 1,
          pageSize: resp.data.meta.pageSize,
          itemsCount: resp.data.meta.total,
        });
      })
      .catch(() => {
        snackbarStore.openSnackbar("Error al obtener datos", "error");
      });
  };
  return (
    <React.Fragment>
      <div>
        <ListDataProvider
          listStore={props.favoritos.list}
          render={(listProps) => (
            <DataTable2
              //title="Conjuntos de Datos"
              settings={getSettings()}
              onReloadTable={() => onReloadTable()}
              {...listProps}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};

const styles = (theme) => ({
  tableContent: {
    fontSize: "11px",
    width: "400px",
    padding: "0px",
    margin: "0px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    display: "flex",
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});

export default withStyles(styles)(inject("favoritos")(observer(Dataset)));
