//import React, {useState} from 'react';
import React from 'react';
import VueAppBar from '../VueAppbar2';
//import {Button,Checkbox,FormControlLabel,FormControl,InputAdornment,IconButton,OutlinedInput} from "@material-ui/core";
//import {Visibility,VisibilityOff} from '@material-ui/icons';
//import {InputLabel,Paper,Typography,withStyles} from "@material-ui/core";
//import snackbarStore from "../../stores/snackbar-store";
import CrearDocumento from "./crearDocumento";
import { makeStyles } from "@material-ui/core/styles";
//import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "auto",
        display: "flex",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: '100%',
            marginLeft: "auto",
            marginRight: "auto"
        }

    },
}))

const NewDoc = props => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <VueAppBar>
                <div className={classes.root}>
                    <CrearDocumento />
                </div>
            </VueAppBar>
        </React.Fragment>
    );

};


export default (NewDoc);