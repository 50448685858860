import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";

const getInstitucionesList = async ({ params, signal }) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
  axios.defaults.headers.common["Content-Type"] = `application/json`;
  try {
    return await axios.get("usuario/", {
      data: {},
      params,
      signal,
    });
  } catch {
    snackbarStore.openSnackbar(
      "Error al obtener el listado de usuarios",
      "error"
    );
  }
};

const getUserBoard = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
  axios.defaults.headers.common["Content-Type"] = `application/json`;

  return axios.get("jwt/user", { data: {} });
};

const changeStatus = async ({ id, signal }) => {
  return axios
    .put(
      "usuarios/estado",
      { id },
      { signal, "Content-Type": "application/json" }
    )
    .then((resp) => {
      snackbarStore.openSnackbar("Cambio de estado exitoso", "success");
      return resp;
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al obtener los datos del servidor",
        "error"
      );
    });
};

const UserService = {
  changeStatus,
  getInstitucionesList,
  getUserBoard,
};

export default UserService;
