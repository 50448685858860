import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
//import QueryBox from "./QueryBox";
//import DataTableToolbar from "./DataTableToolbar";
//import Paper from "@material-ui/core/Paper";
//import RefreshButton from "@material-ui/icons/Refresh";
//import IconButton from "@material-ui/core/IconButton";
//import Tooltip from "@material-ui/core/Tooltip";
//import mdp from "../../../src/images/migasdepan.png";
//import FilterListIcon from "@material-ui/icons/FilterList";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import { FilterTypes } from "../../utils/filter";
import DataDisplay from "./DataDisplay";
import FilterEditor from "./FilterEditor";

/**
 * Componente que renderiza un datatable con capacidad de filtrado, ordenado y paginación
 * de elementos. Además de permitir incorporar acciones a realizar por registro.
 */
class DataTable2 extends Component {
  state = {
    addingFilters: false,
    selectionFilterValues: {},
    defaultFilters: [],
  };

  constructor(props) {
    super(props);
    const { settings, defaultFilters } = props;
    const supportsFilters = Object.keys(settings).reduce(
      (acc, current) => acc || settings[current].filterable,
      false
    );
    this.state = {
      addingFilters: false,
      supportsFilters,
      selectionFilterValues: {},
      defaultFilters,
    };
  }

  onFilterCancel() {
    this.setState({ addingFilters: false });
  }

  onFilterRequested(baseFilter) {
    this.setState({ addingFilters: true, baseFilter });
  }

  componentDidMount() {
    this.actualizar(this.state.defaultFilters ? this.state.defaultFilters : []);
    this.props.resetDataTable();
    this.props.onReloadTable();
  }

  actualizar(filters) {
    const { settings } = this.props;
    let optionsFromSettings;
    const actual = {};
    const filtros = filters.concat(this.props.dataTableState.queryData);
    if (filtros) {
      let i;
      for (i = 0; i < filtros.length; i++) {
        let filter = filtros[i];
        if (filter.type === FilterTypes.SELECTION) {
          optionsFromSettings = settings[filter.key].selectOptions;
          if (optionsFromSettings.then) {
            optionsFromSettings.then((options) => {
              actual[filter.key] = options;
              this.setState({ selectionFilterValues: actual });
            });
          } else {
            actual[filter.key] = optionsFromSettings;
            this.setState({ selectionFilterValues: actual });
          }
        }
      }
    }
  }

  render() {
    const props = this.props;
    const classes = props.classes;
    const { supportsFilters, addingFilters, baseFilter } = this.state;
    return (
      <div className={classes.dataTableContainer}>
        {addingFilters && (
          <FilterEditor
            baseFilter={baseFilter}
            settings={props.settings}
            onFilterRemove={(filter) => props.onFilterRemove(filter)}
            onFilterAdd={(filters) => {
              props.onFilterAdd(filters);
              this.actualizar(filters);
              // props.resetPaging();
              props.resetDataTable();
              props.onReloadTable();
            }}
            onCancel={() => this.onFilterCancel()}
          />
        )}
        {props.dataTableState.itemsData.loading && (
          <LinearProgress color="primary" />
        )}
        {!props.dataTableState.itemsData.loading && (
          /*aqui Debe ir las Migas de Pan (ENRIQUE)*/
          <div className="col-sm-12 col-md-4"></div>
        )}
        <DataDisplay
          listData={props.dataTableState.itemsData}
          orderData={props.dataTableState.orderData}
          pagerData={props.dataTableState.pagerData}
          onPageChanged={(page) => {
            props.resetItemsData();
            props.onPageChanged(page);
            props.onReloadTable();
          }}
          onChangeItemsCountPerPage={(itemsCountPerPage) => {
            props.resetItemsData();
            props.onChangeItemsCountPerPage(itemsCountPerPage);
            props.onReloadTable();
          }}
          settings={props.settings}
          onOrderChanged={(key) => {
            props.resetItemsData();
            props.onOrderChanged(key);
            props.onReloadTable();
          }}
          filters={props.dataTableState.queryData}
          fileName={props.fileName}
          onDataDownload={props.onDataDownload}
        />
      </div>
    );
  }
}
/*
/* <img src={mdp} alt="MDP" />
//BOTONES DE FILTRO Y RECARGAR (ENRIQUE) para commit
 <DataTableToolbar title={props.title} description={props.description || ''} >
 {props.toolbarActions}
 <Tooltip title="Recargar">
 <IconButton
 onClick={() => {
 props.resetItemsData();
 props.onReloadTable();
 }}
 >
 <RefreshButton />
 </IconButton>
 </Tooltip>
 {supportsFilters && (
 <Tooltip title="Agregar filtro">
 <IconButton onClick={() => this.onFilterRequested()}>
 <FilterListIcon />
 </IconButton>
 </Tooltip>
 )}
 </DataTableToolbar>


 */

/*Periodicidad: Mensual
Para obtener lista filtrada
{props.onDataDownload && (
 <DataDownloader
 disabled={props.dataTableState.itemsData.loading}
 settings={props.settings}
 fileName={props.fileName}
 onDataDownload={() => props.onDataDownload()}/>
 )}*/

DataTable2.propTypes = {
  /**
   * Título del DataTable.
   */
  title: PropTypes.string,

  /**
   * Función que permite cargar los datos del datatable.
   */
  onReloadTable: PropTypes.func.isRequired,

  /**
   * Función que permite reiniciar el estado del datatable (solo paginacion y datos de item)
   */
  resetDataTable: PropTypes.func.isRequired,

  /**
   * Función que permite reiniciar el estado de los items
   */
  resetItemsData: PropTypes.func.isRequired,

  /**
   * Objeto que almacena el estado del datatable.
   */
  dataTableState: PropTypes.shape({
    /**
     * Listado de registros a mostrar en la tabla. Prop recibido por
     * el componente [DataDisplay](#datadisplay).
     */
    itemsData: PropTypes.object,

    /**
     * Configuración de ordenamiento.
     */
    orderData: PropTypes.object,

    /**
     * Configuración de paginación.
     */
    pagerData: PropTypes.object,

    /**
     * Objeto que contiene los filtros aplicados al datatable. Utilizado
     * por el componente [QueryBox](#querybox)
     */
    queryData: PropTypes.array,
  }).isRequired,

  /**
   * Función invocada cuando se hace click en los botones de paginación.
   *
   * @param {number} page - El número de página.
   */
  onPageChanged: PropTypes.func.isRequired,

  /**
   * Se utiliza para la inicializacion de los componentes [DataTableRow](#datatablerow) y
   * [DataTableHeader](#datatableheader)
   */
  settings: PropTypes.object.isRequired,

  /**
   * Función para agregar nuevos filtros
   */
  onFilterAdd: PropTypes.func.isRequired,

  /**
   * Funcion para eliminar un filtro
   */
  onFilterRemove: PropTypes.func.isRequired,

  /**
   * Función que restablece los datos de paginación.
   */
  resetPaging: PropTypes.func.isRequired,

  /**
   * Función invocada cuando se hace click sobre una columna "ordenable".
   *
   * @param {string} field - La columna afectada.
   */
  onOrderChanged: PropTypes.func.isRequired,

  /**
   * Array de elementos que se renderizaran en el toolbar como acciones del datatable
   */
  toolbarActions: PropTypes.arrayOf(PropTypes.element),
};

const styleSheet = (theme) => ({
  dataTableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  paper: {
    marginTop: theme.spacing(4),
    borderRadius: "10px",
    //display: "flex",
    //flexDirection: "column",
    //alignItems: "auto",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
});

export default withStyles(styleSheet)(DataTable2);
