import { withStyles } from "@material-ui/core/styles";

function VersionInfo({
  classes,
  isFirstVersion,
  versionId,
  versionTitle,
  versionNumber,
}) {
  return (
    <span
      htmlFor={versionId}
      className={`${classes.txtp1} ${isFirstVersion ? classes.bold : ""}`}
    >
      {versionTitle} - Versión {versionNumber}
    </span>
  );
}

const styles = () => ({
  txtp1: {
    width: "auto",
    height: "auto",
    fontSize: "16px",
    textDecoration: "none",
    color: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
});

export default withStyles(styles)(VersionInfo);
