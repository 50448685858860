import React from "react";
import { Button, IconButton } from "@material-ui/core";
import useIsMobileScreen from "../../hooks/useIsMobileScreen";

function DocumentoActionButton({ startIcon, children, ...props }) {
  const isMobile = useIsMobileScreen();
  return isMobile ? (
    <IconButton variant="contained" size="small" color="primary" {...props}>
      {startIcon}
    </IconButton>
  ) : (
    <Button
      variant="contained"
      size="small"
      color="primary"
      startIcon={startIcon}
      style={{ fontFamily: "DM Sans, serif", width: "100%" }}
      {...props}
    >
      {children}
    </Button>
  );
}

export default DocumentoActionButton;
