import OutlinedInput from "./OutlinedInput";

const defaultInputLabelProps = { shrink: true };

export default function DenseFormOutlinedInput({
  label,
  formik,
  name,
  type = "text",
  InputLabelProps = defaultInputLabelProps,
}) {
  const value = formik.values[name] ?? null;
  const hasError = !!formik.errors[name];
  const error = formik.errors[name];
  return (
    <OutlinedInput
      label={label}
      value={value}
      onChange={formik.handleChange}
      error={hasError}
      helperText={error}
      id={name}
      name={name}
      type={type}
      fullWidth
      InputLabelProps={InputLabelProps}
    />
  );
}
