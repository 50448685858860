import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import React from "react";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import cnfc from "../images/solologocnfc.png";
import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";
import LinearProgress from "@material-ui/core/LinearProgress";

const denormalize = (data) => {
  const target = {
    nombre_apellido: data.nombre_apellido || "",
    email: data.email || "",
    cargo: data.cargo || "",
    tipo_documento: data.tipo_documento || "",
    nro_documento: data.nro_documento || "",
    telefono: data.telefono || "",
    contrasena: data.contrasena || "",
    organizacion: data.organizacion?.id,
    organismoInternacional: data.organismoInternacional?.id,
  };
  return target;
};

let orgItems = [];

const onLoadBox = () => {
  if (orgItems.length === 0) {
    axios
      .get("organizaciones/basicPublicas", {
        data: {},
      })
      .then((resp) => orgItems.push(...resp.data))

      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado documentos",
          "error"
        );
      });
  }
};

//para tipo de documento personal del usuario
const tipoDocumentos = ["CI", "RUC"];

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  subtittle: {
    textAlign: "center",
  },
  registrarse: {
    textAlign: "left",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "6px",
  },
  txttitulo: {
    textAlign: "center",
    color: "#00529c",
    fontSize: "20px",
  },
});

function RegUserInst(props) {
  const { classes } = props;

  const [validado, setValidado] = React.useState(false);
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  //let history = useHistory();
  const formik = useFormik({
    initialValues: {
      nombre_apellido: "",
      email: "",
      cargo: "",
      tipo_documento: "",
      nro_documento: "",
      organizacion: null,
      telefono: "",
      contrasena: "",
      repeatPassword: "",
    },
    validationSchema: Yup.object({
      nombre_apellido: Yup.string().required(
        "El nombre y apellido es requerido"
      ),
      email: Yup.string()
        .email("Debe ser un email valido")
        .required("El email es requerido"),
      cargo: Yup.string(),
      organizacion: Yup.object()
        .nullable()
        .required("La organizacion es requerida"),
      contrasena: Yup.string()
        .required("La contraseña es requerida")
        .oneOf([Yup.ref("repeatPassword")], "Las contraseñas no son iguales"),
      repeatPassword: Yup.string()
        .required("La confirmación de contraseña es requerida")
        .oneOf([Yup.ref("contrasena")], "Las contraseñas no son iguales"),
    }),
    onSubmit: (formdata) => {
      const payload = denormalize(formdata);
      axios
        .post("usuarios/registro-institucional", payload, {
          "Content-Type": "application/json",
        })
        .then((resp) => {
          if (resp.data.mensajes) {
            snackbarStore.openSnackbar(
              "El correo ya fue asignado a un usuario",
              "error"
            );
          } else {
            setValidado(true);
          }
          //window.location.href = //opcion 1 camilo
          //history.push('/auth'); //opcion 2 camilo
        });
    },
  });

  if (validado) {
    return (
      <div>
        <Redirect
          to={{
            pathname: "/usuarios",
            state: {},
          }}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <img width="350px" src={cnfc} alt="CNFC" />
          <div style={{ width: "100%" }}>
            <LinearProgress hidden={!formik.isSubmitting} />
            <h2 className={classes.txttitulo}>Usuarios Institucionales</h2>
          </div>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Nombre y Apellido"
              value={formik.values.nombre_apellido}
              onChange={formik.handleChange}
              error={formik.errors.nombre_apellido && true}
              helperText={formik.errors.nombre_apellido}
              id="nombre_apellido"
              name="nombre_apellido"
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email && true}
              helperText={formik.errors.email}
              id="email"
              name="email"
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Cargo"
              value={formik.values.cargo}
              onChange={formik.handleChange}
              error={formik.errors.cargo && true}
              helperText={formik.errors.cargo}
              id="cargo"
              name="cargo"
            />

            <FormControl
              margin="dense"
              fullWidth
              variant="outlined"
              className={classes.inputS}
            >
              <Autocomplete
                id="tipo_documento"
                size="small"
                options={tipoDocumentos}
                value={formik.values.tipo_documento}
                onChange={(_, newTipodoc) => {
                  formik.setFieldValue("tipo_documento", newTipodoc);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //value={formik.values.tipo_documento}
                    onChange={formik.handleChange}
                    className={classes.inputS}
                    variant="outlined"
                    id="tipo_documento"
                    name="tipo_documento"
                    label="Tipo Documento"
                    error={formik.errors.tipo_documento && true}
                    helperText={formik.errors.tipo_documento}
                  />
                )}
              />
            </FormControl>

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Nro documento"
              value={formik.values.nro_documento}
              onChange={formik.handleChange}
              error={formik.errors.nro_documento && true}
              helperText={formik.errors.nro_documento}
              id="nro_documento"
              name="nro_documento"
            />

            <FormControl
              margin="dense"
              fullWidth
              variant="outlined"
              className={classes.inputS}
            >
              <Autocomplete
                id="organizacion"
                size="small"
                options={orgItems}
                onLoad={onLoadBox()}
                value={formik.values.organizacion}
                onChange={(_, newOrganizacion) => {
                  formik.setFieldValue("organizacion", newOrganizacion);
                }}
                getOptionLabel={(option) => option.abreviatura}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formik.values.organizacion}
                    onChange={formik.handleChange}
                    variant="outlined"
                    id="organizacion"
                    name="organizacion"
                    label="Organismo y Entidad del Estado"
                    error={formik.errors.organizacion && true}
                    helperText={formik.errors.organizacion}
                  />
                )}
              />
            </FormControl>

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              label="Teléfono"
              error={formik.errors.telefono && true}
              helperText={formik.errors.telefono}
              id="telefono"
              name="telefono"
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={formik.values.contrasena}
              onChange={formik.handleChange}
              label="Contraseña"
              error={formik.errors.contrasena && true}
              helperText={formik.errors.contrasena}
              id="contrasena"
              name="contrasena"
              type={values.showPassword ? "text" : "password"}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              label="Confirmar contraseña"
              error={formik.errors.repeatPassword && true}
              helperText={formik.errors.repeatPassword}
              id="repeatPassword"
              name="repeatPassword"
              type={values.showPassword ? "text" : "password"}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
            >
              CREAR
            </Button>
            <a
              href="javascript:history.back(-1)"
              style={{ textDecoration: "none" }}
            >
              <Button
                //type="submit"
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
              >
                CANCELAR
              </Button>
            </a>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(RegUserInst);
