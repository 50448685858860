import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  withStyles,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import AuthService from "../../services/auth-service";
import snackbarStore from "../../stores/snackbar-store";
import axios from "../../utils/axios";
import { axiosUpdateUserPasswordElearning } from "../../utils/axiosElearning";
const currentUserMoodleId = AuthService.getCurrentUser();

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "flex",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  subtittle: {
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: "2rem",
    marginLeft: "auto",
    marginRight: "auto",
    //marginRight: "auto",
    //marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "10px",
  },
  txttitulo: {
    textAlign: "left",
    color: "#00529c",
    fontSize: "20px",
  },
  registrarse: {
    textAlign: "left",
  },
  txtlink: {
    textDecoration: "none",
    color: "#00529c",
    fontWeight: "700",
  },
  red: {
    marginTop: "1rem",
    backgroundColor: "#ea2428",
    color: "white",
    "&:hover": {
      backgroundColor: "rgb(234, 36, 40, 0.7)",
    },
    "&.MuiIconButton-root": {
      backgroundColor: "transparent",
      color: "#ea2428",
    },
  },
});

const CambiarContrasenaDialog = ({
  classes,
  open,
  onClose,
  onChangePassword,
}) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
  };

  const handleConfirm = () => {
    onChangePassword(password, password2);
  };

  const t = AuthService.getCurrentUserT();

  const cambiarContrasena = () => {
    if (password != "" || password2 != "") {
      if (password === password2) {
        setIsLoading(true);
        const payload = { contrasena: password };

        axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
        axios.defaults.headers.common["Content-Type"] = `application/json`;

        const payloadElearning = new FormData();
        payloadElearning.append(
          "users[0][id]",
          parseInt(currentUserMoodleId.id)
        );
        payloadElearning.append("users[0][password]", password);

        axiosUpdateUserPasswordElearning
          .post("", payloadElearning, { "Content-Type": "application/json" })
          .then((resp) => resp.data)
          .then((resp) => {
            if (!resp) {
              axios
                .post("usuarios/cambiarcontrasena/", payload)
                .then(() => {
                  onClose();
                  handleConfirm();
                  snackbarStore.openSnackbar(
                    "Cambio de contraseña exitoso.",
                    "success"
                  );
                  setIsLoading(false);
                })
                .catch(() => {
                  snackbarStore.openSnackbar(
                    "Hubo un error al cambiar la contraseña",
                    "error"
                  );
                });
            } else if (resp.debuginfo) {
              snackbarStore.openSnackbar(resp.debuginfo, "error", 60000);
              setIsLoading(false);
            } else if (resp.errorcode) {
              snackbarStore.openSnackbar(resp.errorcode, "error", 60000);
              setIsLoading(false);
            } else {
              snackbarStore.openSnackbar(
                "Error al intentar cambiar la contraseña en el Moodle",
                "error",
                60000
              );
              setIsLoading(false);
            }
          });
      } else {
        snackbarStore.openSnackbar("Las contraseñas no coinciden.", "error");
      }
    } else {
      snackbarStore.openSnackbar("Debe completar ambos campos.", "error");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Cambiar Contraseña</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Establezca su nueva contraseña.
        </DialogContentText>

        <FormControl
          margin="dense"
          fullWidth
          variant="outlined"
          className={classes.inputS}
        >
          <TextField
            value={password}
            onChange={handlePasswordChange}
            id="contrasena"
            name="contrasena"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            label="Contraseña"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </FormControl>

        <FormControl
          margin="dense"
          fullWidth
          variant="outlined"
          className={classes.inputS}
        >
          <TextField
            value={password2}
            onChange={handlePassword2Change}
            id="repeatPassword"
            name="repeatPassword"
            className={classes.inputS}
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            label="Confirmar contraseña"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary">
          Cerrar
        </Button>
        <Button onClick={cambiarContrasena} variant="contained" color="primary">
          {isLoading ? (
            <CircularProgress color="secondary" size="1.8em" />
          ) : (
            "Cambiar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CambiarContrasenaDialog);
