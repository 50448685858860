import { useFormik } from "formik";
import { FilterConditions as C } from "../../utils/filter";

export default function useFilterForm({ filters, setFilters }) {
  const formik = useFormik({
    initialValues: {
      nombre_apellido:
        filters?.find((v) => v.key === "nombre_apellido")?.value ?? "",
      nro_documento:
        filters?.find((v) => v.key === "nro_documento")?.value ?? "",
      email: filters?.find((v) => v.key === "email")?.value ?? "",
      estado: filters?.find((v) => v.key === "estado")?.value ?? "",
      organizaciones:
        filters?.find((v) => v.key === "organizacion.id")?.value ?? [],
      roles: filters?.find((v) => v.key === "rol.id")?.value ?? [],
    },
    onSubmit: (formdata) => {
      const filtros = [];
      if (formdata.nombre_apellido) {
        const values = { value: formdata.nombre_apellido, condition: C.LIKE };
        filtros.push({ key: "nombre_apellido", ...values });
      }
      if (formdata.nro_documento) {
        const values = { value: formdata.nro_documento, condition: C.LIKE };
        filtros.push({ key: "nro_documento", ...values });
      }
      if (formdata.email) {
        filtros.push({
          key: "email",
          value: formdata.email,
          condition: C.LIKE,
        });
      }
      if (formdata.estado) {
        filtros.push({
          key: "estado",
          value: new Date(formdata.estado),
          condition: C.EQUALS,
        });
      }
      if (formdata.organizaciones && formdata.organizaciones.length) {
        filtros.push({
          key: "organizacion.id",
          value: formdata.organizaciones?.map((x) => x.id),
          condition: C.IN,
        });
      }
      if (formdata.roles && formdata.roles.length) {
        filtros.push({
          key: "rol.id",
          value: formdata.roles?.map((x) => x.id),
          condition: C.IN,
        });
      }
      const oldFilter = filters.filter(
        ({ key }) =>
          key !== "nombre_apellido" &&
          key !== "nro_documento" &&
          key !== "email" &&
          key !== "estado" &&
          key !== "organizacion.id" &&
          key !== "rol.id"
      );
      setFilters([...oldFilter, ...filtros]);
    },
  });
  return formik;
}
