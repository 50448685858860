import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React from "react";
import { Link } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import cnfc from "../images/solologocnfc.png";
import AuthService from "../services/auth-service";
import snackbarStore from "../stores/snackbar-store";
import { MITIC_AUTH_URL, MITIC_AUTH_CLIENT_ID } from "../http-common";

const required = (value) => {
  if (!value) {
    return snackbarStore.openSnackbar("Campo requerido");
  }
};

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  txtaviso: {
    textAlign: "center",
    color: "#00529c",
    fontSize: "15px",
  },
  registrarse: {
    marginTop: "14px",
    textAlign: "left",
    fontSize: "19px",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "10px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#00529c",
    fontWeight: "700",
    textAlign: "center",
    padding: "1pc",
  },
  txtlinkcenter: {
    textDecoration: "none",
    color: "#00529c",
    fontWeight: "700",
    cursor: "pointer",
    display: "block",
    textAlign: "center",
  },
  txtlinkRight: {
    textDecoration: "none",
    color: "#00529c",
    fontWeight: "700",
    cursor: "pointer",
    display: "block",
    textAlign: "right",
  },
  txtHelper: {
    display: "inline-block",
    fontSize: "14px",
  },
});

function Login(props) {
  const form = React.useRef();
  const checkBtn = React.useRef();
  const urlAnterior = props.match.params.urlAnterior;

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [showLoginForm, setShowLoginForm] = React.useState(false);
  const [showRegisterLink, setShowRegisterLink] = React.useState(false);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      if (username != "" && password != "") {
        AuthService.login(username, password).then(
          (resp) => {
            if (!JSON.parse(localStorage.getItem("userData"))) {
              snackbarStore.openSnackbar("Credenciales inválidas", "error");
            } else {
              if (urlAnterior) {
                props.history.push(decodeURIComponent(urlAnterior));
              } else {
                props.history.push("/");
              }
              window.location.reload();
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            snackbarStore.openSnackbar(resMessage, "error");
          }
        );
      } else {
        snackbarStore.openSnackbar(
          "Por favor, indique nombre de usuario y contraseña",
          "error"
        );
      }
    } else {
      setLoading(false);
    }
  };

  const { classes } = props;

  const handleLoginWithIE = () => {
    const queryParams = {
      clientId: MITIC_AUTH_CLIENT_ID,
      scope: "read",
      responseType: "code",
      state: getState(),
    };

    const query = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

    const url = MITIC_AUTH_URL + "/login?" + query;
    window.open(url, "_self");
  };

  const handleLoginWithoutIE = () => {
    setShowLoginForm(true);
    setShowRegisterLink(true);
  };

  const getState = () => {
    const seed = new Date().getTime();
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 48;
    let result = "";
    let timestamp = seed;
    while (result.length < length) {
      timestamp = (timestamp * 16807) % 2147483647;
      const index = Math.floor(timestamp % characters.length);
      result += characters.charAt(index);
    }
    return result;
  };

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <a href="https://www.cnfc.gov.py">
            <img width="350px" src={cnfc} alt="CNFC" />
          </a>
          <p className={classes.txtaviso}>
            Para poder comentar, elija una opcion:
          </p>
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            className={classes.submit}
            onClick={handleLoginWithIE}
          >
            -IDENTIDAD ELECTRÓNICA-
          </Button>{" "}
          {!showRegisterLink && (
            <>
              <Button
                type="button"
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                className={classes.submit}
                onClick={handleLoginWithoutIE}
              >
                -Gremios-
              </Button>{" "}
              <Button
                type="button"
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                className={classes.submit}
                onClick={handleLoginWithoutIE}
              >
                -Empresas-
              </Button>{" "}
            </>
          )}
          <br />
          {showLoginForm && (
            <Form className={classes.form} onSubmit={handleLogin} ref={form}>
              <FormControl margin="normal" fullWidth variant="outlined">
                <TextField
                  className={classes.inputS}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  id="username"
                  name="username"
                  autoComplete="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required]}
                  InputLabelProps={{ shrink: true }}
                  label="Email"
                />
              </FormControl>
              <FormControl margin="normal" fullWidth variant="outlined">
                <TextField
                  className={classes.inputS}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  id="password"
                  name="password"
                  type={values.showPassword ? "text" : "password"}
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                  autoComplete="current-password"
                  label="Contraseña"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toque para ver si contraseña"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              {/* <FormControlLabel
                value="recordar"
                control={<Checkbox color="primary" />}
                label="Recordar Contraseña"
                labelPlacement="end"
              /> */}
              <div className={classes.txtHelper}>
                Para realizar un comentario como empresa o gremio solicitar
                usuario a <a href="mailto:atn@vue.gov.py">atn@vue.gov.py</a>
              </div>
              <br />
              <Link
                to="/recuperaciondecuenta"
                className={`${classes.txtlinkRight} `}
              >
                {" "}
                ¿Olvidaste tu contraseña?
              </Link>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                className={classes.submit}
              >
                -INICIAR SESIÓN-
              </Button>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          )}
          <p className={classes.registrarse}>
            {!showLoginForm && (
              <span
                className={classes.txtlinkcenter}
                onClick={() => {
                  setShowLoginForm(true);
                }}
              >
                Backoffice
              </span>
            )}
            {/* {showRegisterLink && (
              <>
                <br />
                ¿No tiene una cuenta?
                <Link to="/registro/ciudadano" className={classes.txtlink}>
                  {" "}
                  Registrarse aquí
                </Link>
              </>
            )} */}
          </p>
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(Login);
