import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";

async function changeStatus(id, signal) {
  return axios
    .put(
      `organizacion/estado/${id}`,
      {},
      { signal, "Content-Type": "application/json" }
    )
    .then((resp) => {
      snackbarStore.openSnackbar("Cambio de estado exitoso", "success");
      return resp;
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al obtener los datos del servidor",
        "error"
      );
    });
}

const getAll = () => {
  return axios.get("organizacion", { data: {} });
};


const getListForFilters = (isNormativaVigentes) => {
  return axios.get(`organizacion/list/${isNormativaVigentes ? "vigentes" : "noVigentes" }`, { data: {} });
};

const getAllBasic = () => {
  return axios.get("organizaciones/basic", { data: {} });
};

const getAllBasicNoParticular = () => {
  return axios.get("organizaciones/det", { data: {} });
};

const getAllBasicPublicas = () => {
  return axios.get("organizaciones/basicPublicas", { data: {} });
};

const getCantidad = () => {
  return axios.get("organizaciones/cantidad", { data: {} });
};

const get = (id) => {
  return axios.get(`organizacion/${id}`);
};

const getList = async ({ params, signal }) => {
  return axios
    .get("organizaciones", {
      data: {},
      params,
      signal,
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al obtener el listado de instituciones",
        "error"
      );
    });
};

const getStatisticalData = (start, end) => {
  let query = start || end ? "?" : "";
  query = start ? `${query}start=${start.toISOString()}&` : query;
  query = end ? `${query}end=${end.toISOString()}` : query;
  return axios.get(`organizaciones/estadisticas${query}`);
};

const getStatisticalDataByOrgType = (type, start, end) => {
  let query = start || end ? "?" : "";
  query = start ? `${query}start=${start.toISOString()}&` : query;
  query = end ? `${query}end=${end.toISOString()}` : query;
  return axios.get(`organizaciones/estadisticas/type/${type}${query}`);
};

const getStatisticalDataByOrg = (start, end) => {
  let query = start || end ? "?" : "";
  query = start ? `${query}start=${start.toISOString()}&` : query;
  query = end ? `${query}end=${end.toISOString()}` : query;
  return axios.get(`organizaciones/estadisticas/org${query}`);
};

const save = async ({ payload, signal }) => {
  return axios
    .post("organizacion", payload, {
      signal,
      "Content-Type": "application/json",
    })
    .then((resp) => {
      snackbarStore.openSnackbar(
        "Institución registrada exitosamente.",
        "success"
      );
      return resp;
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al ingresar la nueva institución",
        "error"
      );
    });
};

const update = async ({ id, payload, signal }) => {
  return axios
    .put(`organizacion/${id}`, payload, {
      signal,
      "Content-Type": "application/json",
    })
    .then((resp) => {
      snackbarStore.openSnackbar(
        "Institución actualizada exitosamente.",
        "success"
      );
      return resp;
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al editar la institución",
        "error"
      );
    });
};

const OrganizationService = {
  changeStatus,
  get,
  getAll,
  getListForFilters,
  getAllBasic,
  getAllBasicPublicas,
  getAllBasicNoParticular,
  getCantidad,
  getList,
  getStatisticalData,
  getStatisticalDataByOrgType,
  getStatisticalDataByOrg,
  save,
  update,
};

export default OrganizationService;
