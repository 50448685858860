import { TableCell, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Description, PictureAsPdf } from "@material-ui/icons";
import { Input } from "reactstrap";
import { formatDate } from "../../utils/form-data";
import VersionInfo from "./VersionInfo";

function VersionTableRow({
  classes,
  currentVersionUrl,
  onCurrentVersionUrlChange,
  isFirstVersion,
  versionDate,
  versionId,
  versionNumber,
  versionTitle,
  versionUrl,
}) {
  return (
    <TableRow key={versionId}>
      <TableCell className={classes.cell}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            id={versionId}
            type="checkbox"
            bsSize="large"
            value={versionUrl}
            checked={currentVersionUrl == versionUrl}
            onChange={onCurrentVersionUrlChange}
            style={{ marginRight: "8px" }}
          />
          {versionUrl.includes("pdf") ? (
            <PictureAsPdf style={{ color: "#B20A00" }} />
          ) : (
            <Description style={{ color: "#195ABE" }} />
          )}
        </div>
      </TableCell>
      <TableCell className={classes.cell}>
        <VersionInfo
          isFirstVersion={isFirstVersion}
          versionId={versionId}
          versionTitle={versionTitle}
          versionNumber={versionNumber}
        />
      </TableCell>
      <TableCell style={{ marginRight: "0px" }} className={classes.cell}>
        {isFirstVersion ? (
          <strong>{formatDate(versionDate)}</strong>
        ) : (
          formatDate(versionDate)
        )}
      </TableCell>
    </TableRow>
  );
}

const styles = () => ({
  cell: {
    paddingTop: "1rem",
    marginTop: "1rem",
  },
});

export default withStyles(styles)(VersionTableRow);
