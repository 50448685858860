import MenuList from "./MenuList";
import SidenavStyled from "./SidenavStyled";

export default function Sidenav({
  open,
  isMobile,
  drawerWidth,
  toggleDrawer,
  windowProp,
}) {
  const window = windowProp;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <SidenavStyled
      open={open}
      isMobile={isMobile}
      onClose={toggleDrawer}
      container={container}
      width={drawerWidth}
    >
      <MenuList
        sx={{ width: drawerWidth - 1 }}
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      />
    </SidenavStyled>
  );
}
