import { useFormik } from "formik";
import { FilterConditions as C, FilterTypes as T } from "../../utils/filter";

export default function useFilterForm({ filters, setFilters }) {
  const formik = useFormik({
    initialValues: {
      titulo: filters?.find((v) => v.key === "titulo")?.value ?? "",
      asiento: filters?.find((v) => v.key === "asiento")?.value ?? "",
      fecha_publicacion:
        filters?.find((v) => v.key === "fecha_publicacion")?.value ?? "",
      documentoDescriptorList:
        filters?.find((v) => v.key === "documentoDescriptorList.descriptor.id")
          ?.value ?? [],
      textoDelDocumento: filters?.find((v) => v.key === "textoDelDocumento")?.value ?? "",
    },
    onSubmit: (formdata) => {
      const filtros = [];
      if (formdata.titulo) {
        const values = { value: formdata.titulo, condition: C.LIKE };
        filtros.push({ key: "titulo", ...values });
      }
      if (formdata.asiento) {
        filtros.push({
          key: "asiento",
          value: formdata.asiento,
          condition: C.LIKE,
        });
      }
      if (formdata.fecha_publicacion) {
        filtros.push({
          key: "fecha_publicacion",
          value: new Date(formdata.fecha_publicacion),
          condition: C.GTE,
          type: T.DATE,
        });
      }
      if (
        formdata.documentoDescriptorList &&
        formdata.documentoDescriptorList.length
      ) {
        filtros.push({
          key: "documentoDescriptorList.descriptor.id",
          value: formdata.documentoDescriptorList?.map((x) => x.id),
          condition: C.IN,
        });
      }
      if (formdata.textoDelDocumento) {
        filtros.push({
          key: "textoDelDocumento",
          value: formdata.textoDelDocumento,
          condition: C.LIKE,
        });
      }
      const oldFilter = filters.filter(
        ({ key }) =>
          key !== "titulo" &&
          key !== "asiento" &&
          key !== "fecha_publicacion" &&
          key !== "documentoDescriptorList.descriptor.id" &&
          key !== "textoDelDocumento"
      );
      setFilters([...oldFilter, ...filtros]);
    },
  });
  return formik;
}
