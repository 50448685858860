import { observable, decorate } from "mobx";
import ListData from "../toolkit/mobx-connectors/observables/list-data";

class DiccionarioStore {
  list = new ListData();
}

decorate(DiccionarioStore, {
  list: observable
});

const diccionarioStore = new DiccionarioStore();
export default diccionarioStore;
