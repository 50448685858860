import { inject, observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
//import classNames from 'classnames';
//import Button from '@material-ui/core/Button';
//import { BsFileEarmarkArrowUp } from 'react-icons/bs';
import { CircularProgress } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { useFilterContext } from "../../context/FilterContext";
import AuthService from "../../services/auth-service";
import DocumentoService from "../../services/documento-service";
import snackbarStore from "../../stores/snackbar-store";
import DataTable from "../../toolkit/DataTable/DataTable";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import axios from "../../utils/axios";
import { debounce } from "../../utils/form-data";
import createParams from "../../utils/tables-params";
import DocumentoFiltro from "../documento/DocumentoFiltro";
import useFilterForm from "../documento/useFilterForm";
import DownloadDialog from "./DownloadDialog";

function fmt(date, format = "DD-MM-YYYY") {
  return moment(date).format(format);
}

const Dataset = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [fecha, setFecha] = React.useState(null);
  const [doc, setDoc] = React.useState(null);
  const [post, setPost] = React.useState([]);
  const orderData = { orders: { fecha_publicacion: "desc" } };
  const { filters, setFilters } = useFilterContext();
  const formik = useFilterForm({ filters, setFilters });
  const abortController = new AbortController();

  const [abrirDialogoConfirmacion, setAbrirDialogoConfirmacion] =
    React.useState(false);

  const [abrirDialogoDescarga, setAbrirDialogoDescarga] = React.useState(false);

  const cerrarDialogoDescarga = () => {
    setAbrirDialogoDescarga(false);
  };

  const cerrarDialogoConfirmacion = () => {
    window.location.reload();
    setAbrirDialogoConfirmacion(false);
  };

  const currentUser = AuthService.getCurrentUser();

  const actualizarFechaLimite = () => {
    if (!doc) return;
    setLoading(true);
    const payload = { id: doc.id, fecha_limite: fecha };
    const t = AuthService.getCurrentUserT();

    if (t) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;
      axios
        .put("documento/fechalimite", payload, {
          "Content-Type": "application/json",
        })
        .then((resp) => {
          snackbarStore.openSnackbar("Cambio de fecha exitoso", "success");
          window.location.reload();
        })
        .catch(() => {
          snackbarStore.openSnackbar(
            "Error al actualizar la fecha limite del documento",
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  //Manejo de fecha limite
  //const [fechaLocal, setFechaLocal] = useState(fmt(item.fecha_limite, "YYYY-MM-DD"));
  const handleDateChange = (event, item) => {
    const newDate = event.target.value;
    const fechaS = new Date(newDate);
    fechaS.setDate(fechaS.getDate() + 1);
    debounce(() => {
      if (!item || fmt(fechaS) === fmt(item.fecha_limite)) return;
      setDoc(item);
      setFecha(fechaS);
      setAbrirDialogoConfirmacion(true); // Abrimos el diálogo de confirmación
    }, 1000);
  };

  //Manejo de Dias Vigencia
  const [abrirDialogoConfirmacionVigente, setAbrirDialogoConfirmacionVigente] =
    React.useState(false);
  const [diasVigencia, setdiasVigencia] = React.useState([]);

  const handleDiasVigenciaChange = (event, item) => {
    const newDiasVigencia = event.target.value;
    debounce(() => {
      if (!item || newDiasVigencia === item.diasVigencia) return;
      setDoc(item);
      setdiasVigencia(newDiasVigencia);
      setAbrirDialogoConfirmacionVigente(true);
    });
  };

  const cerrarDialogoConfirmacionVigente = () => {
    window.location.reload();
    setAbrirDialogoConfirmacionVigente(false);
  };

  const actualizarDiasVigencia = () => {
    if (!doc) return;
    setLoading(true);
    const payload = { id: doc.id, diasVigencia: diasVigencia };
    const t = AuthService.getCurrentUserT();

    if (t) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;
      axios
        .put("documento/diasVigencia", payload, {
          "Content-Type": "application/json",
        })
        .then((resp) => {
          snackbarStore.openSnackbar(
            "Cambio de dias para vigencia exitoso",
            "success"
          );
          window.location.reload();
        })
        .catch(() => {
          snackbarStore.openSnackbar(
            "Error al actualizar los dias para la vigencia del documento",
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const Bread = () => {
    return (
      <div className={classes.paperBreadContainer}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
          <Link to="/" className={classes.txtlink}>
            Consulta Pública de Normativas
          </Link>
          <Link to="#" className={classes.txtlink}>
            Subir y moderar
          </Link>
          <Link to="#" className={classes.txtlink}>
            <strong>Gestionar documentos</strong>
          </Link>
        </Breadcrumbs>
        <div style={{ flex: 1 }}></div>
        <DocumentoFiltro formik={formik} />
      </div>
    );
  };

  const { classes } = props;

  const getSettings = () => {
    return {
      titulo: {
        label: "TÍTULO",
        sortable: true,
        filterable: true,
        maxWidth: 500,
        minWidth: 200,
        flex: 1,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>{item.titulo}</TableCell>
        ),
      },
      fecha_publicacion: {
        label: "FECHA PUBLICACIÓN",
        sortable: true,
        filterable: true,
        type: "date",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            <p className={classes.tableContent}>
              {fmt(item.fecha_publicacion)}
            </p>
          </TableCell>
        ),
      },
      fecha_limite: {
        label: "FECHA LIMITE",
        sortable: true,
        filterable: true,
        editable: true,
        type: "date",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "wrap" }}
          >
            <TextField
              variant="outlined"
              id="date"
              type="date"
              size="small"
              margin="dense"
              defaultValue={fmt(item.fecha_limite, "YYYY-MM-DD")}
              onChange={(event) => handleDateChange(event, item)}
              sx={{ width: 200 }}
              disabled={item.estado === "En Curso" ? false : true}
            />
          </TableCell>
        ),
      },
      diasVigencia: {
        label: "DÍAS PARA VIGENCIA",
        sortable: true,
        filterable: true,
        editable: true,
        type: "number",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "wrap" }}
          >
            <TextField
              variant="outlined"
              id="number"
              type="number"
              size="small"
              margin="dense"
              defaultValue={item.diasVigencia}
              onChange={(event) => handleDiasVigenciaChange(event, item)}
              sx={{ width: 100 }}
              disabled={item.estado === "En Curso" ? true : false}
            />
          </TableCell>
        ),
      },
      "tipoactividadad.tipo_actividad.descripcion": {
        label: "TIPO DE ACTIVIDAD",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item.tipoactividadad
              ? item.tipoactividadad.map((tipo) => (
                  <Chip
                    key={tipo.tipo_actividad.id}
                    variant="outlined"
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {tipo.tipo_actividad.descripcion}
                      </div>
                    }
                  />
                ))
              : ""}
          </TableCell>
        ),
      },
      estado: {
        label: "ESTADO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item.estado}</p>
          </TableCell>
        ),
      },
      asiento: {
        label: "ASIENTO",
        sortable: true,
        filterable: false,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item.asiento}</p>
          </TableCell>
        ),
      },
      "documentoDescriptorList.descriptor.descripcion": {
        label: "DESCRIPTORES",
        sortable: true,
        filterable: false,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>
              {item?.documentoDescriptorList
                ?.map((d) => d.descriptor?.descripcion)
                .join(", ")}
            </p>
          </TableCell>
        ),
      },
      actions: {
        label: "ACCIONES",
        sortable: false,
        filterable: false,
        cellRendering: (item) => (
          <TableCell className={classes.tableCellAction}>
            <div style={{ display: "flex", padding: 0 }}>
              <div
                className={classes.button}
                onClick={() => {
                  axios
                    .get(`versiones/documento/${item.id}`, {
                      data: {},
                      signal: abortController.signal,
                    })
                    .then((resp) => {
                      setPost(resp.data);
                      setAbrirDialogoDescarga(true);
                    })
                    .catch(() => {
                      snackbarStore.openSnackbar(
                        "Error al obtener los datos del servidor",
                        "error"
                      );
                    });
                }}
              >
                <LightTooltip title="Descargar Documento" arrow>
                  <GetAppIcon
                    style={{
                      color: "#00529c",
                      maxWidth: "35px",
                      maxHeight: "35px",
                      cursor: "pointer",
                    }}
                  />
                </LightTooltip>
              </div>
              <Link to={`subirversion/${item.id}`} className={classes.button}>
                <LightTooltip title="Agregar versión" arrow>
                  <NoteAddIcon
                    style={{
                      color: "#00529c",
                      maxWidth: "35px",
                      maxHeight: "35px",
                    }}
                  />
                </LightTooltip>
              </Link>
              <div
                className={classes.button}
                onClick={() => {
                  DocumentoService.updateStatus(
                    item.id,
                    abortController.signal
                  ).then(() => {
                    onReloadTable();
                  });
                }}
              >
                <LightTooltip
                  title={
                    item.estado !== "Inactivo"
                      ? "Desactivar Documento"
                      : "Activar Documento"
                  }
                  arrow
                >
                  {item.estado !== "Inactivo" ? (
                    <VisibilityIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "20px",
                        maxHeight: "20px",
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "20px",
                        maxHeight: "20px",
                      }}
                    />
                  )}
                </LightTooltip>
              </div>
            </div>
          </TableCell>
        ),
      },
    };
  };
  React.useEffect(() => {
    if (filters) {
      const uniqueFilters = Array.from(new Set(filters));
      props.dataset.list.queryData.setQuerys([...uniqueFilters]);
      onReloadTable();
    }
  }, [filters]);

  const onReloadTable = () => {
    props.dataset.list.setLoading();
    const settings = getSettings();

    const uniqueFilters = Array.from(new Set(filters));
    if (currentUser && currentUser.nombre_apellido !== "Administrador") {
      if (currentUser.organizacion) {
        props.dataset.list.queryData.setQuerys([
          ...uniqueFilters,
          {
            key: "usuario.organizacion.id",
            value: parseFloat(currentUser.organizacion.id),
            condition: "equals",
            type: "string",
          },
        ]);
      } else if (currentUser.organismoInternacional) {
        props.dataset.list.queryData.setQuerys([
          ...uniqueFilters,
          {
            key: "usuario.organismoInternacional.id",
            value: parseFloat(currentUser.organismoInternacional.id),
            condition: "equals",
            type: "string",
          },
        ]);
      }
    }

    let params = createParams({ ...props.dataset.list, settings, orderData });

    //params.page = "all";
    //params.pageSize = props.dataset.list.pagingData.itemsCount;

    const t = AuthService.getCurrentUserT();

    if (t) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;

      axios
        .get("documentosInstitucionales", {
          data: {},
          params,
          signal: abortController.signal,
        })
        .then((resp) => {
          props.dataset.list.resetListData();
          props.dataset.list.dataLoaded(resp.data.data, {
            currentPage: resp.data.meta.currentPage - 1,
            pageSize: resp.data.meta.pageSize,
            itemsCount: resp.data.meta.total,
          });
        })
        .catch(() => {
          snackbarStore.openSnackbar(
            "Error al obtener el listado documentos",
            "error"
          );
        });
    }
  };
  React.useEffect(() => {
    if (filters) {
      props.dataset.list.pagingData.changePage(0);
      onReloadTable();
    }
  }, [filters]);

  React.useEffect(() => {
    return () => abortController.abort();
  }, []);

  if (currentUser) {
    return (
      <div className={classes.layout}>
        <ListDataProvider
          listStore={props.dataset.list}
          render={(listProps) => (
            <DataTable
              //title="Conjuntos de Datos"
              settings={getSettings()}
              Breads={Bread()}
              onReloadTable={onReloadTable}
              {...listProps}
            />
          )}
        />

        <Link to="/subirdocumento" className={classes.addButtonContainer}>
          <AddCircleOutlineIcon className={classes.addButton} />
        </Link>
        <Dialog
          open={abrirDialogoConfirmacion}
          onClose={cerrarDialogoConfirmacion}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Usted esta modificando la fecha límite de este documento"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Reemplazará la fecha: {fmt(doc?.fecha_limite)} por la siguiente:{" "}
              {fmt(fecha)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={cerrarDialogoConfirmacion}
              variant="outlined"
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={actualizarFechaLimite}
              variant="contained"
              color="primary"
              autoFocus
            >
              {loading ? (
                <CircularProgress color="secondary" size="1.8em" />
              ) : (
                "Modificar"
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={abrirDialogoConfirmacionVigente}
          onClose={cerrarDialogoConfirmacionVigente}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "Usted esta modificando los dias para la vigencia de este documento"
            }
          </DialogTitle>
          <DialogContent>
            {doc && doc.diasVigencia ? (
              <DialogContentText id="alert-dialog-description">
                Reemplazará los dias para la vigencia: {doc.diasVigencia} por la
                siguiente: {diasVigencia}
              </DialogContentText>
            ) : (
              <DialogContentText id="alert-dialog-description">
                Agregar dias para la vigencia: {diasVigencia} dias
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={cerrarDialogoConfirmacionVigente}
              variant="outlined"
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={actualizarDiasVigencia}
              variant="contained"
              color="primary"
              autoFocus
            >
              {loading ? (
                <CircularProgress color="secondary" size="1.8em" />
              ) : (
                "Modificar"
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <div className={styles.modal}>
          <DownloadDialog
            open={abrirDialogoDescarga}
            post={post}
            onClose={cerrarDialogoDescarga}
          />
        </div>
      </div>
    );
  } else {
    return (
      snackbarStore.openSnackbar(
        "Para realizar un comentario, primero inicie sesión",
        "warning"
      ),
      (<Redirect to={"/login/" + encodeURIComponent(props.match.url)} />)
    );
  }
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D6EBFF",
    color: "#00529c",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 250,
    textAlign: "center",
  },
}))(Tooltip);

const styles = (theme) => ({
  tableContent: {
    fontSize: "12px",
    width: "20px",
    //padding: "0px",
    margin: "0px",
  },
  tableContentOrgPro: {
    fontSize: "12px",
    width: "40px",
    //padding: "0px",
    margin: "0px",
  },
  tableCell: {
    fontSize: "11px",
    //width: "auto",
    //padding: "0px",
    margin: "0px",
  },
  tableTitle: {
    fontSize: "11px",
    //width: "auto",
    //padding: "0px",
    margin: "0px",
  },
  tableCant: {
    fontSize: "11px",
    textAlign: "center",
    width: "20px",
    padding: "0",
    margin: "0",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    width: "auto",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
    //width: "20px",
  },
  addButtonContainer: {
    textDecoration: "none",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  addButton: {
    fontSize: "70px",
    width: "auto",
    color: "#00529c",
    zIndex: "99999",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  paperBreadContainer: {
    display: "flex",
  },
  paperBread: {
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  cell: {
    paddingTop: "1rem",
    marginTop: "1rem",
  },
  ccellTitulo: {
    width: "200px",
  },
});

export default withStyles(styles)(inject("dataset")(observer(Dataset)));
