import { observable, decorate, action } from "mobx";

/*
interface QueryItem {
  value: any; // Valor a filtrar
  key: string; // nombre de columna o path que se desea filtrar
  condition: FilterConditions; // -> ver el archivo '/utils/filter'
  type: FilterTypes; // -> ver el archivo '/utils/filter'
}
*/

class QueryData {
  querys = [];

  addQuery(filters /*: QueryItem[]*/) {
    const isDuplicated = this.querys.some((q) =>
      filters.some(
        (f) =>
          f.key === q.key &&
          f.value === q.value &&
          f.condition === q.condition &&
          f.type === q.type
      )
    );
    // this.querys.splice(0, this.querys.length);
    if (!isDuplicated) {
      this.querys.push(...filters);
    }
  }

  removeQuery(removed /*: QueryItem*/) {
    const allButRemoved = this.querys.filter(
      (f) =>
        !(
          f.key === removed.key &&
          f.value === removed.value &&
          f.condition === removed.condition &&
          f.type === removed.type
        )
    );
    this.querys.splice(0, this.querys.length);
    this.querys.push(...allButRemoved);
  }

  setQuerys(filters /*: QueryItem[]*/) {
    this.querys = filters.slice();
  }
}

decorate(QueryData, {
  querys: observable,
  addQuery: action,
  removeQuery: action,
  setQuerys: action,
});

export default QueryData;
