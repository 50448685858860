import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import React from "react";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const ConfirmationDialog = ({
  open,
  title,
  message,
  cancelLabel,
  confirmLabel,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div>
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="secondary">
            {cancelLabel || "Cancelar"}
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="primary">
            {confirmLabel || "Confirmar"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
