import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(1100 + theme.spacing(6))]: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    toolbarMain: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
    },
    mainFeaturedPost: {
      //backgroundColor: '#72616e',
      //color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
    },
    mainFeaturedPostContent: {
      padding: `${theme.spacing(6)}px`,
      [theme.breakpoints.up('md')]: {   
        paddingRight: 0,
      },
    },
    mainGrid: {
      marginTop: theme.spacing(3),
    },
    title: {
      color: '#e54b4b'
    },
    card: {
      display: 'flex',
    },
    cardDetails: {
      flex: 1,
    },
    cardMedia: {
      width: 160,
    },
    markdown: {
      padding: `${theme.spacing(3)}px 0`,
    },
    sidebarAboutBox: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
      marginTop: theme.spacing(3),
    }
});

class Legal extends React.Component {
  
  state = {};
  
  render() {

    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.layout}>
            <main>
            {/* Main featured post */}
            <Paper className={classes.mainFeaturedPost}>
                <Grid container>
                <Grid item md={11}>
                    <div className={classes.mainFeaturedPostContent}>
                      <Typography className={classes.title} component="h3" variant="h3" color="inherit" gutterBottom>
                        Aviso Legal
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                        Términos y Condiciones
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                        Este aviso legal recoge las condiciones generales que rigen el acceso y el uso del presente sitio web, del que es titular el Ministerio de Industria y Comercio (MIC); el uso del sitio web implica la expresa y plena aceptación de las presentes condiciones generales en la versión publicada en el momento en que el usuario acceda al mismo, sin perjuicio de las condiciones particulares que pudieran aplicarse a algunos de los contenidos o servicios concretos del sitio web.
                        El MIC no se responsabiliza de las decisiones tomadas a partir de la información difundida en el sitio WEB ni de los posibles daños y perjuicios producidos al usuario visitante o a terceros con motivo de actuaciones que tengan como único fundamento la información obtenida en el sitio. El MIC podrá efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones sobre la información contenida en el sitio o en su configuración o presentación. Asimismo, se reserva el derecho a modificar en cualquier momento las presentes condiciones de uso así como cualesquiera otras condiciones particulares.
                        Los derechos de propiedad intelectual de los datos publicados bajo estándares de "Datos Abiertos" por el Ministerio de Industria y Comercio, de su diseño gráfico y los códigos que contiene, son titularidad de MIC, a no ser que se indique una titularidad diferente.
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                        Autorización de reutilización y cesión no exclusiva de derechos de propiedad intelectual    
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                        A través de la Resolución Ministerial Nº xxxxx , se dispone la apertura de datos públicos del Ministerio de Industria y Comercio bajo el uso de la licencia CC-BY 4.0. Las presentes condiciones generales permiten la reutilización de los documentos sometidos a ellas para fines comerciales y no comerciales, que incluye, a modo ilustrativo, actividades como la copia, difusión, modificación, adaptación, extracción, reordenación y combinación de la información.
                        Esta autorización conlleva, asimismo, la cesión gratuita y no exclusiva de los derechos de propiedad intelectual, en su caso, correspondientes a tales documentos, autorizándose la realización de actividades de reproducción, distribución, comunicación pública o transformación, necesarias para desarrollar la actividad de reutilización autorizada, en cualquier modalidad y bajo cualquier formato, para todo el mundo y por el plazo máximo permitido por la Ley.
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                        Condiciones generales para la reutilización
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                        La reutilización de los datos difundidos por el MIC se realizará por parte de los usuarios bajo su propia cuenta y riesgo, correspondiéndoles en exclusiva a ellos responder frente a terceros por daños que pudieran derivarse de ello. El MIC no será responsable del uso o reuso que hagan de la información ni tampoco de los daños sufridos o pérdidas económicas que, de forma directa o indirecta, produzcan o puedan producir perjuicios económicos, materiales o sobre datos, provocados por el uso de la información reutilizada.
                        Son de aplicación las siguientes condiciones generales para la reutilización de los documentos sometidos a ellas:

    Está prohibido desnaturalizar el sentido de la información.
    Debe citarse la fuente de los documentos objeto de la reutilización. Esta cita podrá realizarse de la siguiente manera: “Origen de los datos: Ministerio de Industria y Comercio."
    Debe mencionarse la fecha de la última actualización de los documentos objeto de la reutilización, siempre cuando estuviera incluida en el documento original.
    No se podrá indicar, insinuar o sugerir que el MIC titular de la información reutilizada participa, patrocina o apoya la reutilización que se lleve a cabo con ella.
    Deben conservarse, no alterarse ni suprimirse los metadatos sobre la fecha de actualización y las condiciones de reutilización aplicables incluidos, en su caso, en el documento puesto a disposición para su reutilización.

                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                        Exclusión de responsabilidad
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                        La utilización de los conjuntos de datos se realizará por parte de los usuarios bajo su propia cuenta y riesgo, correspondiéndoles en exclusiva a ellos responder frente a terceros por daños que pudieran derivarse de ella.
                        El MIC no será responsable del uso que de su información hagan los agentes reutilizadores ni tampoco de los daños sufridos o pérdidas económicas que, de forma directa o indirecta, produzcan o puedan producir perjuicios económicos, materiales o sobre datos, provocados por el uso de la información reutilizada.
                        El MIC no garantiza la continuidad en la puesta a disposición de los documentos reutilizables, ni en contenido ni en forma, ni asume responsabilidades por cualquier error u omisión contenido en ellos.
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                        Política de Privacidad
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                        El acceso a y/o uso de los datos publicados por el MIC en datos.vue.gov.py implica que todos los visitantes al mismo se adhieren a los Términos y Condiciones, aceptándolos, desde ese momento, plenamente y sin reserva alguna, así como en su caso, las Condiciones Particulares que en el futuro puedan complementarlas, sustituirlas o modificarlas en algún sentido en relación con los servicios y contenidos del mismo.
                        Por el solo hecho de ingresar y hacer uso de este Portal (datos.vue.gov.py), usted (el "Usuario Visitante") se adhiere en forma inmediata a todos y cada uno de los siguientes Términos y Condiciones. En consecuencia, el Usuario Visitante deberá leer detenidamente los Términos y Condiciones así como las Condiciones Particulares, que pudieran dictarse, antes de acceder y/o utilizar cualquier servicio del sitio bajo su entera responsabilidad. Es responsabilidad del usuario mantenerse informado acerca de la Política de Privacidad vigente cada vez que hace uso del portal.
                        A través de esta Política de Privacidad, el MIC no está suscribiendo un compromiso contractual con sus visitantes, sino que ésta se entrega sólo con fines informativos.
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                        Derecho a la Intimidad
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                        El MIC no recopilará información personal de los usuarios visitantes, sin embargo, el MIC se reserva el derecho de recopilar cualquier información necesaria para que en caso de sospecha de alguna conducta ilegal en el uso de la información de la página del MIC en la Internet se cuente con ella.
                        Para conservar la disponibilidad de los servicios que prestamos, el MIC supervisará el uso de la red de interconexión, de manera a detectar aquellas situaciones donde se intente modificar el contenido de las páginas o se trate de dañar la propiedad utilizada para estos propósitos. Así también el MIC se reserva el derecho de utilizar cookies para facilitar la navegación.
                        Además, el MIC podrá generar estadísticas y recopilar información relevante a las "visitas" a nuestras páginas en cuanto a:

    El dominio de la Internet y número de IP de su conexión o acceso;
    El tipo de navegador y el sistema operativo (Tipo, Versión e idioma, etc.) que se utilizó para ver las páginas;
    La fecha y hora de la visita;
    Las páginas que se visitaron; y
    La página externa que originó la visita
    Ubicación Geografica de los usuarios,
    Resolucion de la Pantalla, Versión de Flash
    Tipo de dispositivo

                        Cuando la información se relacione con una confidencia o querella, la misma se considerará y se mantendrá en confidencialidad.
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                        Obligatoriedad de las condiciones generales.
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                        Las presentes condiciones generales, disponibles con carácter permanente bajo aviso legal, vincularán a cualquier usuario reutilizador por el mero hecho de hacer uso de los documentos sometidos a ellas.
                      </Typography>
                    </div>
                </Grid>
                </Grid>
            </Paper>
            {/* End main featured post */}
            </main>
        </div>
      </React.Fragment>
    );
  }
}

Legal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Legal);
