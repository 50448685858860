import React from "react";
import { Route } from "react-router-dom";
import Legal from "./Legal";

const LegalRoute = props => (
  <React.Fragment>
    <Route exact path={`${props.match.url}/`} component={Legal} />
  </React.Fragment>
);

export default LegalRoute;
