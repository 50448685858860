import { TableCell } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import snackbarStore from "../../stores/snackbar-store";
import DataTable from "../../toolkit/DataTable/DataTableComentarios";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import axios from "../../utils/axios";
import { FilterUnions as U } from "../../utils/filter";
import createParams from "../../utils/tables-params";
import file_path from "../../utils/files_path";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

const DatasetComentarios = (props) => {
  const idDocumento = props.match.params.idDocumento;
  const abortController = new AbortController();

  const Bread = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
        <a className={classes.txtlink} href="/">
          Consulta Pública de Normativas
        </a>
        <a className={classes.txtlink} href={props.match.url}>
          <strong>Comentarios</strong>
        </a>
      </Breadcrumbs>
    );
  };

  const { classes } = props;

  const getSettings = () => {
    return {
      "id_usuario.nombre_apellido": {
        label: "NOMBRE",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            {item?.esComentarioCiudadano && item.usuario
              ? item.usuario.nombre_apellido
              : ""}
          </TableCell>
        ),
      },
      fecha: {
        label: "FECHA",
        sortable: true,
        filterable: true,
        center: true,
        type: "date",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            {item ? moment(item.fecha).format("DD-MM-YYYY HH:MM:SS") : ""}
          </TableCell>
        ),
      },
      comentario: {
        label: "COMENTARIO",
        sortable: true,
        filterable: true,
        center: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item?.esComentarioCiudadano ? item.comentario : ""}
          </TableCell>
        ),
      },
      respuesta: {
        label: "RESPUESTA",
        sortable: true,
        filterable: true,
        center: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {!item?.esComentarioCiudadano ? item.respuesta : ""}
          </TableCell>
        ),
      },
      actions: {
        label: "ACCIONES",
        sortable: false,
        filterable: false,
        cellRendering: (item) => (
          <TableCell className={classes.tableCellAction}>
            <div style={{ display: "flex", padding: 0 }}>
              <div className={classes.button}>
                {item.ubicacion ? (
                  <a
                    href={file_path + item.ubicacion}
                    download={file_path + item.ubicacion}
                    target="_blank"
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.button}
                    >
                      DESCARGAR ARCHIVO ADJUNTO
                    </Button>
                  </a>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.button}
                    disabled={true}
                  >
                    DESCARGAR ARCHIVO ADJUNTO
                  </Button>
                )}
              </div>
            </div>
          </TableCell>
        ),
      },
    };
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#D6EBFF",
      color: "#00529c",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 250,
      textAlign: "center",
    },
  }))(Tooltip);

  /*prueba*/
  /*<Link  to={`/${item.id}/datos`} className={classes.button}>*/
  //verificar que hace la linea de abajo, esta en todos los datasets
  /*<Route exact path={`${props.match.url}/`} component={DatasetComentarios} props={props}/>*/

  const onReloadTable = () => {
    props.comentarios.list.setLoading();
    let settings = getSettings();
    props.comentarios.list.queryData.setQuerys([
      {
        key: "documento",
        value: parseFloat(idDocumento),
        condition: "equals",
        type: "string",
      },
      { key: "estado", value: "Aprobado", condition: "equals", type: "string" },
    ]);
    const orderData = {
      orders: {
        "comentarioRespondido.id": "desc",
        orderComentarioRespondido: "asc",
        fecha: "desc",
      },
    };
    let params = createParams({
      ...props.comentarios.list,
      settings,
      union: U.AND,
      orderData,
    });

    //params.page = "all";
    //params.pageSize = props.dataset.list.pagingData.itemsCount;

    axios
      .get("comentario/", {
        data: {},
        params,
        signal: abortController.signal,
      })
      .then((resp) => {
        props.comentarios.list.resetListData();
        props.comentarios.list.dataLoaded(resp.data.data, {
          currentPage: resp.data.meta.currentPage - 1,
          pageSize: resp.data.meta.pageSize,
          itemsCount: resp.data.meta.total,
        });
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado comentarios",
          "error"
        );
      });
  };

  React.useEffect(() => {
    return () => abortController.abort();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <ListDataProvider
          listStore={props.comentarios.list}
          render={(listProps) => (
            <DataTable
              title=""
              idDocumento={idDocumento}
              Breads={Bread()}
              settings={getSettings()}
              onReloadTable={onReloadTable}
              {...listProps}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};
//lightTooltip se puede volver a implementar
/*const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#D6EBFF",
        color: "#00529c",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 250,
        textAlign: "center"
    },
}))(Tooltip);*/

const styles = (theme) => ({
  tableContentDocTitle: {
    fontSize: "11px",
    width: "200px",
    //padding: "0px",
    margin: "0px",
  },
  tableContent: {
    fontSize: "11px",
    width: "20px",
    //padding: "0px",
    margin: "0px",
  },
  tableCell: {
    fontSize: "11px",
    //width: "auto",
    //padding: "0px",
    margin: "0px",
    textAlign: "center",
  },
  tableTitle: {
    fontSize: "11px",
    //width: "auto",
    //padding: "0px",
    margin: "0px",
  },
  tableCant: {
    fontSize: "11px",
    textAlign: "center",
    width: "20px",
    padding: "0",
    margin: "0",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    width: "auto",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
    //width: "20px",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  tableCellAction: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default withStyles(styles)(
  inject("comentarios")(observer(DatasetComentarios))
);
