
import axios from "axios";
import { API_BASE_URL } from "../http-common";

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000,
  headers: { "Content-Type": "application/json" }
});

export default instance;
