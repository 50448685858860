import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import CommentIcon from "@material-ui/icons/Comment";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import DocumentoService from "../../services/documento-service";
import LightTooltip from "../dataset/LightTooltip";
import Button from "./DocumentoActionButton";

function DocumentoActions({ classes, item, onDownload }) {
  const hoy = Date.now();
  const hasCantidad = item.cantidad > 0;
  const isFiniquitado =
    item.fecha_limite <= hoy || item.estado === "Finiquitado";
  return (
    <TableCell>
      <div className={classes.tableCellAction}>
        <Link to={`/comentarios/${item.id}`}>
          <LightTooltip
            title={
              hasCantidad ? "Ver Comentarios" : "No hay comentarios para ver"
            }
            arrow
          >
            <Button
              startIcon={<VisibilityIcon style={{ marginRight: "3px" }} />}
              className={classes.red}
            >
              Comentarios
            </Button>
          </LightTooltip>
        </Link>
        {isFiniquitado ? (
          <div>
            <LightTooltip title="Fecha límite para comentar expirada" arrow>
              <Button
                startIcon={<CommentIcon style={{ marginRight: "3px" }} />}
                className={classes.yellow}
                disabled
              >
                Comentar
              </Button>
            </LightTooltip>
          </div>
        ) : (
          <Link to={`/comentar/${item.id}`}>
            <LightTooltip title="Comentar" arrow>
              <Button startIcon={<CommentIcon />} className={classes.yellow}>
                Comentar
              </Button>
            </LightTooltip>
          </Link>
        )}
        {hasCantidad ? (
          <Link to={`/reportes/${item.id}`}>
            <LightTooltip
              title="Descargar el Informe de comentarios del documento a la fecha"
              arrow
            >
              <Button startIcon={<DescriptionIcon />}>Reporte</Button>
            </LightTooltip>
          </Link>
        ) : (
          <div>
            <LightTooltip title="Sin comentarios para reportar" arrow>
              <Button startIcon={<DescriptionIcon />} disabled>
                Reporte
              </Button>
            </LightTooltip>
          </div>
        )}
        <div>
          <LightTooltip title="Descargar Documento" arrow>
            <Button
              startIcon={<GetAppIcon />}
              className={classes.red}
              onClick={() => {
                DocumentoService.getFileByDocumentoId(item.id).then((resp) => {
                  onDownload(resp.data);
                });
              }}
            >
              Descargar
            </Button>
          </LightTooltip>
        </div>
      </div>
    </TableCell>
  );
}

const styles = () => ({
  tableCellAction: {
    display: "flex",
    padding: "10px 0",
    flexDirection: "column",
    gap: "5px",
  },
  red: {
    backgroundColor: "#ea2428",
    color: "white",
    "&:hover": {
      backgroundColor: "rgb(234, 36, 40, 0.7)",
    },
    "&.MuiIconButton-root": {
      backgroundColor: "transparent",
      color: "#ea2428",
    },
  },
  yellow: {
    backgroundColor: "#ffc31b",
    color: "#593408",
    "&:hover": {
      backgroundColor: "rgb(255, 195, 27, 0.7)",
    },
    "&.MuiIconButton-root": {
      backgroundColor: "transparent",
      color: "#ffc31b",
    },
  },
});

export default withStyles(styles)(DocumentoActions);
