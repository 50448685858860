import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from "@material-ui/icons/BarChartOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import React from "react";
import { useHistory } from "react-router-dom";
import Header from "../../header";
import CardButton from "./CardButton";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "2vw",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 64px)",
    textAlign: "center",
    overflow: "none",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  icon: {
    fontSize: "10rem",
    color: "#3F3F41",
  },
}));

const MenuPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleConsultaClick = () => {
    history.push("/");
  };

  const handleNormasVigentesClick = () => {
    history.push("/vigentes");
  };

  const handleEstadoConsultaClick = () => {
    history.push("/estadisticas");
  };

  return (
    <div>
      <Header />
      <div className={classes.container}>
        <CardButton
          title="Normas en Consulta"
          icon={<DescriptionIcon className={classes.icon} />}
          onClick={handleConsultaClick}
        />
        <CardButton
          title="Normas Vigentes"
          icon={<CheckCircleIcon outline className={classes.icon} />}
          onClick={handleNormasVigentesClick}
        />
        <CardButton
          title="Estadísticas"
          icon={<BarChartIcon className={classes.icon} />}
          onClick={handleEstadoConsultaClick}
        />
      </div>
    </div>
  );
};

export default MenuPage;
