import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItem,
} from "@material-ui/core";
import { memo, useRef } from "react";

function MenuItem({ label, onChange, ...props }) {
  const ref = useRef(null);
  const handleClick = (event) => {
    event.stopPropagation();
    ref.current?.click();
  };
  return (
    <ListItem button onClick={handleClick}>
      <FormGroup onClick={(e) => e.stopPropagation()}>
        <FormControlLabel
          onChange={onChange}
          control={<Checkbox inputRef={ref} color="primary" />}
          label={label}
          style={{ marginRight: 0 }}
          {...props}
        />
      </FormGroup>
    </ListItem>
  );
}

const CheckboxMenuItem = memo(MenuItem);

export default CheckboxMenuItem;
