import { memo } from "react";
import useGetUserRole from "../../../hooks/useGetUserRole";
import AdminMenuList from "./AdminMenuList";
import InstitucionMenuList from "./InstitucionMenuList";

function Menu({ user }) {
  const { isAdminRole, isInstitucionRole } = useGetUserRole({ user });
  if (isAdminRole) {
    return <AdminMenuList />;
  }
  if (isInstitucionRole) {
    return <InstitucionMenuList />;
  }
  return null;
}

const MenuListByUser = memo(Menu);

export default MenuListByUser;
