import moment from "moment";

export function getUsernameByEmail(email) {
  return email.split("@")[0];
}

export function getFistNameByFullName(fullName) {
  return fullName.split(" ")[0];
}

export function getLastNameByFullName(fullName) {
  const splited = fullName.split(" ");
  return splited.length > 1 ? splited[1] : splited[0];
}

export function formatDate(date) {
  return moment(date).format("DD-MM-YYYY");
}

let timer;

export function debounce(func, timeout = 3000) {
  (function delayFunc() {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, timeout);
  })();
}
