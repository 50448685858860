import { createFilter } from "./filter";
import createSortings from "./sorting";

const createParams = ({pagingData, queryData, orderData, settings}) => {

    let attributes = [];

    Object.keys(settings).forEach(key => {
        if (settings[key].filterable) attributes.push(key);
    });

    let filtersInstance = createFilter(queryData.querys, attributes);
    let filter = filtersInstance;

    let sorting = createSortings(orderData.orders);

    let params = { 
        filter,
        page: pagingData.currentPage + 1,
        pageSize: pagingData.pageSize,
        sorting
    };

    return params;
};
  
export default createParams;