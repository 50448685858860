import { useRef, useState } from "react";
import {
  Button,
  FormControl,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";

function FileField({ error, helperText, value, onFileChange }) {
  const [fileName, setFileName] = useState("");
  const reader = new FileReader();
  const hiddenFileInput = useRef(null);
  const handleClickFile = () => {
    hiddenFileInput.current.click();
  };
  const handleChangeFile = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size > 52428800) {
      snackbarStore.openSnackbar(
        "El tamaño del archivo no debe ser mayor a 50 MB.",
        "error"
      );
    } else {
      reader.readAsDataURL(fileUploaded);
      reader.onload = function () {
        const base64 = reader.result;
        const content = base64.split(",");
        const filename = fileUploaded.name;
        const pieces = filename.split(".");
        const extension = pieces[pieces.length - 1];
        setFileName(filename);
        onFileChange({
          file: fileUploaded,
          base64: content[1],
          filename: fileUploaded.name,
          extension,
        });
      };
    }
  };

  return (
    <FormControl margin="dense" fullWidth variant="outlined">
      <TextField
        margin="dense"
        variant="outlined"
        label="Adjuntar documento"
        id="adjuntar"
        name="adjuntar"
        fullWidth
        value={value || fileName}
        error={error}
        helperText={helperText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button onClick={handleClickFile}>
                <AttachFileIcon />
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <input
        accept="application/pdf,.doc,.docx"
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeFile}
        style={{ display: "none" }}
      />
      <span style={{ fontSize: "11px" }}>
        *El documento debe estar en formato PDF o MS Word y no superar los 50MB
      </span>
    </FormControl>
  );
}

export default FileField;
