import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { FilterTypes } from "../../utils/filter";

const CustomTableCell = withStyles((theme) => ({
  head: {
    color: blueGrey[700],
  },
}))(TableCell);

/**
 * En los props del componente se recibe configuracion del header
 *
 * ```javascript
 * settings: {
 *  attr: {
 *      label: 'Attributo',
 *      checkbox: true/false,
 *      sortable: true/false,
 *      filterable: true/false,
 *      type: tipo de dato asociado,
 *      headerRendering: Component
 *  }
 * }
 * ```
 * Por defecto, cada celda despliega el valor del label, a no ser que haya especificado
 * un componente en el headerRendering, en cuyo caso se renderizara el componente.
 * Ademas, se recibe el estado de ordenamiento, con el siguiente formato:
 *
 * ```javascript
 * {
 *  attr: {
 *      direction: 'asc'/'desc'
 *  }
 * }
 * ```
 * Para el caso en el que se reciba una configuracion con `sortable=true`, se debe recibir ademas como
 * prop el dispatch `onOrderChanged`, una funcion que recibe el field y hace el dispatch que corresponde.
 *
 * @param {Object} props - Los props del componente.
 * @return {JSX.Element}
 */
const DataTableHeader = (props) => {
  const defaultAttributeSetting = {
    type: FilterTypes.STRING,
    checkbox: false,
    sortable: false,
  };
  const orderData = props.orderData ? props.orderData : {};
  return (
    <TableHead>
      <TableRow>
        {Object.keys(props.settings).map((key) => {
          const currentSetting = {
            ...defaultAttributeSetting,
            ...props.settings[key],
          };
          if (currentSetting.hidden) {
            return null;
          }
          currentSetting.align = currentSetting.align
            ? currentSetting.align
            : currentSetting.type === FilterTypes.NUMERIC
            ? "right"
            : "left";
          return currentSetting.sortable ? (
            <CustomTableCell
              key={key}
              align={currentSetting.align}
              padding={currentSetting.checkbox ? "checkbox" : "normal"}
            >
              <Tooltip
                title="Ordenar"
                placement={
                  currentSetting.type === FilterTypes.NUMERIC
                    ? "bottom-end"
                    : "bottom-start"
                }
                enterDelay={200}
              >
                <TableSortLabel
                  active={!!orderData[key]}
                  direction={orderData[key] ? orderData[key] : undefined}
                  onClick={(_) => props.onOrderChanged(key)}
                >
                  {currentSetting.headerRendering
                    ? currentSetting.headerRendering
                    : currentSetting.label}
                </TableSortLabel>
              </Tooltip>
            </CustomTableCell>
          ) : (
            <CustomTableCell
              key={key}
              align={currentSetting.align}
              padding={currentSetting.checkbox ? "checkbox" : "normal"}
            >
              {currentSetting.headerRendering
                ? currentSetting.headerRendering
                : currentSetting.label}
            </CustomTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

DataTableHeader.propTypes = {
  /**
   * Representa la configuración de ordenamiento.
   */
  orderData: PropTypes.any,

  /**
   * Configuración de las columnas.
   */
  settings: PropTypes.any.isRequired,

  /**
   * Función invocada cuando se hace click sobre una columna "ordenable".
   *
   * @param {string} field - La columna afectada.
   */
  onOrderChanged: PropTypes.func,
};

export default DataTableHeader;
