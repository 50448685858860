import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import cnfc from "../images/solologocnfc.png";
import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";

const required = (value) => {
  if (!value) {
    return snackbarStore.openSnackbar("Campo requerido");
  }
};

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  txtaviso: {
    textAlign: "center",
    color: "#00529c",
    fontSize: "15px",
  },
  registrarse: {
    marginTop: "14px",
    textAlign: "left",
    fontSize: "19px",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "10px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#00529c",
    fontWeight: "700",
  },
});

function RecuperacionDeCuenta(props) {
  const [username, setUsername] = React.useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const handleRecuperarCuenta = () => {
    if (username != "") {
      axios
        .get(`usuarios/recuperar/${username}`, {
          data: {},
        })
        .then(
          () => {
            snackbarStore.openSnackbar(
              `Se ha remitido un correo a ${username} con la información para la recuperación de su cuenta`,
              "success"
            );
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            snackbarStore.openSnackbar(resMessage, "error");
          }
        );
    } else {
      snackbarStore.openSnackbar(
        "Por favor, indique correo electrónico",
        "error"
      );
    }
  };

  const { classes } = props;

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <a href="https://www.cnfc.gov.py">
            <img width="350px" src={cnfc} alt="CNFC" />
          </a>

          <p className={classes.txtaviso}>
            Para poder recuperar su cuenta debe especificar el correo
            electrónico con el cual se registró anteriormente
          </p>
          <FormControl margin="normal" fullWidth variant="outlined">
            <TextField
              className={classes.inputS}
              variant="outlined"
              color="primary"
              fullWidth
              id="username"
              name="username"
              autoComplete="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required]}
              InputLabelProps={{ shrink: true }}
              label="Email"
            />
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            className={classes.submit}
            onClick={handleRecuperarCuenta}
          >
            -ENVIAR-
          </Button>
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(RecuperacionDeCuenta);
