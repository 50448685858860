import axios from "../utils/axios";
import snackbarStore from "../stores/snackbar-store";

const getAll = () => {
  return axios.get("descriptor",{ data: {}})
  .catch(() => {
    snackbarStore.openSnackbar(
      "Error al obtener el listado de descriptores",
      "error"
    );
  });
};


export default {
  getAll,
};