import { observable, decorate } from "mobx";
import ListData from "../toolkit/mobx-connectors/observables/list-data";

class RubroStore {
  list = new ListData();
}

decorate(RubroStore, {
  list: observable
});

const rubroStore = new RubroStore();
export default rubroStore;
