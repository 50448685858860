import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { FilterTypes } from "../../utils/filter";

const styles = (theme) => ({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

/**
 * En los props del componente se puede recibir el item, y settings de configuracion del row
 *
 * ```javascript
 *  settings: {
 *    attr: {
 *      label: 'Attributo',
 *      checkbox: true/false,
 *      filterable: true/false,
 *      selectOptions: array donde cada elemento debe tener {label, value}. Opcionalmente, puede ser un
 *        promise que resuelva un array con el mismo formato
 *      type: tipo de dato asociado,
 *      otherKeys: ['estado.descripcion'] Se usa para obtener este campo al momento de descargar y para poder usarla en la funcion value
 *      value: item => {
 *        // una funcion que recibe el item y retorna un string.
 *        // se puede usar para definir un valor diferente al key
 *        return 'string';
 *      }
 *      cellRendering: item => {
 *        // una funcion que recibe el item y retorna un node element.
 *        return <Component />;
 *      }

 *    }
 *  }
 * ```
 *
 * Por defecto, cada celda despliega el valor del item dado el atributo, a no ser que haya especificado
 * un componente en el cellRendering, en cuyo caso se renderizara el componente.
 *
 * @param {Object} props - Los props del componente.
 * @return {JSX.Element}.
 */
const DataTableRow = (props) => {
  const defaultAttributeSetting = { type: FilterTypes.STRING, checkbox: false };

  return (
    <TableRow className={props.classes.row}>
      {Object.keys(props.settings).map((key) => {
        const currentSetting = {
          ...defaultAttributeSetting,
          ...props.settings[key],
        };
        if (currentSetting.hidden) {
          return null;
        }
        currentSetting.align = currentSetting.align
          ? currentSetting.align
          : currentSetting.type === FilterTypes.NUMERIC
          ? "right"
          : "left";
        return (
          <React.Fragment key={key}>
            {currentSetting.cellRendering &&
              currentSetting.cellRendering(props.item)}
            {!currentSetting.cellRendering && (
              <TableCell
                algin={currentSetting.align}
                padding={currentSetting.checkbox ? "checkbox" : "normal"}
              >
                {currentSetting.type === FilterTypes.BOOLEAN
                  ? props.item[key]
                    ? "Sí"
                    : "No"
                  : props.item[key]}
              </TableCell>
            )}
          </React.Fragment>
        );
      })}
    </TableRow>
  );
};

DataTableRow.propTypes = {
  /**
   * Configuración de las columnas.
   */
  settings: PropTypes.object.isRequired,

  /**
   * Representa a un registro de la tabla.
   */
  item: PropTypes.object.isRequired,
};

export default withStyles(styles)(DataTableRow);
