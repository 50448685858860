import {
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@material-ui/core";
import { memo } from "react";
import { Link } from "react-router-dom";

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

function MenuItem({ to, text, icon, isActive }) {
  const styled = isActive ? { fontWeight: "800", fontSize: '16px' } : {fontSize: '16px'};
  return (
    <Link to={to}>
      <ListItemStyled button>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={text} primaryTypographyProps={{style : styled}} />
      </ListItemStyled>
    </Link>
  );
}

const MemoMenuItem = memo(MenuItem);

export default MemoMenuItem;
