import { observable, decorate } from "mobx";
import ListData from "../toolkit/mobx-connectors/observables/list-data";

class DatosStore {
  list = new ListData();
}

decorate(DatosStore, {
  list: observable
});

const datosStore = new DatosStore();
export default datosStore;
