import ReactEcharts from "echarts-for-react";
import useIsMobileScreen from "../../hooks/useIsMobileScreen";

const Pie = ({ data, onClick }) => {
  const isMobile = useIsMobileScreen();
  const option = {
    tooltip: {
      trigger: "item",
    },
    label: {
      show: true,
      formatter: "{b}: {c}", // Formato de etiqueta: {b} para el nombre, {c} para el valor
    },
    legend: {
      orient: "horizontal",
      left: "center",
    },
    series: [
      {
        type: "pie",
        radius: "50%",
        data,
        color: ["#FA3D53", "#00529C", "#FFC31B", "#808000"],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  function handleClick(echartEvent) {
    onClick(echartEvent.name);
  }

  return (
    <ReactEcharts
      option={option}
      style={{ height: isMobile ? "280px" : "580px", width: "100%" }}
      onEvents={{
        click: handleClick,
      }}
    />
  );
};

export default Pie;
