import React from "react";
import DownloadVersionDialog from "../dialogs/DownloadVersionDialog";
import VersionTableRow from "../dialogs/VersionTableRow";

function DownloadDialog({ open, post, onClose }) {
  const [currentDownload, setCurrentDownload] = React.useState(false);

  const cambioRadioDowload = (e) => {
    setCurrentDownload(e.target.value);
  };

  return (
    <DownloadVersionDialog
      downloadUrl={currentDownload}
      open={open}
      onClose={onClose}
      title="Descargar documento"
    >
      {post.map((version) => (
        <VersionTableRow
          key={version.id}
          currentVersionUrl={currentDownload}
          onCurrentVersionUrlChange={cambioRadioDowload}
          isFirstVersion={post.indexOf(version) === 0}
          versionDate={version.fecha_creacion}
          versionId={version.id}
          versionNumber={post.length - post.indexOf(version)}
          versionTitle={version.titulo}
          versionUrl={version.ubicacion}
        />
      ))}
    </DownloadVersionDialog>
  );
}

export default DownloadDialog;
