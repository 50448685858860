import React from "react";
import axios from "../utils/axios";
import snackbarStore from "../stores/snackbar-store";
import {useState, useEffect}  from 'react';
import { Redirect } from "react-router-dom";

const estadotipoactividad = props => {


     const [editado,setEditado]=useState(null);
     const idTipoActividad = props.match.params.idTipoActividad;
     const payload = {'id':idTipoActividad};
        useEffect(() =>{
            axios
                .put("tiposactividad/estado", payload, {'Content-Type': 'application/json'})
                .then(resp => {
                    snackbarStore.openSnackbar("Cambio de estado exitoso", "success");
                    setEditado(true)
                })
                .catch(() => {
                    snackbarStore.openSnackbar("Error al obtener los datos del servidor", "error");
                });


        },[]);
        return (
            <div>
                {editado? <Redirect to="/tipoactividad"/> : 'cargando'}
            </div>
    )


}

export default (estadotipoactividad);