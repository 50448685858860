import MomentUtils from "@date-io/moment";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import { inject, observer, Provider } from "mobx-react";
import moment from "moment";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import stores from "./stores/";
import { QueryClient, QueryClientProvider } from "react-query";

import "moment/locale/es";
import ApplicationSnackbar from "./components/ApplicationSnackbar";
import AuthenticatedApp from "./components/AuthenticatedApp";
import FilterProvider from "./context/FilterContext";

moment.locale("es");

const theme = createTheme({
  palette: {
    primary: { main: "#00529c", dark: "#0E1820" },
    secondary: { main: "#f1f1f1" },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: "Manrope, serif",
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: "24px",
      },
    },
    MuiRadio: {
      root: {
        color: "#0E1820",
        "&$checked": {
          color: "#00529c",
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 15px 4px 10px",
        fontFamily: "DM Sans, serif",
      },
      head: {
        fontWeight: 800,
        fontSize: "9px",
        lineHeight: 1.5,
        textAlign: "center !important",
      },
    },
    MuiTablePagination: {
      caption: {
        fontFamily: "DM Sans, serif",
      },
      select: {
        fontFamily: "DM Sans, serif",
      },
    },
  },
  typography: { useNextVariants: true, fontFamily: "DM Sans, serif" },
});

//agregar validacion de login
const AuthCmp = () => <>{<AuthenticatedApp />}</>;

const AuthSwitch = inject("auth")(observer(AuthCmp));

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Provider {...stores}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <FilterProvider>
              <CssBaseline />
              <ApplicationSnackbar />
              <AuthSwitch />
            </FilterProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Provider>
    </BrowserRouter>
  </QueryClientProvider>
);

//export default inject("auth")(observer(App));
export default App;
