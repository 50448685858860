import { observable, decorate } from "mobx";
import ListData from "../toolkit/mobx-connectors/observables/list-data";

class ActividadStore {
  list = new ListData();
}

decorate(ActividadStore, {
  list: observable
});

const actividadStore = new ActividadStore();
export default actividadStore;
