import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { inject } from "mobx-react";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { useFilterContext } from "../../context/FilterContext";
import snackbarStore from "../../stores/snackbar-store";
import DataTable from "../../toolkit/DataTable/DataTable";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import axios from "../../utils/axios";
import { FilterConditions as C, FilterUnions as U } from "../../utils/filter";
import createParams from "../../utils/tables-params";
import DocumentoActions from "../documento/DocumentoActions";
import DocumentoFiltro from "../documento/DocumentoFiltro";
import useFilterForm from "../documento/useFilterForm";
import DownloadDialog from "./DownloadDialog";

const Dataset = (props) => {
  const { classes } = props;
  const [abrirDialogoDescarga, setAbrirDialogoDescarga] = React.useState(false);
  const [post, setPost] = React.useState([]);
  const { filters, setFilters } = useFilterContext();
  const abortController = new AbortController();

  const formik = useFilterForm({ filters, setFilters });

  const handleDownload = (file) => {
    setPost(file);
    setAbrirDialogoDescarga(true);
  };

  const Bread = () => {
    return (
      <div className={classes.paperBreadContainer}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
          <strong>Normas en Consulta</strong>
        </Breadcrumbs>
        <div style={{ flex: 1 }}></div>
        <DocumentoFiltro formik={formik} />
      </div>
    );
  };

  const getSettings = () => {
    return {
      actions: {
        label: "",
        sortable: false,
        filterable: false,
        cellRendering: (item) => (
          <DocumentoActions item={item} onDownload={handleDownload} />
        ),
      },
      titulo: {
        label: "TÍTULO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCellDocTitle}>
            <p className={classes.tableContentDocTitle}>{item.titulo}</p>
          </TableCell>
        ),
      },
      "tipo_documento.descripcion": {
        label: "TIPO DE DOCUMENTO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item.tipoDocumento}</p>
          </TableCell>
        ),
      },
      "tipoactividadad.tipo_actividad.descripcion": {
        label: "TIPO DE ACTIVIDAD",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item.tipoActividades}</p>
          </TableCell>
        ),
      },
      fecha_publicacion: {
        label: "FECHA PUBLICACIÓN",
        sortable: true,
        filterable: false,
        type: "date",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            <p className={classes.tableContent}>
              {moment(item.fecha_publicacion).format("DD-MM-YYYY")}
            </p>
          </TableCell>
        ),
      },
      fecha_limite: {
        label: "FECHA LIMITE",
        sortable: true,
        filterable: false,
        type: "date",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            <p className={classes.tableContent} style={{ width: "92px" }}>
              {moment(item.fecha_limite).format("DD-MM-YYYY")}
            </p>
          </TableCell>
        ),
      },
      "usuario.organizacion.abreviatura": {
        label: "ORGANIZACION PROPONENTE",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item.organizacion}</p>
          </TableCell>
        ),
      },
      "organismoInternacionalEmisor.descripcion": {
        label: "ORGANISMO INTERNACIONAL",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>
              {item.organismoInternacional}
            </p>
          </TableCell>
        ),
      },
      estado: {
        label: "ESTADO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item.estado}</p>
          </TableCell>
        ),
      },
      cantidad: {
        label: "CANTIDAD DE COMENTARIOS",
        sortable: false,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCant}>
            <p className={classes.tableContentCantidad}>{item.cantidad}</p>
          </TableCell>
        ),
      },
      asiento: {
        label: "ASIENTO",
        sortable: true,
        filterable: false,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item ? item.asiento : ""}</p>
          </TableCell>
        ),
      },
      "documentoDescriptorList.descriptor.descripcion": {
        label: "DESCRIPTORES",
        sortable: true,
        filterable: false,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>
              {item?.documentoDescriptorList
                ?.map((d) => d.descriptor?.descripcion)
                .join(", ")}
            </p>
          </TableCell>
        ),
      },
    };
  };

  const onReloadTable = () => {
    props.dataset.list.setLoading();
    const settings = getSettings();

    const uniqueFilters = Array.from(
      new Set(filters.filter((filter) => filter.key !== "textoDelDocumento"))
    );
    props.dataset.list.queryData.setQuerys([
      ...uniqueFilters,
      {
        key: "estado",
        value: "Vigente",
        condition: C.NOT_EQUALS,
        type: "string",
      },
    ]);

    const params = createParams({
      ...props.dataset.list,
      settings,
      union: U.AND,
    });

    axios
      .get("documento", {
        data: {},
        params,
        signal: abortController.signal,
      })
      .then((resp) => {
        props.dataset.list.resetListData();
        props.dataset.list.dataLoaded(resp.data.data, {
          currentPage: resp.data.meta.currentPage - 1,
          pageSize: resp.data.meta.pageSize,
          itemsCount: resp.data.meta.total,
        });
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado documentos",
          "error"
        );
      });
  };

  React.useEffect(() => {
    if (filters) {
      props.dataset.list.pagingData.changePage(0);
      onReloadTable();
    }
  }, [filters]);

  React.useEffect(() => {
    return () => abortController.abort();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <ListDataProvider
          listStore={props.dataset.list}
          render={(listProps) => (
            <DataTable
              title=""
              settings={getSettings()}
              onReloadTable={onReloadTable}
              Breads={Bread()}
              {...listProps}
            />
          )}
        />
      </div>
      <div className={styles.modal}>
        <DownloadDialog
          open={abrirDialogoDescarga}
          post={post}
          onClose={setAbrirDialogoDescarga}
        />
      </div>
    </React.Fragment>
  );
};

const styles = (theme) => ({
  tableContentDocTitle: {
    fontSize: "11px",
    width: "200px",
    //padding: "0px",
    margin: "0px",
  },
  tableContent: {
    fontSize: "11px",
    width: "20px",
    //padding: "0px",
    margin: "0px",
  },
  tableContentCantidad: {
    fontSize: "11px",
    width: "10px",
    padding: "0 0 0 0",
    margin: "0 0 0 0",
  },
  tableCellDownloadButton: {
    margin: 0,
    padding: 0,
    paddingLeft: "10px",
  },
  tableCellDocTitle: {
    paddingLeft: "10px",
  },
  tableCell: {
    fontSize: "11px",
    width: "auto",
    //padding: "0px",
    margin: "0px",
  },
  tableTitle: {
    fontSize: "11px",
    //width: "auto",
    //padding: "0px",
    margin: "0px",
  },
  tableCant: {
    fontSize: "11px",
    textAlign: "center",
    width: "20px",
    padding: "0",
    margin: "0",
  },
  fab: {
    //position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  paperBread: {
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  paperBreadContainer: {
    display: "flex",
  },
  inputFiltro: {
    fontSize: "16px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
  },
  container: {
    textAlign: "center",
  },
  cell: {
    paddingTop: "1rem",
    marginTop: "1rem",
  },
  label: {
    width: "100%",
    height: "100%",
  },
  txtp1: {
    width: "auto",
    height: "auto",
    fontSize: "16px",
    textDecoration: "none",
    color: "#000000",
    fontWeight: "700",
  },
});

Dataset.propTypes = {
  settings: PropTypes.shape({}),
  onCancel: PropTypes.func,
  onFilterAdd: PropTypes.func,
  onFilterRemove: PropTypes.func,
};

export default withStyles(styles)(inject("dataset")(Dataset));
