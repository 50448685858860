import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
//import {Checkbox,FormControlLabel,IconButton,Typography,Container} from "@material-ui/core";
import { Paper, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";
//import snackbarStore from "../../stores/snackbar-store";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import AuthService from "../../services/auth-service";
import organismoService from "../../services/organismoservice";
import snackbarStore from "../../stores/snackbar-store";
import axios from "../../utils/axios";
import Versiones from "./versiones";

const styles = (theme) => ({
  layout: {
    width: "100%",
    marginbotton: theme.spacing(100),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),

    //display: "row",
    //flexDirection: "column",
    //alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      2
    )}px`,
  },
  container: {
    display: "flex",
    direction: "row",
    alignItems: "center",
  },
  subtittle: {
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    //padding: "2rem",
    marginTop: theme.spacing(2),
    textDecoration: "none",
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "4px",
  },
  txttitulo: {
    textAlign: "left",
    color: "#00529c",
    fontSize: "20px",
  },
  registrarse: {
    textAlign: "left",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
});

let content;
let idDocumento;

const denormalize = (data) => {
  var fechaS = new Date(data.fecha_creacion);
  fechaS.setDate(fechaS.getDate() + 1);

  const target = {
    documento: idDocumento || "",
    fecha_creacion: fechaS || "",
    titulo: data.tituloDocumento || "",
    content: content[1],
    archivoExtension: data.archivoExtension || "",
  };

  return target;
};

const SubirVersion = (props) => {
  const history = useHistory();
  const currentUser = AuthService.getCurrentUser();
  idDocumento = props.idDocumento;
  var reader = new FileReader();
  const { classes } = props;

  const [archivo, setArchivo] = useState({ undefined });
  const [documento, setDocumento] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const [listOrganismos, setListOrganismos] = React.useState([]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size > 52428800) {
      snackbarStore.openSnackbar(
        "El tamaño del archivo no debe ser mayor a 50 MB.",
        "error"
      );
    } else {
      reader.readAsDataURL(fileUploaded);
      reader.onload = function () {
        const base64 = reader.result;
        content = base64.split(",");
        setArchivo(fileUploaded);
        const filename = fileUploaded.name;
        const pieces = filename.split(".");
        const extension = pieces[pieces.length - 1];
        formik.setFieldValue("adjuntar", fileUploaded.name);
        formik.setFieldValue("archivo", fileUploaded.name);
        formik.setFieldValue("archivoExtension", extension);
      };
    }
  };

  React.useEffect(() => {
    axios
      .get(`/documento/${idDocumento}`, {
        data: {},
      })
      .then((resp) => {
        setDocumento(resp.data);
        formik.setFieldValue("tituloDocumento", resp.data.titulo);
        formik.setFieldValue("tipoActividad", resp.data.tipoactividadad);
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
      });
    organismoService.getAll().then((resp) => setListOrganismos(resp.data.data));
  }, []);

  const formik = useFormik({
    initialValues: {
      adjuntar: "",
      tituloDocumento: documento?.titulo ?? "",
      fecha_creacion: "",
      archivoExtension: "",
      tipoActividad: [],
    },
    validationSchema: Yup.object({
      tituloDocumento: Yup.string().required("El título es requerido"),
      adjuntar: Yup.string().required("El archivo es requerido"),
      fecha_creacion: Yup.date().required("La fecha es requerida"),
      archivoExtension: Yup.string().required(),
      tipoDocumento: Yup.string(),
      tipoActividad: Yup.array()
        .of(Yup.object())
        .min(1, "El tipo de actividad es requerido")
        .required("El tipo de actividad es requerido"),
    }),
    onSubmit: (formdata) => {
      const payload = denormalize(formdata);

      const t = AuthService.getCurrentUserT();

      axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;

      axios
        .post("versiones/nuevo", payload, {
          "Content-Type": "application/json",
        })
        .then((resp) => {
          snackbarStore.openSnackbar(
            "Documento guardado exitosamente.",
            "success"
          );
          history.push("/");
          window.location.reload();
        })
        .catch(() => {
          snackbarStore.openSnackbar("Error al guardar el documento", "error");
        });
    },
  });
  return (
    <main className={classes.layout}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
        <a href="/" className={classes.txtlink}>
          Consulta Pública de Normativas
        </a>
        <a href="#" className={classes.txtlink}>
          Subir y moderar
        </a>
        <a href="#" className={classes.txtlink}>
          <strong>Gestionar documentos</strong>
        </a>
      </Breadcrumbs>
      <Paper className={classes.paper}>
        <LinearProgress color="primary" hidden={!formik.isSubmitting} />
        <div align="left">
          <h3 style={{ color: "#0E1820" }}>Subir nueva versión</h3>
        </div>

        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container className={classes.container} spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl margin="dense" fullWidth variant="outlined">
                <TextField
                  label="Titulo del documento"
                  margin="dense"
                  variant="outlined"
                  value={formik.values.tituloDocumento}
                  onChange={formik.handleChange}
                  error={formik.errors.tituloDocumento && true}
                  className={classes.inputS}
                  id="tituloDocumento"
                  name="tituloDocumento"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth>
                <TextField
                  label="ORGANIZACION"
                  margin="dense"
                  variant="outlined"
                  disabled
                  value={currentUser.organizacion?.abreviatura}
                  onChange={formik.handleChange}
                  error={formik.errors.organizacion && true}
                  className={classes.inputS}
                  id="organizacion"
                  name="organizacion"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth>
                <TextField
                  label="Organismo Internacional"
                  margin="dense"
                  variant="outlined"
                  disabled
                  onChange={formik.handleChange}
                  error={formik.errors.organismoInternacionalEmisor && true}
                  value={currentUser.organismoInternacional?.abreviatura}
                  className={classes.inputS}
                  id="organismo_internacional"
                  name="organismo_internacional"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                margin="dense"
                variant="outlined"
                label="Adjuntar documento"
                id="adjuntar"
                name="adjuntar"
                fullWidth
                className={classes.inputS}
                value={archivo ? archivo.name : "vacio"}
                error={formik.errors.adjuntar && true}
                helperText={formik.errors.adjuntar}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Button onClick={handleClick}>
                        <AttachFileIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <input
                accept="application/pdf,.doc,.docx"
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <span style={{ fontSize: "11px" }}>
                {" "}
                *El documento debe estar en formato PDF o MS Word y no superar
                los 50MB
              </span>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                margin="dense"
                variant="outlined"
                label="Fecha de Publicación"
                value={formik.values.fecha_creacion}
                onChange={formik.handleChange}
                error={formik.errors.fecha_creacion && true}
                helperText={formik.errors.fecha_creacion}
                type="date"
                className={classes.inputS}
                id="fecha_creacion"
                name="fecha_creacion"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  margin="dense"
                  variant="outlined"
                  value={{ ...{ ...documento }.tipo_documento }.descripcion}
                  onChange={formik.handleChange}
                  error={formik.errors.tipoDocumento && true}
                  className={classes.inputS}
                  id="tipoDocumento"
                  name="tipoDocumento"
                  labelwidth={130}
                  label="Tipo de Documento"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <div>
                <br />
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Autocomplete
                disabled
                multiple
                size="small"
                id="tipo_actividad"
                value={formik.values.tipoActividad}
                options={formik.values.tipoActividad}
                getOptionLabel={(option) => option.tipo_actividad.descripcion}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      disabled
                      label={option.tipo_actividad.descripcion}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tipo de Actividad"
                    placeholder="Tipo de Actividad"
                    fullWidth
                    error={formik.errors.tipoActividad && true}
                    helperText={formik.errors.tipoActividad}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <a href="/">
                <Button
                  className={classes.submit}
                  fullWidth
                  variant="outlined"
                  //size="large"
                  color="primary"
                >
                  Cancelar
                </Button>
              </a>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                type="submit"
                className={classes.submit}
                fullWidth
                variant="contained"
                //size="large"
                color="primary"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <CircularProgress color="secondary" size="1.8em" />
                ) : (
                  "ENVIAR"
                )}
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </form>

        <div>
          <Versiones idDocumento={idDocumento} />
        </div>
      </Paper>
    </main>
  );
};

export default withStyles(styles)(SubirVersion);
