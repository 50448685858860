import { TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import DataTable from "../../toolkit/DataTable/DataTable";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import axios from "../../utils/axios";
//import { Route } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/auth-service";
import snackbarStore from "../../stores/snackbar-store";
import file_path from "../../utils/files_path";
import createParams from "../../utils/tables-params";

const DatasetHistorico = (props) => {
  const currentUSer = AuthService.getCurrentUser();
  const abortController = new AbortController();

  props.comentarios.list.queryData.setQuerys([
    {
      key: "documento.usuario.organizacion.id",
      value: parseFloat(currentUSer?.organizacion?.id),
      condition: "equals",
      type: "string",
    },
    {
      key: "estado",
      value: "Pendiente",
      condition: "notEquals",
      type: "string",
    },
  ]);
  const orderData = {
    orders: {
      "comentarioRespondido.id": "desc",
      orderComentarioRespondido: "asc",
      fecha: "desc",
    },
  };

  const Bread = () => {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
          <a className={classes.txtlink} href="/">
            Consulta Pública de Normativas
          </a>
          <a className={classes.txtlink} href="/moderar">
            Moderar comentarios
          </a>
          <a className={classes.txtlink} href={props.match.url}>
            <strong>Historico de comentarios</strong>
          </a>
        </Breadcrumbs>
      </>
    );
  };

  const { classes } = props;

  const getSettings = () => {
    return {
      "documento.titulo": {
        label: "TITULO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableContentDocTitle}>
            {item?.documento ? item.documento.titulo : ""}
          </TableCell>
        ),
      },
      "documento.tipo_documento.descripcion": {
        label: "TIPO DE DOCUMENTO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            {item?.documento ? item.documento.tipo_documento.descripcion : ""}
          </TableCell>
        ),
      },
      "documento.estado": {
        label: "ESTADO DE DOCUMENTO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            {item?.documento ? item.documento.estado : ""}
          </TableCell>
        ),
      },
      "usuario.nombre_apellido": {
        label: "NOMBRE",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            {item ? item.usuario.nombre_apellido : ""}
          </TableCell>
        ),
      },
      fecha: {
        label: "FECHA DEL COMENTARIO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            {item ? moment(item.fecha).format("DD-MM-YYYY") : ""}
          </TableCell>
        ),
      },
      "usuario.organizacion.abreviatura": {
        label: "ORGANIZACION",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            {item ? item.usuario.organizacion?.abreviatura : ""}
          </TableCell>
        ),
      },
      correo: {
        label: "CORREO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            {item ? item.usuario?.email : ""}
          </TableCell>
        ),
      },
      estado: {
        label: "ESTADO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell
            className={classes.tableCell}
            style={{ whiteSpace: "nowrap" }}
          >
            {item.estado ?? ""}
          </TableCell>
        ),
      },
      comentario: {
        label: "COMENTARIO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item ? item.comentario : ""}
          </TableCell>
        ),
      },
      respuesta: {
        label: "RESPUESTA",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item ? item.respuesta : ""}
          </TableCell>
        ),
      },
      actions: {
        label: "ACCIONES",
        sortable: false,
        filterable: false,
        cellRendering: (item) => (
          <TableCell className={classes.tableCellAction}>
            <div style={{ display: "flex", padding: 0 }}>
              <div className={classes.button}>
                {item.ubicacion ? (
                  <a
                    href={file_path + item.ubicacion}
                    download={file_path + item.ubicacion}
                    target="_blank"
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.button}
                    >
                      DESCARGAR ARCHIVO ADJUNTO
                    </Button>
                  </a>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.button}
                    disabled={true}
                  >
                    DESCARGAR ARCHIVO ADJUNTO
                  </Button>
                )}
              </div>
            </div>
          </TableCell>
        ),
      },
    };
  };

  const onReloadTable = () => {
    props.comentarios.list.setLoading();
    let settings = getSettings();
    let params = createParams({
      ...props.comentarios.list,
      settings,
      orderData,
    });

    //const defaultFilters = [{key: 'documento', value: parseFloat(idDocumento), condition: 'equals', type: 'selection'}];

    //params.page = "all";
    //params.pageSize = props.comentarios.list.pagingData.itemsCount;

    axios
      .get("comentario/", {
        data: {},
        params,
        signal: abortController.signal,
      })
      .then((resp) => {
        props.comentarios.list.resetListData();
        props.comentarios.list.dataLoaded(resp.data.data, {
          currentPage: resp.data.meta.currentPage - 1,
          pageSize: resp.data.meta.pageSize,
          itemsCount: resp.data.meta.total,
        });
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado comentarios",
          "error"
        );
      });
  };

  React.useEffect(() => {
    return () => abortController.abort();
  }, []);

  if (currentUSer) {
    return (
      <React.Fragment>
        <div className={classes.layout}>
          <ListDataProvider
            listStore={props.comentarios.list}
            render={(listProps) => (
              <DataTable
                //title="Conjuntos de Datos"
                Breads={Bread()}
                settings={getSettings()}
                onReloadTable={onReloadTable}
                {...listProps}
              />
            )}
          />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      snackbarStore.openSnackbar(
        "Para realizar un comentario, primero inicie sesión",
        "warning"
      ),
      (<Redirect to={"/login/" + encodeURIComponent(props.match.url)} />)
    );
  }
};
//lightTooltip se puede volver a implementar
/*const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#D6EBFF",
        color: "#00529c",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 250,
        textAlign: "center"
    },
}))(Tooltip);*/

const styles = (theme) => ({
  tableContentDocTitle: {
    fontSize: "11px",
    width: "200px",
    //padding: "0px",
    margin: "0px",
  },
  tableContent: {
    fontSize: "11px",
    width: "20px",
    //padding: "0px",
    margin: "0px",
  },
  tableCell: {
    fontSize: "11px",
    //width: "auto",
    //padding: "0px",
    margin: "0px",
  },
  tableTitle: {
    fontSize: "11px",
    //width: "auto",
    //padding: "0px",
    margin: "0px",
  },
  tableCant: {
    fontSize: "11px",
    textAlign: "center",
    width: "20px",
    padding: "0",
    margin: "0",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    width: "auto",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
    //width: "20px",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  tableContentDocTitle: {
    fontSize: "11px",
    width: "200px",
    //padding: "0px",
    margin: "0px",
  },
  dialogoJustificar: {
    width: "500px",
  },
});

export default withStyles(styles)(
  inject("comentarios")(observer(DatasetHistorico))
);
