import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse, ListItem, ListItemText, styled } from "@material-ui/core";
import { useState } from "react";

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: "16px",
  fontWeight: "800",
  "MuiTypography-body1" : {fontWeight: "800"}
}));

export default function CollapsibleMenuItem({
  children,
  text,
  isNested = false,
  isActive = false,
}) {
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    event.stopPropagation();
    setOpen((open) => !open);
  };
  const styled = isActive ? { fontWeight: "800", fontSize: '16px' } : {fontSize: '16px'};
  return (
    <>
      <ListItemStyled
        button
        onClick={handleClick}
        style={{ paddingLeft: isNested ? "32px" : "16px" }}
      >
        <ListItemText primary={text} primaryTypographyProps={{style : styled}}/>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemStyled>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}
