import React from "react";
import axios from "../utils/axios";
import snackbarStore from "../stores/snackbar-store";
import {useState, useEffect}  from 'react';
import { Redirect } from "react-router-dom";

const estadousuario = props => {

     const [editado,setEditado]=useState(null);
     const idUsuario = props.match.params.idUsuario;
     const payload = {'id':idUsuario};
        useEffect(() =>{
            axios
                .put("usuarios/estado", payload, {'Content-Type': 'application/json'})
                .then(resp => {
                    snackbarStore.openSnackbar("Cambio de estado exitoso", "success");
                    setEditado(true)
                })
                .catch(() => {
                    snackbarStore.openSnackbar("Error al obtener los datos del servidor", "error");
                });

        },[])

    return (
        <div>
            {editado? <Redirect to="/usuarios"/> : 'cargando'}
        </div>
    )
}

export default (estadousuario);