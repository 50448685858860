import { observable, decorate } from "mobx";
import ListData from "../toolkit/mobx-connectors/observables/list-data";

class ComentariosStore {
  list = new ListData();
}

decorate(ComentariosStore, {
  list: observable
});

const comentariosStore = new ComentariosStore();
export default comentariosStore;
