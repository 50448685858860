import cnfcImage from "./images/solologocnfc.png";
import classes from "./HeaderImageLink.module.css";

export default function HeaderImageLink() {
  return (
    <a href="https://www.cnfc.gov.py" className={classes.HeaderImageLink}>
      <img
        className={`${classes.headerImg} ${classes.cnfcImage}`}
        src={cnfcImage}
        alt="CNFC"
      />
    </a>
  );
}
