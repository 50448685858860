import { observable, decorate, action } from "mobx";

class SnackbarStore {
  open = false;
  variant = "";
  message = "";
  duration = 0;
  openSnackbar(message, variant = "info", duration = 6000) {
    this.open = true;
    this.message = message;
    this.variant = variant;
    this.duration = duration
  }
  closeSnackbar() {
    this.open = false;
  }
}

decorate(SnackbarStore, {
  open: observable,
  variant: observable,
  message: observable,
  duration: observable,
  openSnackbar: action,
  closeSnackbar: action
});

const snackbarStore = new SnackbarStore();

export default snackbarStore;
