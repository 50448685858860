import { observable, decorate, action } from "mobx";

class PagingData {
  currentPage = 0;
  pageSize = 10; //Enrique: Aqui cambiar cantidad de filas por pagina
  itemsCount = 0;

  changePage(page) {
    this.currentPage = page;
  }

  changeItemsPerPage(itemsPerPage) {
    this.currentPage = 0;
    this.pageSize = itemsPerPage;
  }

  resetPagingData() {
    this.currentPage = 0;
    this.itemsCount = 0;
  }

  setPagingData(pagingData) {
    this.currentPage = pagingData.currentPage;
    this.pageSize = pagingData.pageSize;
    this.itemsCount = pagingData.itemsCount;
  }
}

decorate(PagingData, {
  currentPage: observable,
  pageSize: observable,
  itemsCount: observable,
  changePage: action,
  changeItemsPerPage: action,
  resetPagingData: action,
  setPagingData: action
});

export default PagingData;
