import { action, decorate, observable } from "mobx";
import ItemsData from "./items-data";
import OrderData from "./order-data";
import PagingData from "./paging-data";
import QueryData from "./query-data";

class ListData {
  pagingData = new PagingData();
  itemsData = new ItemsData();
  orderData = new OrderData();
  queryData = new QueryData();

  setLoading(isLoading = true) {
    this.itemsData.setLoading(isLoading);
  }

  resetListData() {
    this.pagingData.resetPagingData();
    this.itemsData.resetItemsData();
  }

  dataLoaded(data, pagingData) {
    this.itemsData.setItemsData(data);
    if (pagingData) {
      this.pagingData.setPagingData(pagingData);
    } else {
      //this.pagingData = null;
      //comentando la linea de arriba  deja de romperse
      //al regresas a la pagina anterior
    }
  }
}

decorate(ListData, {
  pagingData: observable,
  itemsData: observable,
  orderData: observable,
  queryData: observable,
  setLoading: action,
  resetListData: action,
  dataLoaded: action,
});

export default ListData;
