import { List } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MenuListByUser from "./menus/MenuListByUser";
import AuthService from "../../services/auth-service";

export default function MenuList(props) {
  const currentUser = AuthService.getCurrentUser();
  return (
    <Box role="presentation" {...props}>
      <List component="nav">
        <MenuListByUser user={currentUser} />
      </List>
    </Box>
  );
}
