import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { FilterConditions, FilterTypes } from "../../utils/filter";

const GENERAL_FILTER_FIELD = "_GENERAL_FILTER_FIELD_";

/**
 * Componente que renderiza un toolbar con text input con el que realizar filtrados.
 * Recibe en los props el state de busqueda, con el siguiente formato:
 * [{
 *  key: String, el field para el cual se aplica el filtro,
 *  value: el valor del filtro. Puede ser cualquier valor,
 *  condition: FilterConditions; // -> ver el archivo '/utils/filter',  la condicion que se esta aplicando.
 *  type: FilterTypes; // -> ver el archivo '/utils/filter', el identificador del tipo de dato
 * }]
 * Ademas, recibe los dispatches para:
 *  1. onFilterAdded: filters => {} que se invoca cuando se agrega el filtro de
 * filtrado general
 * @param {String} props
 * @since 0.1.0
 */

class QueryBox extends React.Component {
  state = {
    generalFilter: "",
  };

  constructor(props) {
    super(props);
    this.onGeneralFilterChanged = this.onGeneralFilterChanged.bind(this);
  }

  onGeneralFilterChanged(event) {
    this.setState({ generalFilter: event.target.value });
  }

  onAddGeneralFilter() {
    const newFilter = {
      key: GENERAL_FILTER_FIELD,
      value: this.state.generalFilter,
      condition: FilterConditions.LIKE,
      type: FilterTypes.STRING,
    };
    this.props.onFilterAdd([newFilter]);
    this.setState({ generalFilter: "" });
  }

  formatValueByType(filter) {
    switch (filter.type) {
      case FilterTypes.BOOLEAN:
        return filter.value === "true" ? "Sí" : "No";
      case FilterTypes.DATE:
        return moment(filter.value).format("DD-MM-YYYY");
      case FilterTypes.NUMERIC:
        return Number(filter.value).toLocaleString();
      case FilterTypes.SELECTION:
        const valores = this.props.selectionFilterValues[filter.key];
        if (valores) {
          const label = valores.filter((item) => item.value === filter.value)[0]
            .label;
          return `${label}`;
        } else {
          return `...`;
        }
      default:
        return filter.value;
    }
  }

  getChipLabelByFilter(filter) {
    const { settings } = this.props;
    const formattedValue = this.formatValueByType(filter);
    switch (filter.condition) {
      case FilterConditions.EQUALS:
        return `${settings[filter.key].label} = ${formattedValue}`;
      case FilterConditions.NOT_EQUALS:
        return `${settings[filter.key].label} no igual a ${formattedValue}`;
      case FilterConditions.LIKE:
        return `${
          filter.key === GENERAL_FILTER_FIELD
            ? "Cualquier campo"
            : settings[filter.key].label
        } contiene '${formattedValue}'`;
      case FilterConditions.GT:
        return `${settings[filter.key].label} > ${formattedValue}`;
      case FilterConditions.LT:
        return `${settings[filter.key].label} < ${formattedValue}`;
      case FilterConditions.GTE:
        return `${settings[filter.key].label} desde: ${formattedValue}`;
      case FilterConditions.LTE:
        return `${settings[filter.key].label} hasta: ${formattedValue}`;
      default:
        break;
    }
  }

  render() {
    const { classes, filters, onFilterRequested } = this.props;
    const { generalFilter } = this.state;
    return (
      <div>
        {filters && filters.length > 0 ? (
          <div className={classes.filtersContainer}>
            <Typography variant="body2" className={classes.filterLabel}>
              Filtros aplicados
            </Typography>
            {filters.map((filter) => (
              <Chip
                color={
                  filter.key === GENERAL_FILTER_FIELD ? "primary" : "secondary"
                }
                onClick={() => onFilterRequested(filter)}
                style={{ marginRight: "4px", marginBottom: "5px" }}
                key={`${filter.key}-${filter.condition}`}
                variant="outlined"
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* Cualquier campo que contiene &nbsp;
                    <span>{`'${filter.value}'`}</span> */}
                    {this.getChipLabelByFilter(filter)}
                  </div>
                }
                onDelete={() => this.props.onFilterRemove(filter)}
              />
            ))}
          </div>
        ) : (
          <Toolbar>
            <IconButton disabled style={{ paddingLeft: "18px" }}>
              <SearchIcon />
            </IconButton>
            <FormControl fullWidth>
              <Input
                fullWidth
                autoFocus
                placeholder="Filtro general"
                value={generalFilter}
                onChange={this.onGeneralFilterChanged}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    this.onAddGeneralFilter();
                  }
                }}
              />
            </FormControl>
          </Toolbar>
        )}
      </div>
    );
  }
}

const styleSheet = (theme) => ({
  filtersContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingLeft: theme.spacing(3) + 24,
    paddingRight: theme.spacing(3) + 24,
    paddingBottom: 12,
    paddingTop: 12,
  },
  filterLabel: {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: "0.5rem",
    marginBottom: "5px",
    height: "100%",
  },
});

QueryBox.propTypes = {
  /**
   * @ignore
   */
  classes: PropTypes.any,

  /**
   * Los settings de config
   */
  settings: PropTypes.shape({}),

  /**
   * Invocado para editar un filtro que ya se encuentra agregado
   */
  onFilterRequested: PropTypes.func.isRequired,

  baseFilter: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.any,
    condition: PropTypes.oneOf([
      ...Object.keys(FilterConditions).map((key) => FilterConditions[key]),
    ]),
    type: PropTypes.oneOf([
      ...Object.keys(FilterTypes).map((key) => FilterTypes[key]),
    ]),
  }),

  /**
   * Objeto que contiene los filtros aplicados al datatable.
   */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.any,
      condition: PropTypes.oneOf([
        ...Object.keys(FilterConditions).map((key) => FilterConditions[key]),
      ]),
      type: PropTypes.oneOf([
        ...Object.keys(FilterTypes).map((key) => FilterTypes[key]),
      ]),
    })
  ).isRequired,

  /**
   * Función para agregar nuevos filtros
   */
  onFilterAdd: PropTypes.func.isRequired,

  /**
   * Funcion para eliminar un filtro
   */
  onFilterRemove: PropTypes.func.isRequired,
};

export default withStyles(styleSheet)(QueryBox);
export { GENERAL_FILTER_FIELD };
