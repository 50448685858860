import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import React from "react";
import { withRouter } from "react-router-dom";
import { DRAWER_WIDTH } from "../constants";
import Header from "../header";
import useGetUserRole from "../hooks/useGetUserRole";
import { useLayout } from "../hooks/useLayout";
import AuthService from "../services/auth-service";
import Sidenav from "./sidenav/Sidenav";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  appFrame: {
    display: "flex",
    width: "100%",
    paddingBotton: "10px",
  },
  appBar: {
    position: "relative",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "appBarShift-left": {
    marginLeft: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#F1F1F1", //rgba(0, 28, 84, 0.3)
    //height: "100%",
    //backgroundColor: '#72616e',
    padding: theme.spacing(3),
    //height: "100vh",
    overflow: "auto",
    height: "calc(100vh - 64px)",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  "content-left": {
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "contentShift-left": {
    marginLeft: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  Spacer: theme.mixins.toolbar,
  imgContainer: {
    backgroundColor: "white",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  footer: {
    width: "100px",
    color: "#F1F1F1",
    backgroundColor: "#F1F1F1",
    marginTop: theme.spacing(4),
    padding: `${theme.spacing(3)}px 0`,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  tabs: {
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    borderRadius: `${theme.spacing(0.5, 0.5)} 0 0 `,
  },
  tabIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
});

const VueAppbar = (props) => {
  const currentUser = AuthService.getCurrentUser();
  const { isCiudadanoRole } = useGetUserRole({ user: currentUser });
  const { drawerWidth, isMobile, openMenu, toggleDrawer } =
    useLayout(DRAWER_WIDTH);
  const { classes } = props;

  const items = [
    {
      path: "/",
      label: "Conjuntos de datos",
      items: [],
    },
    {
      path: "/visualizaciones",
      label: "Visualizaciones",
    },
    {
      path: "/legal",
      label: "Asuntos Legales",
    },
    {
      path: "/about",
      label: "Acerca de",
    },
  ];

  const showSubTabs = () => {
    const pathList = props.history.location?.pathname.split("/");
    let mostrar = false;
    items[0].items.forEach(function (item) {
      if (pathList.length >= 3 && pathList[2] === item.path) {
        mostrar = true;
      }
    });
    return mostrar;
  };

  const isMobileOrPublicOrCiudadano =
    !currentUser || isCiudadanoRole;
  const sidebarWidth = isMobileOrPublicOrCiudadano ? 0 : drawerWidth;
  const mainStyle = {
    width: isMobileOrPublicOrCiudadano
      ? "100%"
      : `calc(100vw - ${sidebarWidth}px)`,
    marginLeft: `${sidebarWidth}px`,
  };

  return (
    <div className={classes.root}>
      <Header onMenuClick={toggleDrawer} />
      <div className={classes.appFrame}>
        <Sidenav
          isMobile={isMobile}
          drawerWidth={sidebarWidth}
          open={openMenu}
          toggleDrawer={toggleDrawer}
        />
        <main className={classes.content} style={mainStyle}>
          <div className={classes.appBarSpacer} />
          {showSubTabs() && (
            <div className={classes.layout}>
              <Paper className={classes.tabs}></Paper>
            </div>
          )}
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(styles, { withTheme: true })(withWidth()(VueAppbar))
);
