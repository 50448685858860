import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";

async function getAllVersionByArchivoId(id) {
  const user = JSON.parse(localStorage.getItem("userData"));
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
  axios.defaults.headers.common["Content-Type"] = `application/json`;
  try {
    return axios.get(`archivos/${id}/versiones`, {
      data: {},
    });
  } catch {
    snackbarStore.openSnackbar(
      "Error al obtener los datos del servidor",
      "error"
    );
  }
}

async function createArchivo({ archivoId, payload }) {
  const url = `archivos/crear${archivoId ? `/${archivoId}/version` : ""}`;
  const user = JSON.parse(localStorage.getItem("userData"));
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
  axios.defaults.headers.common["Content-Type"] = `application/json`;
  try {
    await axios.post(url, payload, {
      "Content-Type": "application/json",
    });
    snackbarStore.openSnackbar("Archivo guardado exitosamente.", "success");
    return;
  } catch {
    snackbarStore.openSnackbar("Error al guardar el Archivo", "error");
  }
}

export default {
  createArchivo,
  getAllVersionByArchivoId,
};
