import React from "react";
import { observer, inject } from "mobx-react";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import DataTable from "../../toolkit/DataTable/DataTable";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import axios from "../../utils/axios";
import Filter from "../../utils/filter";
import createParams from "../../utils/filter-params";
import snackbarStore from "../../stores/snackbar-store";

class Rubro extends React.Component {
  state = {
    empresa: {}
  };

  getSettings() {
    return {
      "productosRubro.rubro": {
        label: "Rubro",
        sortable: false,
        filterable: true,
        type: "string",
        cellRendering: item => (
          <TableCell>
            {item.productosRubro? item.productosRubro.rubro : ''}
          </TableCell>
        )
      },
    };
  }

  onReloadTable() {
    let settings = this.getSettings();
    let params = createParams({ ...this.props.rubros.list, settings });
    
    const empresaId = this.props.match.params.empresaId;

    if (params.filter) {
      params.filter.and(Filter.path('empresa.id').eq(empresaId));
      params.filter = JSON.stringify(params.filter);
    } else {
      params.filter = JSON.stringify(Filter.path('empresa.id').eq(empresaId));
    }

    axios
      .get(`empresa/${empresaId}`, {
        data: {}
      })
      .then(resp => {
        this.setState({
          empresa: resp.data
        })
      }
      )
      .catch(() => {
        snackbarStore.openSnackbar("Error al obtener el empresa", "error");
      });

    axios
      .get(`rubro`, {
        data: {},
        params
      })
      .then(resp => {
        this.props.rubros.list.dataLoaded(resp.data.data, {
          currentPage: resp.data.meta.currentPage - 1,
          pageSize: resp.data.meta.pageSize,
          itemsCount: resp.data.meta.total
        })
      }
      )
      .catch(() => {
        snackbarStore.openSnackbar("Error al obtener las rubros", "error");
      });

  }

  render() {

    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.layout}>
          <ListDataProvider
            listStore={this.props.rubros.list}
            render={listProps => (
              <DataTable
                title={`Rubros que exporta: `}
                description={`${this.state.empresa.razonsocial}`}
                settings={this.getSettings()}
                onReloadTable={() => this.onReloadTable()}
                {...listProps}
              />
            )}
          />
        </div>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }
});

export default withStyles(styles)(inject("rubros")(observer(Rubro)));
