import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "material-ui-pickers/DatePicker";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { FilterConditions, FilterTypes } from "../../utils/filter";
import { GENERAL_FILTER_FIELD } from "./QueryBox";

const styles = (theme) => ({
  title: {
    // backgroundColor: theme.palette.primary[100]
  },
});

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const conditionsByType = {
  [FilterTypes.STRING]: [
    {
      condition: FilterConditions.LIKE,
      label: "Contiene",
    },
    {
      condition: FilterConditions.EQUALS,
      label: "Igual a",
    },
  ],
  [FilterTypes.NUMERIC]: [
    {
      condition: FilterConditions.LT,
      label: "Menor que",
    },
    {
      condition: FilterConditions.EQUALS,
      label: "Igual a",
    },
    {
      condition: FilterConditions.GT,
      label: "Mayor que",
    },
  ],
  [FilterTypes.DATE]: [
    {
      condition: FilterConditions.GTE,
      label: "Desde",
    },
    {
      condition: FilterConditions.EQUALS,
      label: "Igual a",
    },
    {
      condition: FilterConditions.LTE,
      label: "Hasta",
    },
  ],
  [FilterTypes.BOOLEAN]: [
    {
      condition: FilterConditions.EQUALS,
      label: "Igual a",
    },
  ],
  [FilterTypes.SELECTION]: [
    {
      condition: FilterConditions.EQUALS,
      label: "Igual a",
    },
  ],
};

const ValueHandler = (props) => {
  const { type, value, onValueChanged, errors, selectionFilterOptions } = props;
  return (
    <React.Fragment>
      {(type === FilterTypes.STRING || type === FilterTypes.NUMERIC) && (
        <TextField
          fullWidth
          type={type === FilterTypes.NUMERIC ? "number" : null}
          label="Valor de comparación"
          value={value}
          onChange={(e) => onValueChanged(e.target.value)}
          margin="normal"
          variant="outlined"
          error={errors.value}
        />
      )}
      {type === FilterTypes.DATE && (
        <DatePicker
          value={value}
          invalidLabel=""
          variant="outlined"
          label="Valor de comparación"
          margin="normal"
          fullWidth
          autoOk
          ampm="false"
          okLabel="Aceptar"
          cancelLabel="Cancelar"
          format="DD-MM-YYYY"
          error={errors.value}
          onChange={(date) => onValueChanged(date)}
          animateYearScrolling
          helperText=""
        />
      )}
      {type === FilterTypes.BOOLEAN && (
        <TextField
          select
          label="Valor de comparación"
          fullWidth
          value={value}
          onChange={(event) => onValueChanged(event.target.value)}
          margin="normal"
          variant="outlined"
          error={errors.value}
        >
          <MenuItem value="true">Sí</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </TextField>
      )}
      {type === FilterTypes.SELECTION && (
        <TextField
          select
          label="Valor de comparación"
          fullWidth
          value={value}
          onChange={(event) => onValueChanged(event.target.value)}
          margin="normal"
          variant="outlined"
          error={errors.value}
        >
          {selectionFilterOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </React.Fragment>
  );
};

class FilterEditor extends React.Component {
  constructor(props) {
    super(props);
    const { settings } = props;
    const fields = Object.keys(settings)
      .filter((key) => settings[key].filterable)
      .map((key) => ({ key, label: settings[key].label }));
    const generalQueryField = {
      key: GENERAL_FILTER_FIELD,
      label: "Todos los campos",
    };
    let stateFromBaseFilter = {};
    const { baseFilter } = props;
    if (baseFilter) {
      stateFromBaseFilter = {
        selectedField: baseFilter.key,
        selectedCondition: baseFilter.condition,
        value: baseFilter.value,
        conditions:
          baseFilter.key === GENERAL_FILTER_FIELD
            ? []
            : conditionsByType[props.settings[baseFilter.key].type],
      };
    }
    this.state = {
      selectedField: "",
      selectedCondition: "",
      value: "",
      fields: [generalQueryField, ...fields],
      conditions: null,
      errors: {},
      selectionFilterOptions: [],
      ...stateFromBaseFilter,
    };
  }

  componentDidMount() {
    const { selectedField } = this.state;
    if (selectedField) {
      this.setSelectionFilterOptions(selectedField);
    }
  }

  setSelectionFilterOptions(selectedField) {
    const { settings } = this.props;
    if (selectedField === GENERAL_FILTER_FIELD) {
      return;
    }
    if (settings[selectedField].type === FilterTypes.SELECTION) {
      const optionsFromSettings = settings[selectedField].selectOptions;
      if (optionsFromSettings.then) {
        optionsFromSettings.then((options) =>
          this.setState({ selectionFilterOptions: options })
        );
      } else {
        this.setState({ selectionFilterOptions: optionsFromSettings });
      }
    }
  }

  onFieldChanged(value) {
    const conditionsByCurrentType =
      value === GENERAL_FILTER_FIELD
        ? []
        : conditionsByType[this.props.settings[value].type];
    this.setState({
      selectedField: value,
      conditions: conditionsByCurrentType,
      selectedCondition:
        value === GENERAL_FILTER_FIELD
          ? FilterConditions.LIKE
          : conditionsByCurrentType.length === 1
          ? conditionsByCurrentType[0].condition
          : "",
      errors: {},
      selectionFilterOptions: [],
    });
    this.setSelectionFilterOptions(value);
  }

  onConditionChanged(value) {
    this.setState({
      selectedCondition: value,
      errors: {},
    });
  }

  onValueChanged(element) {
    let value;
    if (moment.isMoment(element)) {
      value = element.toDate();
    } else {
      value = element;
    }
    this.setState({
      value,
      errors: {},
    });
  }

  onFilterAdd() {
    const { selectedField, selectedCondition, value } = this.state;
    const errors = {
      selectedField: !selectedField,
      selectedCondition: !selectedCondition,
      value: !value,
    };
    if (
      Object.keys(errors).reduce(
        (acc, current) => acc || errors[current],
        false
      )
    ) {
      this.setState({ errors });
      return;
    }
    const newFilter = {
      key: selectedField,
      value,
      condition: selectedCondition,
      type:
        selectedField === GENERAL_FILTER_FIELD
          ? FilterTypes.STRING
          : this.props.settings[selectedField].type,
    };
    if (this.props.baseFilter) {
      this.props.onFilterRemove(this.props.baseFilter);
    }
    this.props.onFilterAdd([newFilter]);
    this.props.onCancel();
  }

  render() {
    const { onCancel, classes, settings } = this.props;
    const {
      selectedField,
      selectedCondition,
      fields,
      conditions,
      value,
      errors,
      selectionFilterOptions,
    } = this.state;
    return (
      <div>
        <Dialog
          fullWidth
          open={true}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => onCancel()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className={classes.title}>Agregar filtro</DialogTitle>
          <DialogContent>
            <TextField
              select
              autoFocus
              label="Campo"
              fullWidth
              value={selectedField}
              onChange={(event) => this.onFieldChanged(event.target.value)}
              margin="normal"
              variant="outlined"
              error={errors.selectedField}
            >
              {fields.map((field) => (
                <MenuItem key={field.key} value={field.key}>
                  {field.label}
                </MenuItem>
              ))}
            </TextField>
            {selectedField && conditions.length > 1 && (
              <TextField
                select
                label="Tipo de comparación"
                fullWidth
                value={selectedCondition}
                onChange={(event) =>
                  this.onConditionChanged(event.target.value)
                }
                margin="normal"
                variant="outlined"
                error={errors.selectedCondition}
              >
                {conditions.map((condition) => (
                  <MenuItem
                    key={condition.condition}
                    value={condition.condition}
                  >
                    {condition.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {selectedCondition && (
              <ValueHandler
                value={value}
                onValueChanged={(value) => this.onValueChanged(value)}
                type={
                  selectedField === GENERAL_FILTER_FIELD
                    ? FilterTypes.STRING
                    : settings[selectedField].type
                }
                selectionFilterOptions={selectionFilterOptions}
                errors={errors}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="secondary" variant="outlined">
              Cancelar
            </Button>
            <Button
              onClick={() => this.onFilterAdd()}
              color="primary"
              variant="outlined"
            >
              Agregar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FilterEditor.propTypes = {
  settings: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onFilterRemove: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterEditor);
