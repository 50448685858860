import Drawer from "@material-ui/core/Drawer";

import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  root: {
    display: "block",
    position: "relative",
  },
  "MuiDrawerPaper": {
    boxSizing: "border-box",
    height: "calc(100vh - 64px)",
    overflowX: "hidden",
  }
});

const SidenavStyled = ({
  isMobile,
  width,
  children,
  classes,
  ...props
}) => (
  <Drawer
    anchor="left"
    className={classes.root}
    PaperProps={{
      className: classes.MuiDrawerPaper,
      style: {
        position: "fixed",
        top: isMobile ? 56 : 64,
        width,
      }
    }}
    variant={isMobile ? "temporary" : "permanent"}
    {...props}
  >
    {children}
  </Drawer>
);

export default withStyles(styles)(SidenavStyled);
