import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React from "react";
import { Link } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import cnfc from "../images/solologocnfc.png";
import AuthService from "../services/auth-service";
import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";

const required = (value) => {
  if (!value) {
    return snackbarStore.openSnackbar("Campo requerido");
  }
};

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  txtaviso: {
    textAlign: "center",
    color: "#00529c",
    fontSize: "15px",
  },
  registrarse: {
    marginTop: "14px",
    textAlign: "left",
    fontSize: "19px",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "10px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#00529c",
    fontWeight: "700",
  },
});

function Login(props) {
  const form = React.useRef();
  const checkBtn = React.useRef();
  const urlAnterior = props.match.params.urlAnterior;
  const userHash = props.match.params.userHash;

  const [resultadoHash, setResultadoHash] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  if (userHash != null) {
    React.useEffect(() => {
      axios
        .get(`usuarios/activar/${userHash}`, {
          data: {},
        })
        .then(
          (resp) => {
            resp.data == "Ok"
              ? setResultadoHash(
                  "Proceso de activación de cuenta exitoso, por favor, ingrese su email y contraseña"
                )
              : setResultadoHash("Hubo un error en el proceso de activación");
            snackbarStore.openSnackbar(
              "Proceso de activación de cuenta exitoso",
              "success"
            );
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            snackbarStore.openSnackbar("No se pudo activar su cuenta", "error");
            setResultadoHash("Hubo un error en el proceso de activación");
          }
        );
    }, []);
  }

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      if (username != "" && password != "") {
        AuthService.login(username, password).then(
          (resp) => {
            if (!JSON.parse(localStorage.getItem("userData"))) {
              snackbarStore.openSnackbar("Credenciales inválidas", "error");
            } else {
              if (urlAnterior) {
                props.history.push(decodeURIComponent(urlAnterior));
              } else {
                props.history.push("/");
              }
              window.location.reload();
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            snackbarStore.openSnackbar(resMessage, "error");
          }
        );
      } else {
        snackbarStore.openSnackbar(
          "Por favor, indique nombre de usuario y contraseña",
          "error"
        );
      }
    } else {
      setLoading(false);
    }
  };

  const { classes } = props;

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <a href="https://www.cnfc.gov.py">
            <img width="350px" src={cnfc} alt="CNFC" />
          </a>

          <p className={classes.txtaviso}>{resultadoHash}</p>

          <Form className={classes.form} onSubmit={handleLogin} ref={form}>
            <FormControl margin="normal" fullWidth variant="outlined">
              <TextField
                className={classes.inputS}
                variant="outlined"
                color="primary"
                fullWidth
                id="username"
                name="username"
                autoComplete="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
                autoFocus
                InputLabelProps={{ shrink: true }}
                label="Email"
              />
            </FormControl>
            <FormControl margin="normal" fullWidth variant="outlined">
              <TextField
                className={classes.inputS}
                variant="outlined"
                color="primary"
                fullWidth
                id="password"
                name="password"
                type={values.showPassword ? "text" : "password"}
                value={password}
                onChange={onChangePassword}
                validations={[required]}
                autoComplete="current-password"
                label="Contraseña"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toque para ver si contraseña"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControlLabel
              value="recordar"
              control={<Checkbox color="primary" />}
              label="Recordar Contraseña"
              labelPlacement="end"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.submit}
            >
              -INICIAR SESIÓN-
            </Button>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
          <p className={classes.registrarse}>
            ¿No tienes una cuenta?
            <Link to="/registro/ciudadano" className={classes.txtlink}>
              {" "}
              Registrarse aquí
            </Link>
          </p>
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(Login);
