import React from 'react';
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import DataTable from "../../toolkit/DataTable/DataTable";
import { withStyles } from "@material-ui/core/styles";
import axios from "../../utils/axios";
import createParams from "../../utils/tables-params";
import snackbarStore from "../../stores/snackbar-store";
import PropTypes from 'prop-types';
import { observer, inject } from "mobx-react";
import config from "./DatasetsConfig";
import queryString from 'query-string';

const styles = theme => ({
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    button: {
        margin: 0,
        textDecoration: 'none',
        marginLeft: theme.spacing(1)
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    tableCellAction: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    tableCell: {

    },
    tableCellButton: {

    }
});

const Datos = props => {
    const { classes } = props;
    const configuraciones = config({classes});
    const dataset = props.match.params.dataset;
    const datosConfig = configuraciones[dataset].datosConfig;
    const datosSettings = configuraciones[dataset].datosSettings;

    let tipo = queryString.parse(props.location.search).tipo;
    let ruc = queryString.parse(props.location.search).ruc;

    let defaultFilters = [];
    if(tipo){
        defaultFilters = [{key: 'tiporegistro.id', value: parseInt(tipo, 10), condition: 'equals', type: 'selection'}];
    }
    const getSettings = () => {
        return datosSettings;
    }

    const onReloadTable = () => {
        let settings = getSettings();
        if(ruc){
            props.datos.list.queryData.addQuery([{key: 'empresa.ruc', value: ruc, condition: 'equals', type: 'string'}]);
            ruc = undefined;
        }
        if(tipo){
            props.datos.list.queryData.addQuery([{key: 'tiporegistro.id', value: parseInt(tipo, 10), condition: 'equals', type: 'selection'}]);
            tipo = undefined;
        }
        let params = createParams({ ...props.datos.list, settings });
        //const documentId = props.match.params.documentId;

        if(params.filter){
            params.filter = JSON.stringify(transformRucFilter(JSON.parse(params.filter)));
        }

        axios
            .get(datosConfig.servicio, {
                data: {},
                params
            })
            .then(resp =>
                props.datos.list.dataLoaded(resp.data.data, {
                    currentPage: resp.data.meta.currentPage - 1,
                    pageSize: resp.data.meta.pageSize,
                    itemsCount: resp.data.meta.total
                })
            )
            .catch(() => {
                snackbarStore.openSnackbar("Error al obtener los datos", "error");
            });
    }

    /**
     * Reemplaza el - en el filtro de ruc
     * @param {*} filter
     */
    const transformRucFilter = (filter) => {
        if(filter.path === 'empresa.ruc'){
            filter.conditions = filter.conditions.map(item => ({...item, comparingObject: item.comparingObject.replace('-', '')} ));
            return filter;
        } else {
            filter.booleanJoins.forEach(item => {
                item.filter = transformRucFilter(item.filter);
            });
            return filter;
        }
    }

    const filterExists = (filters, match) => {
        let exists = false;
        filters.forEach(item => {
            if(item.key === match){
                exists = true;
            }
        });
        return exists;
    }

    const onDataDownload = () => {
        let settings = getSettings();
        let params = createParams({ ...props.datos.list, settings });
        //TODO sobreecribir pagesize y demas para que traiga todo
        params.page = "all";
        params.pageSize = props.datos.list.pagingData.itemsCount;
        //params.pageSize= 10;
        if(params.filter){
            params.filter = JSON.stringify(transformRucFilter(JSON.parse(params.filter)));
        }
        const filters = props.datos.list.queryData.querys;
        const promise = axios
            .get(datosConfig.servicio, {
                data: {},
                params
            });

        if(filterExists(filters, 'empresa.ruc')){
            return promise;
        } else if(filterExists(filters, 'empresa.razonsocial')){
            return promise;
        } else if(!filters || filters.length < 2){
            snackbarStore.openSnackbar("Debe aplicar al menos dos filtros", "warning");
            return null;
        } else {
            return promise;
        }
    };

    return (
        <React.Fragment>
          <div className={classes.layout}>
            <ListDataProvider
                listStore={props.datos.list}
                render={listProps => (
                    <DataTable
                        title={datosConfig.titulo}
                        settings={getSettings()}
                        onReloadTable={() => onReloadTable()}
                        onDataDownload={() => onDataDownload()}
                        defaultFilters={defaultFilters}
                        fileName={datosConfig.servicio}
                        {...listProps}
                    />
                )}
            />
          </div>
        </React.Fragment>
    );
}

Datos.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(inject("datos")(observer(Datos)));