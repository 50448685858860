import { createContext, useContext, useState } from "react";

/*
interface QueryItem {
  value: any; // Valor a filtrar
  key: string; // nombre de columna o path que se desea filtrar
  condition: FilterConditions; // -> ver el archivo '/utils/filter'
  type: FilterTypes; // -> ver el archivo '/utils/filter'
}
*/

export const FilterContext = createContext({
  setFilters: () => {},
  filters: [], //: QueryItem[],
});

export const useFilterContext = () => useContext(FilterContext);

const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState([]);
  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
