import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

export default function MenuIconButton({ onClick, ...props }) {
  return (
    <IconButton
      size="medium"
      edge="start"
      aria-label="menu"
      sx={{ mr: 2 }}
      onClick={onClick}
      {...props}
    >
      <MenuIcon />
    </IconButton>
  );
}
