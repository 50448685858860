import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useFormik } from "formik";
import { inject, observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import OrganizationService from "../../services/organizacionservice";
import DataTable from "../../toolkit/DataTable/DataTable";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import createParams from "../../utils/tables-params";
import FormDialog from "../dialogs/FormDialog";
import OrganizacionForm from "../organizacion/OrganizacionForm";

const denormalize = (data) => {
  const target = {
    id: data.id || "",
    descripcion: data.descripcion || "",
    abreviatura: data.abreviatura || "",
    tipo_organizacion: data.tipo_organizacion || "",
    estado: "Activo",
  };

  return target;
};

const DatasetOrganizaciones = (props) => {
  const { classes } = props;
  const abortController = new AbortController();
  const [open, setOpen] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      id: "",
      descripcion: "",
      abreviatura: "",
      tipo_organizacion: "Privada",
      estado: "Activo",
    },
    validationSchema: Yup.object({
      id: Yup.number().nullable(),
      descripcion: Yup.string().required("La descripción es requerida"),
      abreviatura: Yup.string().required("La abreviatura es requerida"),
      tipo_organizacion: Yup.string().required(
        "El tipo de organización es requerida"
      ),
    }),
    onSubmit: (formdata) => {
      const payload = denormalize(formdata);
      const fetchFc = formdata.id
        ? OrganizationService.update({
            id: formdata.id,
            payload,
            signal: abortController.signal,
          })
        : OrganizationService.save({
            payload,
            signal: abortController.signal,
          });
      fetchFc.then(() => {
        onReloadTable();
        setOpen(false);
      });
    },
  });

  const edit = (item) => {
    formik.setValues({
      id: item.id,
      descripcion: item.descripcion,
      abreviatura: item.abreviatura,
      tipo_organizacion: item.tipo_organizacion,
      estado: "Activo",
    });
    setOpen(true);
  };

  const crear = () => {
    formik.setValues({
      id: null,
      descripcion: "",
      abreviatura: "",
      tipo_organizacion: "Privada",
      estado: "Activo",
    });
    setOpen(true);
  };

  const Bread = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
        <Link className={classes.txtlink} to="/">
          Consulta Pública de Normativas
        </Link>
        <Link className={classes.txtlink} to="/organizaciones">
          <strong>Instituciones (Organizaciones)</strong>
        </Link>
      </Breadcrumbs>
    );
  };

  const getSettings = () => {
    return {
      descripcion: {
        label: "DESCRIPCIÓN",
        sortable: true,
        filterable: true,
        editable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContentDocTitle}>
              {item ? item.descripcion : ""}
            </p>
          </TableCell>
        ),
      },
      abreviatura: {
        label: "ABREVIATURA",
        sortable: true,
        filterable: true,
        editable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContentDocTitle}>
              {item ? item.abreviatura : ""}
            </p>
          </TableCell>
        ),
      },
      tipo_organizacion: {
        label: "TIPO ORGANIZACIóN",
        sortable: true,
        filterable: true,
        editable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContentDocTitle}>
              {item ? item.tipo_organizacion : ""}
            </p>
          </TableCell>
        ),
      },
      estado: {
        label: "ESTADO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContentDocTitle}>
              {item ? item.estado : ""}
            </p>
          </TableCell>
        ),
      },
      actions: {
        label: "ACCIONES",
        sortable: false,
        filterable: false,
        cellRendering: (item) => (
          <TableCell className={classes.tableCellAction}>
            <div style={{ display: "flex", padding: 0 }}>
              <div className={classes.button} onClick={() => edit(item)}>
                <LightTooltip title="Editar Institución" arrow>
                  <EditIcon
                    style={{
                      color: "#00529c",
                      maxWidth: "35px",
                      maxHeight: "35px",
                    }}
                  />
                </LightTooltip>
              </div>
              <div
                className={classes.button}
                onClick={() => {
                  OrganizationService.changeStatus(
                    item.id,
                    abortController.signal
                  ).then(() => {
                    onReloadTable();
                  });
                }}
              >
                <LightTooltip
                  title={
                    item.estado !== "Inactivo"
                      ? "Desactivar Institución"
                      : "Activar Institución"
                  }
                  arrow
                >
                  {item.estado !== "Inactivo" ? (
                    <VisibilityIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "20px",
                        maxHeight: "20px",
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "20px",
                        maxHeight: "20px",
                      }}
                    />
                  )}
                </LightTooltip>
              </div>
            </div>
          </TableCell>
        ),
      },
    };
  };

  const onReloadTable = () => {
    props.dataset.list.setLoading();
    let settings = getSettings();
    let params = createParams({ ...props.dataset.list, settings });

    OrganizationService.getList({
      params,
      signal: abortController.signal,
    }).then((resp) => {
      props.dataset.list.resetListData();
      props.dataset.list.dataLoaded(resp.data.data, {
        currentPage: resp.data.meta.currentPage - 1,
        pageSize: resp.data.meta.pageSize,
        itemsCount: resp.data.meta.total,
      });
    });
  };

  React.useEffect(() => {
    return () => abortController.abort();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <ListDataProvider
          listStore={props.dataset.list}
          render={(listProps) => (
            <DataTable
              //title="Conjuntos de Datos"
              Breads={Bread()}
              settings={getSettings()}
              onReloadTable={onReloadTable}
              {...listProps}
            />
          )}
        />

        <div className={classes.addButtonContainer} onClick={crear}>
          <AddCircleOutlineIcon className={classes.addButton} />
        </div>
      </div>

      <FormDialog
        formik={formik}
        open={open}
        onClose={() => setOpen(false)}
        title={(formik.values.id ? "Editar " : "Crear ") + "Institución"}
      >
        <OrganizacionForm formik={formik} />
      </FormDialog>
    </React.Fragment>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D6EBFF",
    color: "#00529c",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 250,
    textAlign: "center",
  },
}))(Tooltip);

const styles = (theme) => ({
  tableContentDocTitle: {
    fontSize: "14px",
    width: "auto",
    //padding: "0px",
    margin: "10px",
  },
  tableMail: {
    fontSize: "14px",
    width: "120px",
    //padding: "0px",
    margin: "10px",
  },
  tableContent: {
    fontSize: "14px",
    width: "60px",
    //padding: "0px",
    margin: "5px",
  },
  tableCell: {
    fontSize: "14px",
    //width: "auto",
    //padding: "0px",
    margin: "10px",
  },
  tableTitle: {
    fontSize: "14px",
    //width: "auto",
    //padding: "0px",
    margin: "10px",
  },
  tableCant: {
    fontSize: "14px",
    textAlign: "center",
    width: "30px",
    //padding: "0px",
    margin: "10px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    position: "relative",
    width: "auto",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
    //width: "20px",
  },
  addButtonContainer: {
    textDecoration: "none",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  addButton: {
    fontSize: "70px",
    width: "auto",
    color: "#00529c",
    zIndex: "99999",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
});

export default withStyles(styles)(
  inject("dataset")(observer(DatasetOrganizaciones))
);
