import { observable, decorate } from "mobx";
import ListData from "../toolkit/mobx-connectors/observables/list-data";

class DescargasStore {
  list = new ListData();
}

decorate(DescargasStore, {
  list: observable
});

const descargasStore = new DescargasStore();
export default descargasStore;
