import { observable, decorate } from "mobx";
import ListData from "../toolkit/mobx-connectors/observables/list-data";

class DatasetStore {
  list = new ListData();
}

decorate(DatasetStore, {
  list: observable
});

const datasetVersionesStore = new DatasetStore();
export default datasetVersionesStore;
