import { memo, useCallback, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import {
  ORGANIZATION_QUERY_KEY,
  TIPO_ACTIVIDAD_QUERY_KEY,
  TIPO_DOCUMENTO_QUERY_KEY,
} from "../../../constants";
import { useFilterContext } from "../../../context/FilterContext";
import OrganizationService from "../../../services/organizacionservice";
import TipoActividadService from "../../../services/tipoactividadsercice";
import TipoDocumentoService from "../../../services/tipodocumentoservice";
import {
  FilterConditions as C,
  FilterTypes as T,
  getCheckValueInFilters,
  getFiltro,
} from "../../../utils/filter";
import CheckboxMenuItem from "../../ui/CheckboxMenuItem";
import CollapsibleMenuItem from "../../ui/CollapsibleMenuItem";

function FilterMenuList({ isNormativaVigentes = false }) {
  const location = useLocation();
  const { filters, setFilters } = useFilterContext();
  const queryClient = useQueryClient();
  const { data: orgs } = useQuery(ORGANIZATION_QUERY_KEY, () =>
    OrganizationService.getListForFilters(isNormativaVigentes)
  );
  const { data } = useQuery(
    TIPO_DOCUMENTO_QUERY_KEY,
    TipoDocumentoService.getAll
  );
  const { data: typs } = useQuery(
    TIPO_ACTIVIDAD_QUERY_KEY,
    TipoActividadService.getAllBasic
  );
  const organizations = orgs?.data ?? [];
  const documentTypes = data?.data?.data ?? [];
  const activityTypes = typs?.data ?? [];
  useEffect(
    () => () => {
      queryClient.cancelQueries([ORGANIZATION_QUERY_KEY]);
      queryClient.cancelQueries([TIPO_DOCUMENTO_QUERY_KEY]);
      queryClient.cancelQueries([TIPO_ACTIVIDAD_QUERY_KEY]);
    },
    []
  );
  const setFilter = useCallback(
    ({ key, value, condition = C.IN, type = T.STRING, isChecked }) => {
      setFilters(
        getFiltro({
          filters,
          key,
          value,
          condition,
          type,
          addCondition: isChecked,
        })
      );
    },
    [filters]
  );
  return (
    <>
      {organizations.length !== 0 && (
        <CollapsibleMenuItem text="Organismos y Entidades del Estado" isNested>
          {organizations?.map((o) => (
            <CheckboxMenuItem
              key={o.id}
              label={o.abreviatura}
              checked={getCheckValueInFilters(
                filters,
                "usuario.organizacion.id",
                o.id,
                C.IN
              )}
              onChange={(e) =>
                setFilter({
                  key: "usuario.organizacion.id",
                  value: o.id,
                  isChecked: e.target.checked,
                })
              }
            />
          ))}
        </CollapsibleMenuItem>
      )}
      {location.pathname !== "/vigentes" && (
        <CollapsibleMenuItem text="Estado" isNested>
          {["En Curso", "Finiquitado"].map((label, index) => (
            <CheckboxMenuItem
              key={index}
              label={label}
              checked={getCheckValueInFilters(filters, "estado", label, C.IN)}
              onChange={(e) =>
                setFilter({
                  key: "estado",
                  value: label,
                  isChecked: e.target.checked,
                })
              }
            />
          ))}
        </CollapsibleMenuItem>
      )}
      <CollapsibleMenuItem text="Tipo de Documento" isNested>
        {documentTypes?.map((o) => (
          <CheckboxMenuItem
            key={o.id}
            label={o.descripcion}
            checked={getCheckValueInFilters(
              filters,
              "tipo_documento.descripcion",
              o.descripcion,
              C.IN
            )}
            onChange={(e) =>
              setFilter({
                key: "tipo_documento.descripcion",
                value: o.descripcion,
                isChecked: e.target.checked,
              })
            }
          />
        ))}
      </CollapsibleMenuItem>
      <CollapsibleMenuItem text="Tipo de Actividad" isNested>
        {activityTypes?.map((o) => (
          <CheckboxMenuItem
            key={o.id}
            label={o.descripcion}
            checked={getCheckValueInFilters(
              filters,
              "tipoactividadad.tipo_actividad.descripcion",
              o.descripcion,
              C.IN
            )}
            onChange={(e) =>
              setFilter({
                key: "tipoactividadad.tipo_actividad.descripcion",
                value: o.descripcion,
                isChecked: e.target.checked,
              })
            }
          />
        ))}
      </CollapsibleMenuItem>
    </>
  );
}

const DocumentoFilterMenuList = memo(FilterMenuList);

export default DocumentoFilterMenuList;
