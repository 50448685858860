import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import FileField from "../../material/FileField";

function ResponseDialog({
  classes,
  loading,
  open,
  onClose,
  onSend,
  onFileChange,
  onResponseChange,
}) {
  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Responder a comentario</DialogTitle>
      <DialogContent>
        <p style={{ fontWeight: "bold", color: "red" }}>
          Luego de responder debe Aprobar o Rechazar el comentario
        </p>
        <TextField
          autoFocus
          multiline
          minRows={6}
          variant="outlined"
          margin="dense"
          id="name"
          label="Escribir respuesta..."
          type="string"
          fullWidth
          onChange={onResponseChange}
        />
        <FileField onFileChange={onFileChange} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          size="small"
          color="primary"
          className={classes.button}
        >
          CANCELAR
        </Button>
        <Button
          onClick={onSend}
          variant="contained"
          size="small"
          color="primary"
          className={classes.button}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress color="secondary" size="1.8em" />
          ) : (
            "ENVIAR"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
    //width: "20px",
  },
});

export default withStyles(styles)(ResponseDialog);
