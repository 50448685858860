import datasetStore from "./dataset-store";
import datasetVigentesStore from "./datasetVigente-store";
import datasetVersionesStore from "./datasetVersiones-store";
import rolesStore from "./auth-store";
import authStore from "./auth-store";
import diccionarioStore from "./diccionario-store";
import registroStore from "./registro-store";
import datosStore from "./datos-store";
import descargasStore from "./descargas-store";
import favoritosStore from "./favoritos-store";
import actividadStore from "./actividad-store";
import rubroStore from "./rubro-store";
import comentariosStore from "./comentarios-store";
import archivosStore from "./archivos-store";

export default {
  auth: authStore,
  dataset: datasetStore,
  datasetVigentes: datasetVigentesStore,
  datasetVersiones: datasetVersionesStore,
  diccionario: diccionarioStore,
  datos: datosStore,
  descargas: descargasStore,
  favoritos: favoritosStore,
  registros: registroStore,
  actividades: actividadStore,
  rubros: rubroStore,
  roles: rolesStore,
  comentarios: comentariosStore,
  archivos: archivosStore,
};
