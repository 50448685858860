import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import snackbarStore from "../../stores/snackbar-store";
import DataTable3 from "../../toolkit/DataTable/DataTable3";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import axios from "../../utils/axios";
import file_path from "../../utils/files_path";
import createParams from "../../utils/tables-params";

const Dataset = (props) => {
  const { classes } = props;
  const idDocumento = props.idDocumento;
  props.datasetVersiones.list.queryData.setQuerys([
    {
      key: "documento",
      value: parseFloat(idDocumento),
      condition: "equals",
      type: "string",
    },
  ]);
  const orderData = { orders: { fecha_creacion: "desc" } };

  const getSettings = () => {
    return {
      "documento.titulo": {
        label: "VERSIONES",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item ? item.titulo : ""}</p>
          </TableCell>
        ),
      },
      "usuario.organizacion": {
        label: "VERSION",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item.usuario ? item.usuario.organizacion?.abreviatura : ""}
          </TableCell>
        ),
      },
      fecha_creacion: {
        label: "FECHA PUBLICACION",
        sortable: true,
        filterable: true,
        type: "date",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>
              {item ? moment(item.fecha_creacion).format("DD-MM-YYYY") : ""}
            </p>
          </TableCell>
        ),
      },
      actions: {
        label: "ACCIONES",
        sortable: false,
        filterable: false,
        cellRendering: (item) => (
          <TableCell className={classes.tableCellAction}>
            <div style={{ display: "flex", padding: 0 }}>
              <Tooltip title="Descargar" arrow>
                <a
                  href={file_path + item.ubicacion}
                  download={item.ubicacion}
                  target="_blank"
                >
                  <GetAppIcon style={{ color: "#00529c" }} />
                </a>
              </Tooltip>
            </div>
          </TableCell>
        ),
      },
    };
  };
  /*prueba*/
  /*<Link  to={`/${item.id}/datos`} className={classes.button}>*/
  /* <Route exact path={`${props.match.url}/`} component={Dataset} props={props}/>*/

  const onReloadTable = () => {
    let settings = getSettings();
    let params = createParams({
      ...props.datasetVersiones.list,
      settings,
      orderData,
    });

    axios
      .get("version/", {
        data: {},
        params,
      })
      .then((resp) =>
        props.datasetVersiones.list.dataLoaded(resp.data.data, {
          currentPage: resp.data.meta.currentPage - 1,
          pageSize: resp.data.meta.pageSize,
          itemsCount: resp.data.meta.total,
        })
      )
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado documentos",
          "error"
        );
      });
  };

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <ListDataProvider
          listStore={props.datasetVersiones.list}
          render={(listProps) => (
            <DataTable3
              //title="Conjuntos de Datos"
              settings={getSettings()}
              onReloadTable={() => onReloadTable()}
              {...listProps}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};

const styles = (theme) => ({
  tableContent: {
    fontSize: "11px",
    width: "400px",
    padding: "0px",
    margin: "0px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    //display:"flex",
    width: "100%",
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    /*[theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 1100,
            marginLeft: "auto",
            marginRight: "auto",
        },*/
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});

export default withStyles(styles)(
  inject("datasetVersiones")(observer(Dataset))
);
