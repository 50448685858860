import { SvgIcon } from "@material-ui/core";
import React from "react";

function FilterIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#593408"
      >
        <g>
          <path d="M0,0h24 M24,24H0" fill="none" />
          <path d="M3,4c2.01,2.59,7,9,7,9v7h4v-7c0,0,4.98-6.41,7-9H3z" />
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default FilterIcon;
