import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useFilterContext } from "../../context/FilterContext";
import DocumentoService from "../../services/documento-service";
import snackbarStore from "../../stores/snackbar-store";
import DataTable from "../../toolkit/DataTable/DataTable";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import axios from "../../utils/axios";
import { FilterConditions as C, FilterUnions as U } from "../../utils/filter";
import createParams from "../../utils/tables-params";
import Button from "../documento/DocumentoActionButton";
import DocumentoFiltro from "../documento/DocumentoFiltro";
import useFilterForm from "../documento/useFilterForm";
import DownloadDialog from "./DownloadDialog";
import LightTooltip from "./LightTooltip";

const getSettings = (classes, setPost, setAbrirDialogoDescarga) => {
  return {
    download: {
      label: "",
      sortable: false,
      filterable: false,
      cellRendering: (item) => (
        <TableCell className={classes.tableCellDownloadButton}>
          <LightTooltip title="Descargar Documento" arrow>
            <Button
              startIcon={<GetAppIcon />}
              className={classes.red}
              onClick={() => {
                DocumentoService.getFileByDocumentoId(item.id).then((resp) => {
                  setPost(resp.data);
                  setAbrirDialogoDescarga(true);
                });
              }}
            >
              Descargar
            </Button>
          </LightTooltip>
        </TableCell>
      ),
    },
    titulo: {
      label: "TÍTULO",
      sortable: true,
      filterable: true,
      type: "string",
      cellRendering: (item) => (
        <TableCell className={classes.tableCell}>
          <p className={classes.tableContent}>{item.titulo}</p>
        </TableCell>
      ),
    },
    "tipo_documento.descripcion": {
      label: "TIPO DE DOCUMENTO",
      sortable: true,
      filterable: true,
      type: "string",
      cellRendering: (item) => (
        <TableCell className={classes.tableCell}>
          {item.tipoDocumento}
        </TableCell>
      ),
    },
    "tipoactividadad.tipo_actividad.descripcion": {
      label: "TIPO DE ACTIVIDAD",
      sortable: true,
      filterable: true,
      type: "string",
      cellRendering: (item) => (
        <TableCell className={classes.tableCell}>
          <p className={classes.tableContent}>{item.tipoActividades}</p>
        </TableCell>
      ),
    },
    fecha_publicacion: {
      label: "FECHA PUBLICACIÓN",
      sortable: true,
      filterable: false,
      type: "date",
      cellRendering: (item) => (
        <TableCell
          className={classes.tableCell}
          style={{ whiteSpace: "nowrap" }}
        >
          {moment(item.fecha_publicacion).format("DD-MM-YYYY")}
        </TableCell>
      ),
    },
    "usuario.organizacion.abreviatura": {
      label: "ORGANIZACION",
      sortable: true,
      filterable: false,
      type: "string",
      cellRendering: (item) => (
        <TableCell className={classes.tableCell}>{item.organizacion}</TableCell>
      ),
    },
    "organismoInternacionalEmisor.descripcion": {
      label: "ORGANISMO INTERNACIONAL",
      sortable: true,
      filterable: true,
      type: "string",
      cellRendering: (item) => (
        <TableCell className={classes.tableCell}>
          <p className={classes.tableContent}>{item.organismoInternacional}</p>
        </TableCell>
      ),
    },
    asiento: {
      label: "ASIENTO",
      sortable: true,
      filterable: false,
      type: "string",
      cellRendering: (item) => (
        <TableCell className={classes.tableCell}>
          <p className={classes.tableContent}>{item.asiento}</p>
        </TableCell>
      ),
    },
    "documentoDescriptorList.descriptor.descripcion": {
      label: "DESCRIPTORES",
      sortable: true,
      filterable: false,
      type: "string",
      cellRendering: (item) => (
        <TableCell className={classes.tableCell}>
          <p className={classes.tableContent}>{item.descriptores}</p>
        </TableCell>
      ),
    },
  };
};

const DatasetVigente = (props) => {
  const {
    classes,
    datasetVigentes: { list },
  } = props;
  const { filters, setFilters } = useFilterContext();
  const [abrirDialogoDescarga, setAbrirDialogoDescarga] = React.useState(false);
  const [post, setPost] = React.useState([]);
  const settings = getSettings(classes, setPost, setAbrirDialogoDescarga);
  const abortController = new AbortController();
  const formik = useFilterForm({ filters, setFilters });

  const Bread = () => {
    return (
      <div className={classes.paperBreadContainer}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
          <strong>Normas Vigentes</strong>
        </Breadcrumbs>
        <div style={{ flex: 1 }}></div>
        <DocumentoFiltro formik={formik} isNormativaVigentes />
      </div>
    );
  };

  const onReloadTable = () => {
    list.setLoading();
    const settings = getSettings();

    const uniqueFilters = Array.from(new Set(filters));
    list.queryData.setQuerys([
      ...uniqueFilters,
      {
        key: "estado",
        value: "Vigente",
        condition: C.EQUALS,
        type: "string",
      },
    ]);

    const params = createParams({
      ...list,
      settings,
      union: U.AND,
    });

    axios
      .get("documento/", { data: {}, params, signal: abortController.signal })
      .then((resp) => {
        list.resetListData();
        list.dataLoaded(resp.data.data, {
          currentPage: resp.data.meta.currentPage - 1,
          pageSize: resp.data.meta.pageSize,
          itemsCount: resp.data.meta.total,
        });
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado documentos",
          "error"
        );
      });
  };

  React.useEffect(() => {
    if (filters) {
      list.pagingData.changePage(0);
      onReloadTable();
    }
  }, [filters]);

  React.useEffect(() => {
    return () => abortController.abort();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <ListDataProvider
          listStore={list}
          render={(listProps) => (
            <DataTable
              title=""
              settings={settings}
              onReloadTable={onReloadTable}
              Breads={Bread()}
              {...listProps}
            />
          )}
        />
      </div>
      <DownloadDialog
        open={abrirDialogoDescarga}
        post={post}
        onClose={setAbrirDialogoDescarga}
      />
    </React.Fragment>
  );
};

const styles = (theme) => ({
  tableContent: {
    fontSize: "11px",
    width: "200px",
    padding: "0px",
    margin: "0px",
  },
  tableCellDownloadButton: {
    margin: 0,
    padding: 0,
    paddingLeft: "10px",
  },
  tableCellDocTitle: {
    paddingLeft: "10px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    width: "auto",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  downloadButton: {
    margin: 0,
    padding: 0,
    height: "20px",
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  paperBread: {
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  paperFilter: {
    display: "inline-block",
    marginTop: "0px",
    marginLeft: "auto",
    marginRight: "0px",
    width: "200px",
    borderRadius: "20px",
  },
  paperBreadContainer: {
    display: "flex",
  },
  inputFiltro: {
    fontSize: "16px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
  },
  red: {
    backgroundColor: "#ea2428",
    color: "white",
    "&:hover": {
      backgroundColor: "rgb(234, 36, 40, 0.7)",
    },
    "&.MuiIconButton-root": {
      backgroundColor: "transparent",
      color: "#ea2428",
    },
  },
});

export default withStyles(styles)(
  inject("datasetVigentes")(observer(DatasetVigente))
);
