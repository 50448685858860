import ReactEcharts from "echarts-for-react";
import React from "react";

function StatsBySector({ stats }) {
  const sectorData = [
    { value: stats?.usersBySector["Público"] ?? 0, name: "Sector Público" },
    { value: stats?.usersBySector["Privado"] ?? 0, name: "Sector Privado" },
  ];

  const getOption = () => ({
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "horizontal",
      left: "center",
    },
    series: [
      {
        type: "pie",
        radius: "50%",
        data: sectorData,
        color: ["#177ABB", "#FB9D3B"],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  });

  return (
    <ReactEcharts
      option={getOption()}
      style={{ height: "300px", width: "100%" }}
    />
  );
}

export default StatsBySector;
