import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AuthService from "../services/auth-service";
import snackbarStore from "../stores/snackbar-store";

function IELogin() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    
    if (!code || !state) {
      history.push('/login');
    } else {
        AuthService.iELogin(code, state).then(response => {
          console.log(response)
          if (!JSON.parse(localStorage.getItem("userData"))) {
            snackbarStore.openSnackbar("Credenciales inválidas", "error");
            history.push('/login');
          } else {
            history.push("/");
          }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        snackbarStore.openSnackbar(resMessage, "error");
        history.push('/login');
      });
    }
  }, [location.search, history]);

  return null;
}

export default IELogin;
