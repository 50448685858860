import axios from "../utils/axios";
import snackbarStore from "../stores/snackbar-store";

async function getFileByDocumentoId(documentoId) {
  try {
    return await axios.get(`versiones/documento/${documentoId}`, {
      data: {},
    });
  } catch {
    snackbarStore.openSnackbar(
      "Error al obtener el archivo del servidor",
      "error"
    );
  }
}

async function updateStatus(id, signal) {
  return axios
    .put(`documento/estado/${id}`, {}, { signal, "Content-Type": "application/json" })
    .then((resp) => {
      snackbarStore.openSnackbar("Cambio de estado exitoso", "success");
      return resp;
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al obtener los datos del servidor",
        "error"
      );
    });
}

const DocumentoService = {
  getFileByDocumentoId,
  updateStatus,
};

export default DocumentoService;
