import _ from "../@lodash/";

const GENERAL_FILTER = "_GENERAL_FILTER_FIELD_";

function getValueByCondition(
  values,
  value,
  condition = FilterConditions.EQUALS
) {
  const existsValue =
    condition === FilterConditions.IN
      ? values.some((v) => v === value)
      : values === value;
  return existsValue;
}

export function getCheckValueInFilters(
  filters,
  key,
  value,
  condition = FilterConditions.EQUALS
) {
  return filters.some(
    (f) => f.key === key && getValueByCondition(f.value, value, condition)
  );
}

export function getFiltro({
  filters,
  key,
  value,
  addCondition,
  condition = FilterConditions.EQUALS,
  type = FilterTypes.STRING,
}) {
  if (condition === FilterConditions.IN) {
    const newFilters = filters.filter((f) => f.key !== key);
    const oldValue = filters
      .filter((f) => f.key === key)
      .flatMap((f) => f.value);
    const newValue = addCondition
      ? [...oldValue, value]
      : oldValue.filter((v) => v !== value);
    return newValue.length
      ? [...newFilters, { key, value: newValue, condition, type }]
      : newFilters;
  } else {
    if (addCondition) {
      const filtersAlreadyExists = filters.some(
        (f) =>
          key === f.key &&
          value === f.value &&
          condition === f.condition &&
          type === f.type
      );
      if (!filtersAlreadyExists) {
        return [...filters, { key, value, condition, type }];
      }
      return [...filters];
    } else {
      return filters.filter(
        (f) =>
          !(
            f.key === key &&
            f.value === value &&
            f.condition === condition &&
            f.type === type
          )
      );
    }
  }
}

export const FilterUnions = Object.freeze({
  AND: "and",
  OR: "or",
});

export const FilterTypes = Object.freeze({
  STRING: "string",
  NUMERIC: "numeric",
  INTEGER: "integer",
  DATE: "date",
  BOOLEAN: "boolean",
  SELECTION: "selection",
});

export const FilterConditions = Object.freeze({
  EQUALS: "equals",
  NOT_EQUALS: "notEquals",
  NULL: "null",
  NOT_NULL: "notNull",
  LIKE: "like",
  NOT_LIKE: "notLike",
  GT: "gt",
  GTE: "gte",
  LT: "lt",
  LTE: "lte",
  IN: "in",
});

function joinFilters(builder, filtersParam, joinType) {
  let filters;
  if (!_.isArray(filtersParam)) {
    filters = [filtersParam];
  }

  filters.forEach((filter) => {
    builder.booleanJoins.push({
      joinType,
      filter,
    });
  });
  return filters;
}

function addCondition(builder, condition, other) {
  const cond = { condition };

  if (other || other === false) {
    if (_.isArray(other)) {
      cond.comparingObjects = [];
      other.forEach((value) => {
        const conditionValue = { value };
        if (_.isString(value)) {
          conditionValue.type = "string";
        } else if (_.isNumber(value)) {
          conditionValue.type = "integer";
        }
        cond.comparingObjects.push(conditionValue);
      });
    } else {
      cond.comparingObject = other;

      if (_.isBoolean(other)) {
        cond.type = "boolean";
      } else if (_.isString(other)) {
        cond.type = "string";
      } else if (_.isDate(other)) {
        cond.type = "date";
      } else if (_.isNumber(other)) {
        cond.type = "integer";
      }
    }
  }
  builder.conditions.push(cond);
}

class Filter {
  constructor(path) {
    this.path = path;
    this.conditions = [];
    this.booleanJoins = [];
  }

  or(filters) {
    joinFilters(this, filters, "or");
    return this;
  }

  and(filters) {
    joinFilters(this, filters, "and");
    return this;
  }

  eq(other) {
    addCondition(this, FilterConditions.EQUALS, other);
    return this;
  }

  notEq(other) {
    addCondition(this, FilterConditions.NOT_EQUALS, other);
    return this;
  }

  isNull() {
    addCondition(this, FilterConditions.NULL);
    return this;
  }

  notNull() {
    addCondition(this, FilterConditions.NOT_NULL);
    return this;
  }

  like(other) {
    addCondition(this, FilterConditions.LIKE, other);
    return this;
  }

  notLike(other) {
    addCondition(this, FilterConditions.NOT_LIKE, other);
    return this;
  }

  gt(other) {
    addCondition(this, FilterConditions.GT, other);
    return this;
  }

  gte(other) {
    addCondition(this, FilterConditions.GTE, other);
    return this;
  }

  lt(other) {
    addCondition(this, FilterConditions.LT, other);
    return this;
  }

  lte(other) {
    addCondition(this, FilterConditions.LTE, other);
    return this;
  }

  in(other) {
    addCondition(this, FilterConditions.IN, other);
    return this;
  }

  fromCondition(condition, value) {
    switch (condition) {
      case FilterConditions.EQUALS:
        this.eq(value);
        break;
      case FilterConditions.NOT_EQUALS:
        this.notEq(value);
        break;
      case FilterConditions.LIKE:
        this.like(value);
        break;
      case FilterConditions.NOT_LIKE:
        this.notLike(value);
        break;
      case FilterConditions.NULL:
        this.isNull();
        break;
      case FilterConditions.NOT_NULL:
        this.notNull();
        break;
      case FilterConditions.GT:
        this.gt(value);
        break;
      case FilterConditions.GTE:
        this.gte(value);
        break;
      case FilterConditions.LT:
        this.lt(value);
        break;
      case FilterConditions.LTE:
        this.lte(value);
        break;
      case FilterConditions.IN:
        this.in(value);
        break;
      default:
        console.error("Condition Type not found");
    }
    return this;
  }

  static path(filterPath) {
    return new Filter(filterPath);
  }
}

const createGeneralFilter = (attributes, value) => {
  let filter;
  attributes.forEach((attribute) => {
    if (!filter) {
      filter = Filter.path(attribute).like(value);
    } else {
      filter.or(Filter.path(attribute).like(value));
    }
  });
  return filter;
};

const dateFilter = (filters, filter, value) => {
  value.setHours(0, 0, 0);
  value = new Date(value.getTime() - value.getTimezoneOffset() * 60000);

  if (filter.condition === FilterConditions.EQUALS) {
    if (!filters) {
      const antes = new Date(value);
      antes.setDate(antes.getDate() - 1);
      filters = Filter.path(filter.key).fromCondition(
        FilterConditions.GT,
        antes
      );
      const despues = new Date(value);
      despues.setDate(despues.getDate() + 1);
      filters = filters.and(
        Filter.path(filter.key).fromCondition(FilterConditions.LT, despues)
      );
    } else {
      const antes = new Date(value);
      antes.setDate(antes.getDate() - 1);
      filters = filters.and(
        Filter.path(filter.key).fromCondition(FilterConditions.GT, antes)
      );
      const despues = new Date(value);
      despues.setDate(despues.getDate() + 1);
      filters = filters.and(
        Filter.path(filter.key).fromCondition(FilterConditions.LT, despues)
      );
    }
  } else if (!filters) {
    filters = Filter.path(filter.key).fromCondition(filter.condition, value);
  } else {
    filters = filters.and(
      Filter.path(filter.key).fromCondition(filter.condition, value)
    );
  }
  return filters;
};

const U = FilterUnions;

export const createFilter = (rawFilters, attributes, union = U.AND) => {
  let filters;
  rawFilters.forEach((filter) => {
    let value = filter.value;
    if (filter.type === "boolean") {
      value = filter.value === "true" ? true : false;
    }
    if (filter.key === GENERAL_FILTER) {
      if (!filters) {
        filters = createGeneralFilter(attributes, value);
      } else {
        filters = filters.and(createGeneralFilter(attributes, value));
      }
    } else if (filter.type === "date") {
      filters = dateFilter(filters, filter, value);
    } else if (!filters) {
      filters = Filter.path(filter.key).fromCondition(filter.condition, value);
    } else if (union === U.OR) {
      filters = filters.or(
        Filter.path(filter.key).fromCondition(filter.condition, value)
      );
    } else {
      filters = filters.and(
        Filter.path(filter.key).fromCondition(filter.condition, value)
      );
    }
  });
  return filters;
};

export default Filter;
