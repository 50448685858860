import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { FormGroup } from "reactstrap";
import file_path from "../../utils/files_path";

function DownloadVersionDialog({
  children,
  open,
  downloadUrl,
  onClose,
  title,
}) {
  const cerrarDialogoDescarga = () => {
    onClose(false);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={cerrarDialogoDescarga}
      aria-labelledby="form-dialog-title"
    >
      <div align="left">
        <DialogTitle id="form-dialog-title">
          <span style={{ color: "navy" }}>{title}</span>
        </DialogTitle>
      </div>
      <DialogContent>
        <FormGroup>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ width: "100px" }} />
                </TableRow>
                {children}
              </TableBody>
            </Table>
          </TableContainer>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={cerrarDialogoDescarga}
          variant="outlined"
          size="small"
          color="primary"
          className={styles.button}
        >
          ATRAS
        </Button>

        {downloadUrl ? (
          <a
            href={file_path + downloadUrl}
            download={file_path + downloadUrl}
            target="_blank"
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={styles.button}
              //onClick={rechazar}
            >
              DESCARGAR
            </Button>
          </a>
        ) : (
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={styles.button}
            disabled
            //onClick={rechazar}
          >
            DESCARGAR
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
  },
  cell: {
    paddingTop: "1rem",
    marginTop: "1rem",
  },
});

export default withStyles(styles)(DownloadVersionDialog);
