import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  withStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import React from "react";
//import snackbarStore from "../../stores/snackbar-store";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import archivosService from "../../services/archivos-service";
import snackbarStore from "../../stores/snackbar-store";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = (theme) => ({
  layout: {
    width: "auto",
    //display: "flex",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginbotton: theme.spacing(100),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),

    //display: "row",
    //flexDirection: "column",
    //alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      2
    )}px`,
  },
  container: {
    display: "flex",
    direction: "row",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    //padding: "2rem",
    marginTop: theme.spacing(2),
    textDecoration: "none",
    borderRadius: "10px",
  },
  txttitulo: {
    textAlign: "center",
    color: "#0E1820",
    fontSize: "20px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
});

const CrearArchivo = (props) => {
  const { archivoId, classes } = props;
  const history = useHistory();
  const reader = new FileReader();
  const archivoBase64 = React.useRef(null);
  const hiddenFileInput = React.useRef(null);
  const title = archivoId ? "Subir nueva versión de archivo" : "Subir archivo";
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (!fileUploaded) {
      snackbarStore.openSnackbar("Debe elegir un archivo.", "error");
    }
    if (fileUploaded.size > 53477376) {
      snackbarStore.openSnackbar(
        "El tamaño del archivo no debe ser mayor a 50 MB.",
        "error"
      );
    } else {
      formik.setFieldValue("archivo", fileUploaded.name);
      formik.setFieldValue("nombre", fileUploaded.name);
      reader.readAsDataURL(fileUploaded);
      reader.onload = function () {
        let base64 = reader.result;
        archivoBase64.current = base64.split(",");
      };
    }
  };

  const formik = useFormik({
    initialValues: {
      archivo: null,
      descripcion: "",
      nombre: "",
    },
    validationSchema: Yup.object({
      archivo: Yup.string().required(),
    }),
    onSubmit: (formdata) => {
      const base64 = archivoBase64.current;
      const payload = {
        archivo: base64 && base64.length > 0 ? base64[1] : [],
        descripcion: formdata.descripcion,
        nombre: formdata.nombre,
      };
      archivosService
        .createArchivo({ archivoId, payload })
        .then(() => history.push("/archivos"));
    },
  });

  return (
    <main className={classes.layout}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
        <Link to="/" className={classes.txtlink}>
          Consulta Pública de Normativas
        </Link>
        <Link to="#" className={classes.txtlink}>
          Subir y moderar
        </Link>
        <Link to="#" className={classes.txtlink}>
          <strong>Gestionar Archivos</strong>
        </Link>
      </Breadcrumbs>
      <Paper className={classes.paper}>
        <LinearProgress color="primary" hidden={!formik.isSubmitting} />
        <div align="left">
          <h3 style={{ color: "#0E1820" }}>{title}</h3>
        </div>

        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container className={classes.container} spacing={3}>
            <Grid item xs={12}>
              <FormControl margin="dense" fullWidth variant="outlined">
                <InputLabel htmlFor="adjuntar">Adjuntar Archivo</InputLabel>
                <OutlinedInput
                  label="Adjuntar Archivo"
                  autoComplete="off"
                  id="adjuntar"
                  readOnly
                  value={formik.values.archivo}
                  startAdornment={
                    <InputAdornment position="start">
                      <AttachFileIcon onClick={handleClick} />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <input
                accept="application/pdf"
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <span style={{ fontSize: "12px" }}>
                {" "}
                *El Archivo debe estar en formato PDF y no superar los 50MB
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="dense" fullWidth variant="outlined">
                <InputLabel htmlFor="descripcion">Descripción</InputLabel>
                <OutlinedInput
                  label="Descripción"
                  value={formik.values.descripcion}
                  onChange={formik.handleChange}
                  error={formik.errors.descripcion && true}
                  id="descripcion"
                  name="descripcion"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={6} md={2}>
                  <Link to="/archivos" className={classes.button}>
                    <Button
                      type="button"
                      className={classes.submit}
                      fullWidth
                      variant="outlined"
                      //size="large"
                      color="primary"
                    >
                      Cancelar
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    ENVIAR
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default withStyles(styles)(CrearArchivo);
