import { Avatar, Button, Menu, MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import withStyles from "@material-ui/core/styles/withStyles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import React, { useMemo, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import HeaderImageLink from "./HeaderImageLink";
import CambiarContrasenaDialog from "./components/dialogCambiarPass/CambiarContrasenaDialog";
import MenuIconButton from "./components/ui/MenuIconButton";
import useGetUserRole from "./hooks/useGetUserRole";
import useIsMobileScreen from "./hooks/useIsMobileScreen";
import AuthService from "./services/auth-service";
import { getUserLabel } from "./utils/user-label";
import file_path from "./utils/files_path";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const styles = (theme) => ({
  header: {
    padding: "0 0 0 0",
    textAlign: "left",
    background: "#ffffff",
    zIndex: 9999,
  },
  headerImg: {
    width: "auto",
    height: "60px",
    paddingRight: "25px",
    cursor: "pointer",
  },
  buttonLogin: {
    width: "auto",
    display: "fixed",
    marginLeft: "auto",
    marginRight: "0px",
    color: "#011C52",
    float: "right",
    margin: "0 0 0 0px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    float: "right",
    margin: "0 0 0 0",
  },
  btn: {
    backgroundColor: "#ea2428",
    color: "white",
    width: "auto",
    "&:hover": {
      backgroundColor: "rgb(234, 36, 40, 0.7)",
    },
  },
});

const Header = (props) => {
  const manualFileName = "manual.pdf";
  const history = useHistory();
  const currentUser = AuthService.getCurrentUser();
  const { isAdminRole, isInstitucionRole, isCiudadanoRole } = useGetUserRole({
    user: currentUser,
  });

  const { classes } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [redirigir, setRedirigir] = useState(false);
  const [openCambiarContrasena, setOpenCambiarContrasena] = useState(false);
  const isMobile = useIsMobileScreen();
  const userLabel = useMemo(() => getUserLabel(currentUser), [currentUser]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseLogout = () => {
    setAnchorEl(null);
    setRedirigir(true);
  };

  const handleChangePassword = () => {
    setOpenCambiarContrasena(true);
    setAnchorEl(null);
  };

  if (redirigir) {
    AuthService.logout();
    history.replace("/");
    window.location.reload();
    return <Redirect to="/" />;
  }

  return (
    AuthService.checkCurrentUser(),
    (
      <div>
        <ElevationScroll {...props}>
          <AppBar className={classes.header}>
            <Toolbar>
              {/* {isMobile && <MenuIconButton onClick={props.onMenuClick} />} */}
              {currentUser &&
                (isAdminRole || isInstitucionRole) &&
                isMobile && <MenuIconButton onClick={props.onMenuClick} />}
              <HeaderImageLink />
              <div className={classes.buttonLogin}>
                {(!currentUser || isCiudadanoRole) && (
                  <>
                    <Link to="/estadisticas">
                      <Button
                        className={classes.btn}
                        variant="contained"
                        style={{ marginRight: "5px" }}
                      >
                        ESTADISTICAS
                      </Button>
                    </Link>
                    <a
                      href={file_path + manualFileName}
                      download={file_path + manualFileName}
                      target="_blank"
                    >
                      <Button
                        className={classes.btn}
                        variant="contained"
                        style={{ marginRight: "5px" }}
                      >
                        MANUAL
                      </Button>
                    </a>
                  </>
                )}
                {!currentUser ? (
                  <Link to="/login">
                    <Button className={classes.btn} variant="contained">
                      INGRESAR
                    </Button>
                  </Link>
                ) : (
                  <>
                    <Button
                      className={classes.btn}
                      variant="contained"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      {!isMobile && <span>{userLabel}&nbsp; &nbsp; </span>}
                      <Avatar style={{ width: "22px", height: "22px" }} />
                    </Button>
                    <Menu
                      style={{ position: "fixed", marginTop: "45px" }}
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {currentUser && (isAdminRole || isInstitucionRole) ? (
                        <MenuItem onClick={handleChangePassword}>
                          Cambiar Contraseña
                        </MenuItem>
                      ) : isCiudadanoRole ? (
                        <>
                          <MenuItem
                            component={Link}
                            to="/archivos"
                            selected={location.pathname === "/archivos"}
                          >
                            Archivos
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/miperfil"
                            selected={location.pathname === "/miperfil"}
                          >
                            Mi Perfil
                          </MenuItem>
                        </>
                      ) : (
                        <></>
                      )}
                      <MenuItem onClick={handleCloseLogout}>
                        Cerrar Sesión
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
        <CambiarContrasenaDialog
          open={openCambiarContrasena}
          onClose={() => setOpenCambiarContrasena(false)}
          onChangePassword={(password, password2) => {
            // Aquí puedes manejar la lógica de cambio de contraseña
          }}
        />
      </div>
    )
  );
};

export default withStyles(styles)(Header);
