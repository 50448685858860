import { observable, decorate, action } from "mobx";

class ItemsData {
  data = [];
  loading = true;

  setLoading(isLoading = true) {
    this.loading = isLoading;
  }

  resetItemsData() {
    this.data.splice(0, this.data.length);
    this.loading = true;
  }

  setItemsData(data) {
    this.data.push(...data);
    this.loading = false;
  }
}

decorate(ItemsData, {
  data: observable,
  loading: observable,
  setLoading: action,
  resetItemsData: action,
  setItemsData: action,
});

export default ItemsData;
