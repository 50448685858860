import { inject, observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
//import classNames from 'classnames';
//import Button from '@material-ui/core/Button';
//import { BsFileEarmarkArrowUp } from 'react-icons/bs';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
//import DialogContentText from "@material-ui/core/DialogContentText";
import TableCell from "@material-ui/core/TableCell";
//import TextField from "@material-ui/core/TextField";
import { FormControl, TextField } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import moment from "moment";
import { useFilterContext } from "../../context/FilterContext";
import archivosService from "../../services/archivos-service";
import AuthService from "../../services/auth-service";
import snackbarStore from "../../stores/snackbar-store";
import DataTable from "../../toolkit/DataTable/DataTable";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import axios from "../../utils/axios";
import createParams from "../../utils/tables-params";
import CopyArchivoVersionUrlForShare from "./CopyArchivoVersionUrlForShare";
import DeleteArchivoDialog from "./DeleteArchivoDialog";
import DownloadArchivoVersionDialog from "./DownloadArchivoVersionDialog";
import DropdownShareButton from "./DropdownShareButton";

const DatasetGestionarArchivos = (props) => {
  const [versions, setVersions] = React.useState([]);
  const { filters, setFilters } = useFilterContext();
  const [filtroGeneral, setFiltroGeneral] = React.useState([]);
  const [archivoAEliminar, setArchivoAEliminar] = React.useState(undefined);
  const [abrirDialogoDescarga, setAbrirDialogoDescarga] = React.useState(false);
  const abortController = new AbortController();

  const getAllVersionByArchivoId = async (id) => {
    const resp = await archivosService.getAllVersionByArchivoId(id);
    return setVersions(resp.data);
  };

  const getAllVersionByArchivoIdAndOpenDialog = (id) => {
    getAllVersionByArchivoId(id).then(() => {
      setAbrirDialogoDescarga(true);
    });
  };

  const handleChangeFiltroGeneral = (event) => {
    const generalFilter = {
      key: "titulo",
      value: filtroGeneral,
      condition: "like",
      type: "string",
    };
    const removedGeneralFilter = filters.filter(
      (f) =>
        !(
          f.key === generalFilter.key &&
          f.value === generalFilter.value &&
          f.condition === generalFilter.condition &&
          f.type === generalFilter.type
        )
    );
    generalFilter.value = event.target.value;
    if (event.target.value) {
      setFilters([...removedGeneralFilter, generalFilter]);
    } else {
      setFilters(removedGeneralFilter);
    }
    setFiltroGeneral(event.target.value);
  };

  const Bread = () => {
    return (
      <div className={classes.paperBreadContainer}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
          <Link to="/" className={classes.txtlink}>
            Consulta Pública de Normativas
          </Link>
          <Link to="#" className={classes.txtlink}>
            Subir y moderar
          </Link>
          <Link to="#" className={classes.txtlink}>
            <strong>Gestionar Archivos</strong>
          </Link>
        </Breadcrumbs>
        <FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className={classes.paperFilter}
          margin="dense"
        >
          <TextField
            label="Buscar"
            variant="outlined"
            value={filtroGeneral}
            onChange={handleChangeFiltroGeneral}
            labelwidth={135}
            className={classes.inputFiltro}
            id="filtroTitulo"
            type="text"
            size="small"
          />
        </FormControl>
      </div>
    );
  };

  const { classes } = props;

  const getSettings = () => {
    return {
      name: {
        label: "NOMBRE DEL ARCHIVO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item ? item.nombre : ""}
          </TableCell>
        ),
      },
      descripcion: {
        label: "DESCRIPCIÓN",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item ? item.descripcion : ""}
          </TableCell>
        ),
      },
      fecha: {
        label: "FECHA DE ACTUALIZACION",
        sortable: false,
        filterable: false,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {moment(item.fechaActualizacion).format("YYYY-MM-DD")}
          </TableCell>
        ),
      },
      tamano: {
        label: "TAMAÑO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item ? item.tamano : ""}
          </TableCell>
        ),
      },
      actions: {
        label: "ACCIONES",
        sortable: false,
        filterable: false,
        align: "center",
        cellRendering: (item) => (
          <TableCell className={classes.tableCellAction}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LightTooltip title="Descargar Archivo" arrow>
                <Button
                  style={{
                    color: "#00529c",
                    maxWidth: "35px",
                    maxHeight: "35px",
                    cursor: "pointer",
                    marginLeft: "10px",
                    marginRight: "5px",
                  }}
                  onClick={() => getAllVersionByArchivoIdAndOpenDialog(item.id)}
                >
                  <GetAppIcon />
                </Button>
              </LightTooltip>
              <DropdownShareButton
                onOpen={() => getAllVersionByArchivoId(item.id)}
              >
                {versions.map((version, index) => (
                  <CopyArchivoVersionUrlForShare
                    key={version.id}
                    versionNumber={index + 1}
                    versionUrl={version.nombre}
                  />
                ))}
              </DropdownShareButton>
              <LightTooltip title="Eliminar Archivo" arrow>
                <Button
                  className={classes.button}
                  onClick={() => setArchivoAEliminar(item)}
                >
                  <DeleteIcon style={{ fontSize: "1.5rem" }} />
                </Button>
              </LightTooltip>
              <LightTooltip title="Agregar versión" arrow>
                <Link
                  to={`/archivos/${item.id}/crear_version/`}
                  className={classes.button}
                >
                  <Button className={classes.button}>
                    <NoteAddIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "35px",
                        maxHeight: "35px",
                      }}
                    />
                  </Button>
                </Link>
              </LightTooltip>
            </div>
          </TableCell>
        ),
      },
    };
  };

  const onReloadTable = () => {
    props.archivos.list.setLoading();
    let settings = getSettings();
    let params = createParams({ ...props.archivos.list, settings });
    const t = AuthService.getCurrentUserT();

    axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
    axios.defaults.headers.common["Content-Type"] = `application/json`;
    axios
      .get("/archivos", {
        data: {},
        params,
        signal: abortController.signal,
      })
      .then((resp) => {
        props.archivos.list.resetListData();
        props.archivos.list.dataLoaded(resp.data.data, {
          currentPage: resp.data.meta.currentPage - 1,
          pageSize: resp.data.meta.pageSize,
          itemsCount: resp.data.meta.total,
        });
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado de Archivos",
          "error"
        );
      });
  };

  React.useEffect(() => {
    return () => abortController.abort();
  }, []);

  return (
    <div className={classes.layout}>
      <ListDataProvider
        listStore={props.archivos.list}
        render={(listProps) => (
          <DataTable
            //title="Conjuntos de Datos"
            settings={getSettings()}
            Breads={Bread()}
            onReloadTable={onReloadTable}
            {...listProps}
          />
        )}
      />

      <Link to="/crear_archivo" className={classes.addButtonContainer}>
        <AddCircleOutlineIcon className={classes.addButton} />
      </Link>
      <div className={classes.modal}>
        <DeleteArchivoDialog
          open={!!archivoAEliminar}
          id={archivoAEliminar?.id}
          name={archivoAEliminar?.nombre}
          onClose={() => setArchivoAEliminar(undefined)}
          onReloadTable={onReloadTable}
        />
        <DownloadArchivoVersionDialog
          versions={versions}
          open={abrirDialogoDescarga}
          onClose={setAbrirDialogoDescarga}
        />
      </div>
    </div>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D6EBFF",
    color: "#00529c",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 250,
    textAlign: "center",
  },
}))(Tooltip);

const styles = (theme) => ({
  tableCell: {
    fontSize: "11px",
    //width: "auto",
    //padding: "0px",
    margin: "0px",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    color: "#00529c",
    cursor: "pointer",
    marginLeft: "0px",
    marginRight: "0px",
  },
  addButtonContainer: {
    textDecoration: "none",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  addButton: {
    fontSize: "70px",
    width: "auto",
    color: "#00529c",
    zIndex: "99999",
  },
  paperBread: {
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  cell: {
    paddingTop: "1rem",
    marginTop: "1rem",
  },
  paperBreadContainer: {
    display: "flex",
  },
  paperFilter: {
    display: "inline-block",
    marginTop: "0px",
    marginLeft: "auto",
    marginRight: "0px",
    width: "200px",
    borderRadius: "20px",
  },
});

export default withStyles(styles)(
  inject("archivos")(observer(DatasetGestionarArchivos))
);
