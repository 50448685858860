import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import AuthService from "../../services/auth-service";
import VueAppBar from "../VueAppbar2";
import MiPerfilDatos from "./MiPerfilDatos";
import DocumentosInteres from "./MiPerfilDocumentosInteres";
import MiPerfilSuscrip from "./MiPerfilSuscrip";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    display: "flex",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    /*[theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            width: 800,
            marginLeft: "auto",
            marginRight: "auto"
        }
        */
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
}));

const MiPerfil = (props) => {
  const currentUser = AuthService.getCurrentUser();
  const classes = useStyles();

  return (
    <React.Fragment>
      <VueAppBar>
        <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
          <a className={classes.txtlink} href="/">
            Consulta Pública de Normativas
          </a>
          <a className={classes.txtlink} href={props.match.url}>
            <strong>Mi Perfil</strong>
          </a>
        </Breadcrumbs>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <MiPerfilDatos currentUser={currentUser} />
            </Grid>
            <Grid item xs={8}>
              <MiPerfilSuscrip />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DocumentosInteres />
            </Grid>
          </Grid>
        </Box>
      </VueAppBar>
    </React.Fragment>
  );
};

export default MiPerfil;
