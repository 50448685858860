import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import CommentIcon from "@material-ui/icons/Comment";
import DescriptionIcon from "@material-ui/icons/Description";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import VueAppBar from "../../../src/components/VueAppbar2";
import AuthService from "../../services/auth-service";
import snackbarStore from "../../stores/snackbar-store";
import axios from "../../utils/axios";
import { FilterTypes } from "../../utils/filter";
import DataDisplay from "./DataDisplay";
import FilterEditor from "./FilterEditor";

/**
 * Componente que renderiza un datatable con capacidad de filtrado, ordenado y paginación
 * de elementos. Además de permitir incorporar acciones a realizar por registro.
 */

const Favorito = (idDocumento, abortController, setState) => {
  const t = AuthService.getCurrentUserT();
  if (t != null) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
    axios
      .get(`/favoritos/cantidad/${idDocumento}`, {
        data: {},
        signal: abortController.signal,
      })
      .then((resp) => {
        setState({ documentoFavorito: resp.data == 0 ? false : true });
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
      });
  }
};

const setFavorito = (idDocumento, abortController, setState) => {
  let esFavorito;
  const t = AuthService.getCurrentUserT();
  if (t != null) {
    setState({ isLoading: true });
    axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
    axios
      .get(`/favoritos/set/${idDocumento}`, {
        data: {},
        signal: abortController.signal,
      })
      .then((resp) => {
        if (resp.data === "OK") {
          Favorito(idDocumento, abortController, setState);
          snackbarStore.openSnackbar(
            "Operación realizada con éxito",
            "success"
          );
          setState({ isLoading: false });
        } else {
          snackbarStore.openSnackbar(
            "Error al marcar el documento como favorito",
            "error"
          );
          setState({ isLoading: false });
        }
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
        setState({ isLoading: false });
      });
    return esFavorito;
  }
};

class DataTableComentarios extends Component {
  abortController = new AbortController();

  state = {
    addingFilters: false,
    selectionFilterValues: {},
    defaultFilters: [],
    doc: {},
    documentoFavorito: false,
    isLoading: false,
  };

  constructor(props) {
    super(props);

    const { settings, defaultFilters } = props;
    const supportsFilters = Object.keys(settings).reduce(
      (acc, current) => acc || settings[current].filterable,
      false
    );
    this.state = {
      addingFilters: false,
      supportsFilters,
      selectionFilterValues: {},
      defaultFilters,
      documentoFavorito: false,
      isLoading: false,
    };
    const documento = props.idDocumento;
    Favorito(documento, this.abortController, this.setState.bind(this));
  }

  onFilterCancel() {
    this.setState({ addingFilters: false });
  }

  onFilterRequested(baseFilter) {
    this.setState({ addingFilters: true, baseFilter });
  }

  componentDidMount() {
    this.actualizar(this.state.defaultFilters ? this.state.defaultFilters : []);
    this.props.resetDataTable();

    this.props.onReloadTable();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  actualizar(filters) {
    const { settings } = this.props;
    let optionsFromSettings;
    const actual = {};
    const filtros = filters.concat(this.props.dataTableState.queryData);
    if (filtros) {
      let i;
      for (i = 0; i < filtros.length; i++) {
        let filter = filtros[i];
        if (filter.type === FilterTypes.SELECTION) {
          optionsFromSettings = settings[filter.key].selectOptions;
          if (optionsFromSettings.then) {
            optionsFromSettings.then((options) => {
              actual[filter.key] = options;
              this.setState({ selectionFilterValues: actual });
            });
          } else {
            actual[filter.key] = optionsFromSettings;
            this.setState({ selectionFilterValues: actual });
          }
        }
      }
    }
  }

  render() {
    const doc = { ...this.props.dataTableState.itemsData.data[0] };
    const idDoc = { ...doc.documento }.id;
    //let doc = Object.assign({}, this.props.dataTableState.itemsData.data[0]);
    //let org = Object.assign({}, doc.usuario.organizacion);

    const props = this.props;
    const classes = props.classes;
    const { supportsFilters, addingFilters, baseFilter } = this.state;
    return (
      <div className={classes.dataTableContainer}>
        <VueAppBar>
          {addingFilters && (
            <FilterEditor
              baseFilter={baseFilter}
              settings={props.settings}
              onFilterRemove={(filter) => props.onFilterRemove(filter)}
              onFilterAdd={(filters) => {
                props.onFilterAdd(filters);
                this.actualizar(filters);
                // props.resetPaging();
                props.resetDataTable();
                props.onReloadTable();
              }}
              onCancel={() => this.onFilterCancel()}
            />
          )}
          {props.dataTableState.itemsData.loading && (
            <LinearProgress color="primary" />
          )}
          {!props.dataTableState.itemsData.loading && props.Breads}
          <Paper className={classes.paper}>
            <div align="right">
              <LinearProgress color="primary" hidden={!this.state.isLoading} />
              <Button
                onClick={() =>
                  setFavorito(
                    props.idDocumento,
                    this.abortController,
                    this.setState.bind(this)
                  )
                }
                style={{
                  display: AuthService.getCurrentUserT() == null ? "none" : "",
                }}
              >
                {this.state.documentoFavorito ? (
                  <StarIcon />
                ) : (
                  <StarBorderIcon />
                )}
              </Button>
            </div>
            <div align="left" style={{ display: "inline-block", width: "95%" }}>
              <h3
                style={{
                  color: "#00529c",
                  margin: "5px 20px 5px 2px",
                  padding: "2px 0 2px 0",
                }}
              >
                {{ ...doc.documento }.titulo}
              </h3>
            </div>

            <div className={classes.orgInfo}>
              <div style={{ overflow: "auto" }}>
                <h4
                  style={{
                    float: "left",
                    width: "200px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  SECTOR:
                </h4>
                <h4
                  style={{
                    float: "left",
                    width: "100px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {doc.documento?.usuario?.organizacion?.abreviatura}
                </h4>
              </div>
              <div style={{ overflow: "auto" }}>
                <h4
                  style={{
                    float: "left",
                    width: "200px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  FECHA DE PUBLICACION:
                </h4>
                <h4
                  style={{
                    float: "left",
                    width: "100px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {moment({ ...doc.documento }.fecha_publicacion).format(
                    "DD-MM-YYYY"
                  )}
                </h4>
              </div>
              <div style={{ overflow: "auto" }}>
                <h4
                  style={{
                    float: "left",
                    width: "200px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  FECHA DEL REPORTE:
                </h4>
                <h4
                  style={{
                    float: "left",
                    width: "100px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {moment(Date.now()).format("DD-MM-YYYY")}
                </h4>
              </div>
              <div style={{ overflow: "auto" }}>
                <h4
                  style={{
                    float: "left",
                    width: "200px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  ESTADO:
                </h4>
                <h4
                  style={{
                    float: "left",
                    width: "100px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {{ ...doc.documento }.estado}
                </h4>
              </div>
            </div>

            <DataDisplay
              conteoComentarios={true}
              listData={props.dataTableState.itemsData}
              orderData={props.dataTableState.orderData}
              pagerData={props.dataTableState.pagerData}
              onPageChanged={(page) => {
                props.resetItemsData();
                props.onPageChanged(page);
                props.onReloadTable();
              }}
              onChangeItemsCountPerPage={(itemsCountPerPage) => {
                props.resetItemsData();
                props.onChangeItemsCountPerPage(itemsCountPerPage);
                props.onReloadTable();
              }}
              settings={props.settings}
              onOrderChanged={(key) => {
                props.resetItemsData();
                props.onOrderChanged(key);
                props.onReloadTable();
              }}
              filters={props.dataTableState.queryData}
              fileName={props.fileName}
              onDataDownload={props.onDataDownload}
            />
          </Paper>
          <div align="right">
            <Link to="/">
              <Button
                margin="normal"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                startIcon={<CommentIcon />}
              >
                VOLVER
              </Button>
            </Link>
            <Link to={`/comentar/${idDoc}`}>
              <Button
                margin="normal"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                startIcon={<CommentIcon />}
              >
                COMENTAR
              </Button>
            </Link>
            <Link to={`/reportes/${idDoc}`}>
              <Button
                margin="normal"
                variant="outlined"
                size="large"
                color="primary"
                className={classes.button}
                startIcon={<DescriptionIcon />}
              >
                GENERAR INFORME
              </Button>
            </Link>
          </div>
        </VueAppBar>
      </div>
    );
  }
}

DataTableComentarios.propTypes = {
  /**
   * Título del DataTable.
   */
  title: PropTypes.string,

  /**
   * Función que permite cargar los datos del datatable.
   */
  onReloadTable: PropTypes.func.isRequired,

  /**
   * Función que permite reiniciar el estado del datatable (solo paginacion y datos de item)
   */
  resetDataTable: PropTypes.func.isRequired,

  /**
   * Función que permite reiniciar el estado de los items
   */
  resetItemsData: PropTypes.func.isRequired,

  /**
   * Objeto que almacena el estado del datatable.
   */
  dataTableState: PropTypes.shape({
    /**
     * Listado de registros a mostrar en la tabla. Prop recibido por
     * el componente [DataDisplay](#datadisplay).
     */
    itemsData: PropTypes.object,

    /**
     * Configuración de ordenamiento.
     */
    orderData: PropTypes.object,

    /**
     * Configuración de paginación.
     */
    pagerData: PropTypes.object,

    /**
     * Objeto que contiene los filtros aplicados al datatable. Utilizado
     * por el componente [QueryBox](#querybox)
     */
    queryData: PropTypes.array,
  }).isRequired,

  /**
   * Función invocada cuando se hace click en los botones de paginación.
   *
   * @param {number} page - El número de página.
   */
  onPageChanged: PropTypes.func.isRequired,

  /**
   * Se utiliza para la inicializacion de los componentes [DataTableRow](#datatablerow) y
   * [DataTableHeader](#datatableheader)
   */
  settings: PropTypes.object.isRequired,

  /**
   * Función para agregar nuevos filtros
   */
  onFilterAdd: PropTypes.func.isRequired,

  /**
   * Funcion para eliminar un filtro
   */
  onFilterRemove: PropTypes.func.isRequired,

  /**
   * Función que restablece los datos de paginación.
   */
  resetPaging: PropTypes.func.isRequired,

  /**
   * Función invocada cuando se hace click sobre una columna "ordenable".
   *
   * @param {string} field - La columna afectada.
   */
  onOrderChanged: PropTypes.func.isRequired,

  /**
   * Array de elementos que se renderizaran en el toolbar como acciones del datatable
   */
  toolbarActions: PropTypes.arrayOf(PropTypes.element),
};

const styleSheet = (theme) => ({
  dataTableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
  },
  paper: {
    marginTop: theme.spacing(4),
    width: "100%",
    //marginTop: "0px",
    //borderRadius: `0 0 ${theme.spacing(0.5 , 0.5)}`
    borderRadius: "10px",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  orgInfo: {
    fontSize: "12px",
    marginBottom: "10px",
    color: "#00529c",
  },
  button: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    borderRadius: "10px",
    border: "solid 2px",
    "&:hover": {
      border: "2px solid rgba(0, 82, 156, 0.08)",
      backgroundColor: "rgba(0, 82, 156, 0.08)",
    },
  },
});

export default withStyles(styleSheet)(DataTableComentarios);
