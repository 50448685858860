import axios from "../utils/axios";
import snackbarStore from "../stores/snackbar-store";

async function getAll() {
  try {
    return await axios.get("organismo_internacional", { data: {} });
  } catch {
    snackbarStore.openSnackbar(
      "Error al obtener el listado de Organismos",
      "error"
    );
  }
}

const OrganismoService = {
  getAll,
};

export default OrganismoService;
