import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  layout: {
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: 0,
    display: "flex",
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      3
    )}px`,
  },
  paperBread: {
    width: "auto",
    display: "inline-block",
    fontSize: "16px",
    textDecoration: "none",
  },
  subtitle: {
    paddingTop: 0,
  },
  title: {
    marginTop: 0,
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  contenedorEstadistica: {
    borderRadius: "10px",
    border: "2px solid #E3E3E3",
    padding: "10px 10px 10px 10px",
  },
  paperBreadContainer: {
    display: "flex",
  },
  filtros: {
    display: "inline-block",
    marginTop: "0px",
    marginLeft: "auto",
    marginRight: "0px",
    borderRadius: "20px",
  },
}));

export default useStyles;
