import TableCell from "@material-ui/core/TableCell";
import React from "react";
//import { Link } from "react-router-dom";
import moment from "moment";
//import ListIcon from "@material-ui/icons/List";
//import Button from '@material-ui/core/Button';
//import classNames from 'classnames';
//import axios from "../../utils/axios";

const DatasetsConfig = (props) => {
  const { classes } = props;

  return {
    registros: {
      datosConfig: {
        titulo: "COMENTARIOS",
        servicio: "comentario",
        url: "comentario",
      },
      datosSettings: {
        "usuario.nombres": {
          label: "NOMBRE",
          sortable: true,
          filterable: true,
          type: "string",
          cellRendering: (item) => (
            <TableCell
              className={classes.tableCell}
              style={{ whiteSpace: "nowrap" }}
            >
              {item.usuario ? item.usuario.nombre_apellido : ""}
            </TableCell>
          ),
        },
        "comentario.fecha": {
          label: "FECHA",
          sortable: true,
          filterable: true,
          type: "date",
          cellRendering: (item) => (
            <TableCell
              className={classes.tableCell}
              style={{ whiteSpace: "nowrap" }}
            >
              {item ? moment(item.fecha).format("DD-MM-YYYY") : ""}
            </TableCell>
          ),
        },
        // "organizacion.abreviatura": {
        //   label: "ORGANIZACION",
        //   sortable: true,
        //   filterable: true,
        //   type: "string",
        //   cellRendering: (item) => (
        //     <TableCell
        //       className={classes.tableCell}
        //       style={{ whiteSpace: "nowrap" }}
        //     >
        //       {item.usuario ? item.usuario.organizacion?.abreviatura : ""}
        //     </TableCell>
        //   ),
        // },
        "comentario.comentario": {
          label: "COMENTARIO",
          sortable: true,
          filterable: true,
          type: "string",
          cellRendering: (item) => (
            <TableCell className={classes.tableCell}>
              {item ? item.comentario : ""}
            </TableCell>
          ),
        },
      },
    },
  };
};

export default DatasetsConfig;
