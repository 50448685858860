//import React, {useState} from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
  withStyles,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import AuthService from "../../services/auth-service";
import UserService from "../../services/user-service";
import snackbarStore from "../../stores/snackbar-store";
import axios from "../../utils/axios";
import { axiosUpdateUserPasswordElearning } from "../../utils/axiosElearning";
import ConfirmDialog from "../dialogs/ConfirmDialog";

const currentUserMoodleId = AuthService.getCurrentUser();

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "flex",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  subtittle: {
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: "2rem",
    marginLeft: "auto",
    marginRight: "auto",
    //marginRight: "auto",
    //marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "10px",
  },
  txttitulo: {
    textAlign: "left",
    color: "#00529c",
    fontSize: "20px",
  },
  registrarse: {
    textAlign: "left",
  },
  txtlink: {
    textDecoration: "none",
    color: "#00529c",
    fontWeight: "700",
  },
  red: {
    marginTop: "1rem",
    backgroundColor: "#ea2428",
    color: "white",
    "&:hover": {
      backgroundColor: "rgb(234, 36, 40, 0.7)",
    },
    "&.MuiIconButton-root": {
      backgroundColor: "transparent",
      color: "#ea2428",
    },
  },
});

const MiPerfilDatos = (props) => {
  const t = AuthService.getCurrentUserT();
  const user = props.currentUser;
  const abortController = new AbortController();
  const history = useHistory();
  const currentUser = AuthService.getCurrentUser();

  const denormalize = (data, post) => {
    const target = {
      id: user.id || "",
      nombre_apellido: data.nombre_apellido || post.nombre_apellido,
      email: data.email || post.email,
      tipo_documento: data.tipo_documento || post.tipo_documento,
      nro_documento: data.nro_documento || post.nro_documento,
      telefono: data.telefono || post.telefono,
      contrasena: data.contrasena || "",
      organizacion: data.organizacion || post.organizacion?.id,
      organismoInternacional:
        data.organismoInternacional || post.organismoInternacional?.id,
    };
    return target;
  };

  const [abrirDialogoContrasena, setAbrirDialogoContrasena] =
    React.useState(false);
  const [abrirDialogoDesactivar, setAbrirDialogoDesactivar] =
    React.useState(false);

  const cerrarDialogoContrasena = () => {
    setAbrirDialogoContrasena(false);
  };

  const cerrarDialogoDesactivar = () => {
    setAbrirDialogoDesactivar(false);
  };

  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const tipoDocumentos = ["CI", "RUC"];
  const [tipodoc, setTipodoc] = React.useState(user.tipo_documento);
  const [organizacion, setOrganizacion] = React.useState({
    id: user.organizacion?.id,
    abreviatura: user.organizacion?.abreviatura,
  });

  const [org, setOrg] = React.useState([]);

  const desactivarCuenta = () => {
    UserService.changeStatus({ id: t.id, signal: abortController.signal }).then(
      () => {
        cerrarDialogoDesactivar();
        AuthService.logout();
        history.replace("/");
        window.location.reload();
      }
    );
  };

  React.useEffect(() => {
    axios
      .get("organizaciones/basic", {
        data: {},
        signal: abortController.signal,
      })
      .then((resp) => setOrg(resp.data))
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado organizaciones",
          "error"
        );
      });
  }, []);

  const cambiarContrasena = () => {
    if (password != "" || password2 != "") {
      if (password === password2) {
        setIsLoading(true);
        const payload = { contrasena: password };

        axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
        axios.defaults.headers.common["Content-Type"] = `application/json`;

        const payloadElearning = new FormData();
        payloadElearning.append(
          "users[0][id]",
          parseInt(currentUserMoodleId.moodleid)
        );
        payloadElearning.append("users[0][password]", password);

        axiosUpdateUserPasswordElearning
          .post("", payloadElearning, { "Content-Type": "application/json" })
          .then((resp) => resp.data)
          .then((resp) => {
            if (!resp) {
              axios
                .post("usuarios/cambiarcontrasena/", payload)
                .then(() => {
                  cerrarDialogoContrasena();
                  snackbarStore.openSnackbar(
                    "Cambio de contraseña exitoso.",
                    "success"
                  );
                  setIsLoading(false);
                })
                .catch(() => {
                  snackbarStore.openSnackbar(
                    "Hubo un error al cambiar la contraseña",
                    "error"
                  );
                });
            } else if (resp.debuginfo) {
              snackbarStore.openSnackbar(resp.debuginfo, "error", 60000);
              setIsLoading(false);
            } else if (resp.errorcode) {
              snackbarStore.openSnackbar(resp.errorcode, "error", 60000);
              setIsLoading(false);
            } else {
              snackbarStore.openSnackbar(
                "Error al intentar cambiar la contraseña en el Moodle",
                "error",
                60000
              );
              setIsLoading(false);
            }
          });
      } else {
        snackbarStore.openSnackbar("Las contraseñas no coinciden.", "error");
      }
    } else {
      snackbarStore.openSnackbar("Debe completar ambos campos.", "error");
    }
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      nombre_apellido: user.nombre_apellido,
      email: user.email,
      tipo_documento: user.tipo_documento,
      nro_documento: user.nro_documento,
      telefono: user.telefono,
    },
    validationSchema: Yup.object({
      nombre_apellido: Yup.string(),
      email: Yup.string().email("Debe ser un email valido"),
    }),
    onSubmit: (formdata) => {
      setIsLoading(true);
      formik.values.tipo_documento = tipodoc;
      formik.values.organizacion = organizacion?.id;

      const payload = denormalize(formdata, user);

      const t = AuthService.getCurrentUserT();

      axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;
      axios
        .put("usuarios/modificacion", payload)
        .then((resp) => {
          snackbarStore.openSnackbar(
            "Actualización de datos exitosa",
            "success"
          );
          AuthService.updateUser();
          setIsLoading(false);
        })
        .catch(() => {
          snackbarStore.openSnackbar("Error al actualizar los datos", "error");
          setIsLoading(false);
        });
    },
  });

  const { classes } = props;

  const isNotIE =
    currentUser != null &&
    currentUser.rol.nombre !== "Administrador" &&
    (currentUser.rol.nombre === "Institución" || !currentUser.createdWithIE);

  return (
    <main>
      <Paper className={classes.paper}>
        <div align="left" style={{ width: "100%", textAlign: "center" }}>
          <LinearProgress color="primary" hidden={!isLoading} />
          <h3 style={{ color: "#0E1820" }}>Datos Personales</h3>
        </div>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <FormControl margin="dense" fullWidth variant="outlined">
            <TextField
              className={styles.field}
              variant="outlined"
              color="primary"
              multiline
              minRows={1}
              onChange={formik.handleChange}
              fullWidth
              name="nombre_apellido"
              id="nombre_apellido"
              value={formik.values.nombre_apellido}
              InputLabelProps={{ shrink: true }}
              label="Nombre y Apellido"
              //autoFocus
            />
          </FormControl>

          <FormControl margin="dense" fullWidth variant="outlined">
            <TextField
              className={styles.field}
              variant="outlined"
              color="primary"
              multiline
              minRows={1}
              onChange={formik.handleChange}
              fullWidth
              name="email"
              id="email"
              value={formik.values.email}
              InputLabelProps={{ shrink: true }}
              label="Correo"
              //autoFocus
            />
          </FormControl>

          <FormControl
            margin="dense"
            fullWidth
            variant="outlined"
            className={classes.inputS}
          >
            <Autocomplete
              id="tipo_documento"
              size="small"
              options={tipoDocumentos}
              value={tipodoc}
              inputValue={tipodoc}
              onChange={(event, newTipodoc) => {
                setTipodoc(newTipodoc);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //value={formik.values.tipo_documento}
                  onChange={formik.handleChange}
                  className={classes.inputS}
                  variant="outlined"
                  id="tipo_documento"
                  name="tipo_documento"
                  InputLabelProps={{ shrink: true }}
                  label="Tipo de Documento"
                  labelwidth={100}
                />
              )}
            />
          </FormControl>
          <FormControl margin="dense" fullWidth variant="outlined">
            <TextField
              className={styles.field}
              variant="outlined"
              color="primary"
              multiline
              minRows={1}
              onChange={formik.handleChange}
              fullWidth
              name="nro_documento"
              id="nro_documento"
              value={formik.values.nro_documento}
              InputLabelProps={{ shrink: true }}
              label="Numero de Documento"
            />
          </FormControl>

          <FormControl margin="dense" fullWidth variant="outlined">
            <TextField
              className={styles.field}
              variant="outlined"
              color="primary"
              multiline
              minRows={1}
              onChange={formik.handleChange}
              fullWidth
              name="telefono"
              id="telefono"
              value={formik.values.telefono}
              InputLabelProps={{ shrink: true }}
              label="Telefono"
            />
          </FormControl>

          <br />
          <br />

          {isNotIE && (
            <div align="left">
              <h4>
                <a
                  href="#"
                  onClick={() => setAbrirDialogoContrasena(true)}
                  style={{ color: "#0E1820" }}
                >
                  Cambiar contraseña
                </a>
              </h4>
            </div>
          )}

          <br />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
          >
            Guardar
          </Button>

          {isNotIE && (
            <Button
              className={classes.red}
              onClick={() => setAbrirDialogoDesactivar(true)}
            >
              Desactivar cuenta
            </Button>
          )}
        </form>
      </Paper>

      <ConfirmDialog
        open={abrirDialogoDesactivar}
        onClose={cerrarDialogoDesactivar}
        onConfirm={desactivarCuenta}
        title="Desactivar cuenta"
      >
        <h4>¿ Está seguro que quiere desactivar su cuenta ?</h4>
      </ConfirmDialog>

      <Dialog
        open={abrirDialogoContrasena}
        onClose={cerrarDialogoContrasena}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">Cambiar Contraseña</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Establezca su nueva contraseña.
          </DialogContentText>

          <FormControl margin="dense" fullWidth variant="outlined">
            <InputLabel htmlFor="contrasena">Contraseña</InputLabel>
            <OutlinedInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.inputS}
              id="contrasena"
              name="contrasena"
              type={values.showPassword ? "text" : "password"}
              label="Contraseña"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl margin="dense" fullWidth variant="outlined">
            <InputLabel htmlFor="repeatPassword">
              Confirmar contraseña
            </InputLabel>
            <OutlinedInput
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              className={classes.inputS}
              id="repeatPassword"
              name="repeatPassword"
              type={values.showPassword ? "text" : "password"}
              label="Confirmar contraseña"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={cerrarDialogoContrasena}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Cerrar
          </Button>

          <Button
            onClick={cambiarContrasena}
            variant="contained"
            color="primary"
            autoFocus
          >
            Cambiar
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default withStyles(styles)(MiPerfilDatos);
