import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";
import { inject, observer } from "mobx-react";
import React from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useFilterContext } from "../../context/FilterContext";
import UserService from "../../services/user-service";
import DataTable from "../../toolkit/DataTable/DataTable";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import createParams from "../../utils/tables-params";
import UsuarioFiltro from "../usuario/UsuarioFiltro";
import useFilterForm from "../usuario/useFilterForm";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";

const Dataset = (props) => {
  const { classes } = props;
  const { filters, setFilters } = useFilterContext();
  const formik = useFilterForm({ filters, setFilters });
  const abortController = new AbortController();

  const Bread = () => {
    return (
      <div className={classes.paperBreadContainer}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
          <Link className={classes.txtlink} to="/">
            Consulta Pública de Normativas
          </Link>
          <Link className={classes.txtlink} to="/usuarios">
            <strong>Usuarios</strong>
          </Link>
        </Breadcrumbs>
        <div style={{ flex: 1 }}></div>
        <UsuarioFiltro formik={formik} />
      </div>
    );
  };

  const getSettings = () => {
    return {
      nombre_apellido: {
        label: "NOMBRE Y APELLIDO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContentDocTitle}>
              {item ? item.nombre_apellido : ""}
            </p>
          </TableCell>
        ),
      },
      nro_documento: {
        label: "DOCUMENTO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableMail}>
              {item ? item.nro_documento : ""}
            </p>
          </TableCell>
        ),
      },
      email: {
        label: "EMAIL",
        sortable: true,
        filterable: true,
        width: 200,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item ? item.email : ""}
          </TableCell>
        ),
      },
      cargo: {
        label: "CARGO",
        sortable: true,
        filterable: true,
        width: 200,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            {item ? item.cargo : ""}
          </TableCell>
        ),
      },
      // id_organizacion: {
      //   label: "ORGANIZACIÓN",
      //   sortable: true,
      //   filterable: true,
      //   type: "string",
      //   cellRendering: (item) => (
      //     <TableCell className={classes.tableCell}>
      //       <p className={classes.tableContent}>
      //         {item.organizacion ? item.organizacion?.abreviatura : ""}
      //       </p>
      //     </TableCell>
      //   ),
      // },
      estado: {
        label: "ESTADO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item.estado}</p>
          </TableCell>
        ),
      },
      rol: {
        label: "ROL",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContent}>{item.rol.nombre}</p>
          </TableCell>
        ),
      },
      actions: {
        label: "ACCIONES",
        sortable: false,
        filterable: false,
        cellRendering: (item) => (
          <TableCell className={classes.tableCellAction}>
            <div style={{ display: "flex", padding: 0 }}>
              {item.rol.nombre === "Institución" ? (
                <Link
                  to={`/EditarUsuarioInstitucional/${item.id}`}
                  className={classes.button}
                >
                  <LightTooltip title="Editar Usuario" arrow>
                    <EditIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "35px",
                        maxHeight: "35px",
                      }}
                    />
                  </LightTooltip>
                </Link>
              ) : (
                <Link
                  to={`/EditarUsuarioCiudadano/${item.id}`}
                  className={classes.button}
                >
                  <LightTooltip title="Editar Usuario" arrow>
                    <EditIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "35px",
                        maxHeight: "35px",
                      }}
                    />
                  </LightTooltip>
                </Link>
              )}
              <Link to={`/estadousuario/${item.id}`} className={classes.button}>
                <LightTooltip
                  title={
                    item.estado === "Activo"
                      ? "Desactivar Usuario"
                      : "Activar Usuario"
                  }
                  arrow
                >
                  {item.estado === "Activo" ? (
                    <VisibilityIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "20px",
                        maxHeight: "20px",
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "20px",
                        maxHeight: "20px",
                      }}
                    />
                  )}
                </LightTooltip>
              </Link>
            </div>
          </TableCell>
        ),
      },
    };
  };

  const onReloadTable = () => {
    props.dataset.list.setLoading();
    let settings = getSettings();

    const uniqueFilters = Array.from(new Set(filters));
    props.dataset.list.queryData.setQuerys(uniqueFilters);

    let params = createParams({ ...props.dataset.list, settings });

    //params.page = "all";
    //params.pageSize = props.dataset.list.pagingData.itemsCount;
    UserService.getInstitucionesList({
      params,
      signal: abortController.signal,
    }).then((resp) => {
      props.dataset.list.resetListData();
      props.dataset.list.dataLoaded(resp.data.data, {
        currentPage: resp.data.meta.currentPage - 1,
        pageSize: resp.data.meta.pageSize,
        itemsCount: resp.data.meta.total,
      });
    });
  };

  React.useEffect(() => {
    if (filters) {
      props.dataset.list.pagingData.changePage(0);
      onReloadTable();
    }
  }, [filters]);

  React.useEffect(() => {
    return () => abortController.abort();
  }, []);

  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRedirect = (path) => {
    history.push(path);
    handleClose();
  };

  return (
    <React.Fragment>
      <div>
        <div className={classes.layout}>
          <ListDataProvider
            listStore={props.dataset.list}
            render={(listProps) => (
              <DataTable
                //title="Conjuntos de Datos"
                Breads={Bread()}
                settings={getSettings()}
                onReloadTable={onReloadTable}
                {...listProps}
              />
            )}
          />
        </div>
        <div className={classes.addButtonContainer}>
          <AddCircleOutlineIcon
            className={classes.addButton}
            onClick={handleClickOpen}
          />
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Seleccionar Tipo de Usuario
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Por favor, seleccione el tipo de usuario que desea crear.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleRedirect("/NuevoUsuarioInstitucional")}
              color="primary"
              variant="contained"
            >
              Usuario Institucional
            </Button>
            <Button
              onClick={() => handleRedirect("/NuevoUsuarioCiudadano")}
              color="primary"
              variant="contained"
            >
              Usuario para gremios o empresas
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
};
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D6EBFF",
    color: "#00529c",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 250,
    textAlign: "center",
  },
}))(Tooltip);

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#72616e",
    padding: theme.spacing(3),
    //height: "100vh",
    overflow: "auto",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  footer: {
    width: "100px",
    color: "#F1F1F1",
    backgroundColor: "#F1F1F1",
    marginLeft: "240px",
    marginTop: theme.spacing(4),
    padding: `${theme.spacing(3)}px 0`,
  },
  tableContentDocTitle: {
    fontSize: "14px",
    width: "125px",
    //padding: "0px",
    margin: "10px",
  },
  tableMail: {
    fontSize: "14px",
    width: "120px",
    //padding: "0px",
    margin: "10px",
  },
  tableContent: {
    fontSize: "14px",
    width: "60px",
    //padding: "0px",
    margin: "5px",
  },
  tableCell: {
    fontSize: "14px",
    //width: "auto",
    //padding: "0px",
    margin: "10px",
  },
  tableTitle: {
    fontSize: "14px",
    //width: "auto",
    //padding: "0px",
    margin: "10px",
  },
  tableCant: {
    fontSize: "14px",
    textAlign: "center",
    width: "30px",
    //padding: "0px",
    margin: "10px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    position: "relative",
    width: "auto",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
    //width: "20px",
  },
  addButtonContainer: {
    textDecoration: "none",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  addButton: {
    fontSize: "70px",
    width: "auto",
    color: "#00529c",
    zIndex: "99999",
    cursor: "pointer",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  paperBread: {
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
  },
  paperBreadContainer: {
    display: "flex",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
});

export default withStyles(styles)(inject("dataset")(observer(Dataset)));
