import ReactEcharts from "echarts-for-react";

const Bar = ({ info, comments, abbreviations }) => {
  const option = {
    title: {
      text: "Ranking de Organizaciones más comentadas",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      show: false,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: "category",
      data: abbreviations,
      axisLabel: {
        margin: 20,
        width: "90",
        overflow: "truncate",
      },
      extraCssText: "width:200px; white-space:pre-wrap;",
      inverse: true,
    },
    series: [
      {
        type: "bar",
        data: comments,
        barMaxWidth: 30,
      },
    ],
  };
  return (
    <ReactEcharts option={option} style={{ height: 500, width: "100%" }} />
  );
};

export default Bar;
