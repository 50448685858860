import React, { useState, useEffect } from "react";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { inject } from "mobx-react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DataTable from "../../toolkit/DataTable/DataTable";
import { withStyles } from "@material-ui/core/styles";
import axios from "../../utils/axios";
import TableCell from "@material-ui/core/TableCell";
import snackbarStore from "../../stores/snackbar-store";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { green, grey } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: 'none'
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  tableCell: {
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  green: {
      color: green[700]
  },
  grey: {
      color: grey[700]
  }
});

const Descargas = props => {

  const { classes } = props;
  const [ cargado, setCargado] = useState(false);
  const [ mensual, setMensual] = useState(false);

  const meses = {};
  meses['1'] = 'Enero';
  meses['2'] = 'Febrero';
  meses['3'] = 'Marzo';
  meses['4'] = 'Abril';
  meses['5'] = 'Mayo';
  meses['6'] = 'Junio';
  meses['7'] = 'Julio';
  meses['8'] = 'Agosto';
  meses['9'] = 'Septiembre';
  meses['10'] = 'Octubre';
  meses['11'] = 'Noviembre';
  meses['12'] = 'Diciembre';

  const dataset = 'registros';
  const urlDescargas = `${axios.defaults.baseURL}descarga/datos/${dataset}`;

  const getUrlDescargaPeriodo = (anho, mes) => {
    let periodo = `${anho}`;
    if(mes){
      const mes2 = ("0" + mes).slice(-2);
      periodo = `${periodo}${mes2}`;
    }
    return `${urlDescargas}_${periodo}`;
  }
  const getSettings = (mensual) => {
      return settingsGenerator(mensual);
  }

  const dataTransform = (data) => {
    if(data.length === 0){
      return data;
    }
    
    const periodos = {};
    let mensual = false;
    
    // Se verifica si el periodo es mensual o anual
    if(data && data[0].periodo.includes('/')){
      mensual = true;
    }

    // Se transforman los datos recibidos del servidor a un formato mas fácil de manipular
    // mensual {'2018': {'01': true, '02': true}}
    // anual {'2018': true}
    data.forEach(function (item) {
      if(mensual){
        const ano = item.periodo.split('/')[0];
        const mes = parseInt(item.periodo.split('/')[1], 10);
        if(!periodos[ano]){
          periodos[ano] = {};
        } 
        periodos[ano][mes] = true;
      } else {
        periodos[item.periodo] = true;
      }
    });

    const listaPeriodos = Object.keys(periodos);
    listaPeriodos.sort(); // ['2016', '2017', ...]
    let listadoFinal = [];
    // Se guarda en un array donde cada item representa un año
    listaPeriodos.forEach(function (anho) {
      let itemListadoFinal = {};
      itemListadoFinal.anho = anho;
      itemListadoFinal.id = anho;
      if(mensual){
        for (let i = 1; i <= 12; i++) {
          itemListadoFinal[meses[i]] = periodos[anho][i];
        }
      }
      listadoFinal.push(itemListadoFinal);
    });
    return listadoFinal;
  }

  const CustomTableCell = props => {
    const { mostrar, anho, mes, gridProps, xs } = props;
    return (
      <TableCell className={classes.tableCell}>
        {mostrar && 
          <Grid container {...gridProps}>
            <Grid item xs={xs}>
              <Button href={`${getUrlDescargaPeriodo(anho, mes)}.zip`} color="secondary" size="small">
                <FontAwesomeIcon icon={faFileArchive} className={clsx(classes.icon)} size="lg"/>
                zip
              </Button>
            </Grid>
            <Grid item xs={xs}>
              <Button href={`${getUrlDescargaPeriodo(anho, mes)}.md5`} color="secondary" size="small">
                <VerticalAlignBottomIcon fontSize="small"/>
                md5
              </Button>
            </Grid>
          </Grid>
        }
        {!mostrar && 
          <span>-</span>
        }
      </TableCell>
    );
  }

  const settingsGenerator = (mensual) => {
    const oneSetting = {};
    // Se agrega la primera columna
    oneSetting['anho'] = {
      label: 'Año',
      type: "string",
      cellRendering: item => (
        <TableCell className={classes.tableCell}>
          <Typography component="h1"
          variant="h6"
          color="inherit">
            {item.anho}
          </Typography>
        </TableCell>
      )
    }
    // Si es mensual se agrega una columna por cada mes
    if(mensual){
      for (let i = 1; i <= 12; i++) {
        oneSetting[meses[i]] = {
          label: meses[i],
          type: "string",
          cellRendering: item => (
            <CustomTableCell mostrar={item[meses[i]]} anho={item.anho} mes={i} xs={12}/>
          )
        }
      } 
    } else {
      const gridProps = {
        spacing: 3,
        direction: "row",
        justify: "flex-start",
        alignItems: "center",
      }
      oneSetting['descargas'] = {
        label: 'Descargas',
        type: "string",
        cellRendering: item => (
          <CustomTableCell mostrar={item} anho={item.anho} mes={item.mes} gridProps={gridProps}/>
        )
      };
    }

    return oneSetting;
  }

  const onReloadTable = () => {
    let params = {};
    params.filter = {
      "path": "actual",
      "conditions": [{
        "condition": "equals",
        "comparingObject": "1",
        "type": "integer"
      }],
      "booleanJoins": [{
        "joinType": "and",
        "filter": {
          "path": "dataset",
          "conditions": [{
            "condition": "equals",
            "comparingObject": dataset,
            "type": "string"
          }],
          "booleanJoins": []
        }
      }]
    };
    params.sorting = {"sortingConditions":[]}
    axios
      .get("descarga", {
        data: {},
        params
      })
      .then(resp => {
          const datos = resp.data.data;
          props.descargas.list.dataLoaded(dataTransform(datos),
          {
            currentPage: resp.data.meta.currentPage - 1,
            pageSize: resp.data.meta.pageSize,
            itemsCount: resp.data.meta.total
          });
          if(datos && datos[0].periodo.includes('/')){
            setMensual(true);
          }
          setCargado(true);
        }
      )
      .catch((error) => {

        snackbarStore.openSnackbar("Error al obtener las descargas", "error");
      });
  };

  useEffect(
    () => {
      props.descargas.list.resetListData();
      onReloadTable();
    },
    []
  );

  return (
    <React.Fragment>
      <div className={classes.layout}>
        {cargado && <ListDataProvider
          listStore={props.descargas.list}
          render={listProps => {
            return (
            <DataTable
              title="Registros"
              settings={getSettings(mensual)}
              onReloadTable={() => onReloadTable()}
              {...listProps}
            />
          )}}
          />
          }
      </div>
    </React.Fragment>
  );
}

Descargas.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(inject("descargas")(Descargas));
