import React from "react";
import { Route } from "react-router-dom";
import Dataset from "./Dataset";

const DataRoute = (props) => {
  return (
    <React.Fragment>
      <Route
        exact
        path={`${props.match.url}/`}
        component={Dataset}
        props={props}
      />
    </React.Fragment>
  );
};

export default DataRoute;
