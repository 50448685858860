import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { Link } from "react-router-dom";
import VueAppBar from "../VueAppbar2";
import useStyles from "./styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const EstadisticaView = ({
  url,
  Pie,
  Ranking,
  Tablero,
  DateRange,
  isLoading,
}) => {
  const styles = useStyles();
  return (
    <VueAppBar>
      <main className={styles.layout}>
        <div className={styles.paperBreadContainer}>
          <Breadcrumbs aria-label="breadcrumb" className={styles.paperBread}>
            <strong>Estadísticas de Normas en Consulta</strong>
          </Breadcrumbs>
          <div className={styles.filtros}>{DateRange}</div>
        </div>
        <LinearProgress color="primary" hidden={!isLoading} />
        <h4 className={styles.title}>Estadísticas de Comentarios</h4>
        <Paper className={styles.paper}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className={styles.contenedorEstadistica}>
                  <h5 className={styles.subtitle}>
                    Comentarios por Tipo de Organismo
                  </h5>
                  {Pie}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  {Tablero}
                  <Grid item xs={12}>
                    {Ranking}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </main>
    </VueAppBar>
  );
};

export default EstadisticaView;
