import React, { useState } from "react";
//import { Link } from "react-router-dom";
import { Button, Modal, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import snackbarStore from "../../stores/snackbar-store";
import axios from "../../utils/axios";

const denormalize = (data) => {
  const target = {
    descripcion: data.descripcion || "",
    abreviatura: data.descripcion.substr(0, 2),
  };

  return target;
};

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: "auto",
    backgroundColor: "white",
    //border: '2px solid  #00529c',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    //padding: "16px 32px 24px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  textfield: {
    width: "100%",
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    textAlign: "center",
  },
  txtp1: {
    textDecoration: "none",
    color: "#000000",
    fontWeight: "700",
  },
  field: {
    marginTop: "5px",
    marginBottom: "5px",
    display: "block",
    width: "300px",
  },
}));

const nuevoTipoActividad = (props) => {
  const formik = useFormik({
    initialValues: {
      descripcion: "",
    },
    validationSchema: Yup.object({
      descripcion: Yup.string().required(),
    }),
    onSubmit: (formdata) => {
      const payload = denormalize(formdata);
      axios
        .post("tiposactividad/nuevo/", payload, {
          "Content-Type": "application/json",
        })
        .then((resp) => {
          snackbarStore.openSnackbar(
            "Tipo Actividad registrado exitosamente.",
            "success"
          );
          props.history.push("/tipoactividad");
          window.location.reload();
        })
        .catch(() => {
          snackbarStore.openSnackbar(
            "Error al ingresar el tipo actividad",
            "error"
          );
        });
    },
  });

  const styles = useStyles();

  const [modal, setModal] = useState(true);
  const abrirCerrarModal = () => {
    setModal(!modal);
  };

  if (!modal) {
    return <Redirect to="/tipoactividad" />;
  }

  const bodymodal = (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <div className={styles.modal}>
        <div align="left">
          <h4 style={{ color: "#00529c" }}>Crear nuevo Tipo de Actividad</h4>
        </div>
        <div align="center">
          <TextField
            placeholder={"Nueva Actividad"}
            //value="Escribir Justificacion..."
            autoFocus
            className={styles.field}
            variant="outlined"
            color="primary"
            multiline
            minRows={1}
            onChange={formik.handleChange}
            fullWidth
            nanme="descripcion"
            id="descripcion"
            value={formik.values.descripcion}
          />
        </div>

        <div align="center">
          <a
            href="javascript:history.back(-1)"
            style={{ textDecoration: "none" }}
          >
            <Button
              className={styles.buttons}
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => abrirCerrarModal()}
            >
              CANCELAR
            </Button>
          </a>
          <Button
            type="submit"
            className={styles.buttons}
            variant="contained"
            color="primary"
            size="medium"
          >
            GUARDAR
          </Button>
        </div>
      </div>
    </form>
  );
  return (
    <div className={styles.container}>
      <button onClick={() => abrirCerrarModal()} style={{ display: "none" }}>
        Abrir Modal
      </button>
      <Modal open={modal} onClose={abrirCerrarModal}>
        {bodymodal}
      </Modal>
    </div>
  );
};

export default nuevoTipoActividad;
