import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

function ConfirmDialog({ children, open, onClose, onConfirm, title }) {
  const cerrarDialogo = () => {
    onClose(false);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={cerrarDialogo}
      aria-labelledby="form-dialog-title"
    >
      <div align="left">
        <DialogTitle id="form-dialog-title">
          <span style={{ color: "navy" }}>{title}</span>
        </DialogTitle>
      </div>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={cerrarDialogo}
          variant="outlined"
          size="small"
          color="primary"
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={onConfirm}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
