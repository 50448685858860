import React from "react";
import AuthService from "../../services/auth-service";
import snackbarStore from "../../stores/snackbar-store";
import ConfirmationDialog from "../../toolkit/misc/ConfirmationDialog";
import axios from "../../utils/axios";

function DeleteArchivoDialog({ id, name, open, onClose, onReloadTable }) {
  return (
    <ConfirmationDialog
      open={open}
      title="Eliminar Archivo."
      message={`Está seguro que desea eliminar el archivo ${name}?`}
      handleClose={onClose}
      handleConfirm={() => {
        const t = AuthService.getCurrentUserT();
        axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
        axios.defaults.headers.common["Content-Type"] = `application/json`;
        axios
          .delete(`archivos/eliminar/${id}`)
          .then(() => {
            onReloadTable();
            snackbarStore.openSnackbar(
              "Archivo eliminado exitosamente",
              "success"
            );
            onClose();
          })
          .catch(() =>
            snackbarStore.openSnackbar(
              "Error al realizar la operación",
              "error"
            )
          );
      }}
    />
  );
}

export default DeleteArchivoDialog;
