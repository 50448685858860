import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as d3 from "d3";
import AnimatedPieHooks from "./AnimatedPieHooks";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const Visualizaciones = props => {

  const { classes } = props;

  const generateData = (value, length = 5) =>
  d3.range(length).map((item, index) => ({
    date: index,
    value: value === null || value === undefined ? Math.random() * 100 : value
  }));

  const [data, setData] = useState(generateData(0));
  const changeData = () => {
    setData(generateData());
  };

  useEffect(
    () => {
      setData(generateData());
    },
    [!data]
  );

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <div>
            <Button onClick={changeData} color="primary">Transform</Button>
          </div>
          <div>
            <AnimatedPieHooks
              data={data}
              width={200}
              height={200}
              innerRadius={60}
              outerRadius={100}
            />
          </div>
        </Paper>
      </div>
    </React.Fragment>
  );
}

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper:{
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
});

export default withStyles(styles)(Visualizaciones);
