import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "16px",
  },
}));

export default function AlertStyled(props) {
  const styles = useStyles();
  return (
    <Alert variant="filled" severity="error" className={styles.root} {...props}>
      {props.children}
    </Alert>
  );
}
