import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  contenedorEstadisticaTexto: {
    borderRadius: "10px",
    border: "2px solid #E3E3E3",
    padding: "10px 10px 10px 10px",
    textAlign: "center",
    height: "140px",
    color: "#00529c",
  },
}));

function Tablero({ data }) {
  const styles = useStyles();
  return (
    <>
      {data && (
        <>
          {data.comments.map(({ quantity, organization }) => (
            <Grid key={organization} item xs={6} md={4}>
              <div className={styles.contenedorEstadisticaTexto}>
                <h1>{quantity}</h1>
                <h3>{organization}</h3>
              </div>
            </Grid>
          ))}

          <Grid item xs={12} md={4}>
            <div className={styles.contenedorEstadisticaTexto}>
              <h1>{data.numberOfComments}</h1>
              <h3>Total de Comentarios</h3>
            </div>
          </Grid>
        </>
      )}
    </>
  );
}

export default Tablero;
