import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

const ListDataProvider = props => {
  const { listStore } = props;
  const childrenProps = {
    dataTableState: {
      itemsData: toJS(listStore.itemsData),
      pagerData: toJS(listStore.pagingData),
      orderData: toJS(listStore.orderData.orders),
      queryData: toJS(listStore.queryData.querys)
    },
    resetDataTable: () => listStore.resetListData(),
    resetItemsData: () => listStore.itemsData.resetItemsData(),
    onPageChanged: page => listStore.pagingData.changePage(page),
    onChangeItemsCountPerPage: itemsPerPage =>
      listStore.pagingData.changeItemsPerPage(itemsPerPage),
    onOrderChanged: order => listStore.orderData.changeOrder(order),
    onFilterAdd: filters => listStore.queryData.addQuery(filters),
    onFilterRemove: filter => listStore.queryData.removeQuery(filter),
    resetPaging: () => listStore.pagingData.changePage(0)
  };
  return <React.Fragment>{props.render(childrenProps)}</React.Fragment>;
};

export default observer(ListDataProvider);
