import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { inject, observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import DataTable from "../../toolkit/DataTable/DataTable";
import ListDataProvider from "../../toolkit/mobx-connectors/ListDataProvider";
import TipoActividadService from "../../services/tipoactividadsercice";
import createParams from "../../utils/tables-params";

const DatasetTipoActividad = (props) => {
  const { classes } = props;
  const abortController = new AbortController();

  const Bread = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
        <Link className={classes.txtlink} to="/">
          Consulta Pública de Normativas
        </Link>
        <Link className={classes.txtlink} to="/tipoactividad">
          <strong>Tipo de actividad</strong>
        </Link>
      </Breadcrumbs>
    );
  };

  const getSettings = () => {
    return {
      descripcion: {
        label: "DESCRIPCIÓN",
        sortable: true,
        filterable: true,
        editable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContentDocTitle}>
              {item ? item.descripcion : ""}
            </p>
          </TableCell>
        ),
      },
      estado: {
        label: "ESTADO",
        sortable: true,
        filterable: true,
        type: "string",
        cellRendering: (item) => (
          <TableCell className={classes.tableCell}>
            <p className={classes.tableContentDocTitle}>
              {item ? item.estado : ""}
            </p>
          </TableCell>
        ),
      },
      actions: {
        label: "ACCIONES",
        sortable: false,
        filterable: false,
        cellRendering: (item) => (
          <TableCell className={classes.tableCellAction}>
            <div style={{ display: "flex", padding: 0 }}>
              <Link to={`/editActivity/${item.id}`} className={classes.button}>
                <LightTooltip title="Editar Tipo de Actividad" arrow>
                  <EditIcon
                    style={{
                      color: "#00529c",
                      maxWidth: "35px",
                      maxHeight: "35px",
                    }}
                  />
                </LightTooltip>
              </Link>
              <Link
                to={`/estadotipoactividad/${item.id}`}
                className={classes.button}
              >
                <LightTooltip
                  title={
                    item.estado === "Activo"
                      ? "Desactivar Tipo de Actividad"
                      : "Activar Tipo Actividad"
                  }
                  arrow
                >
                  {item.estado === "Activo" ? (
                    <VisibilityIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "20px",
                        maxHeight: "20px",
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{
                        color: "#00529c",
                        maxWidth: "20px",
                        maxHeight: "20px",
                      }}
                    />
                  )}
                </LightTooltip>
              </Link>
            </div>
          </TableCell>
        ),
      },
    };
  };
  /*prueba*/
  /*<Link  to={`/${item.id}/datos`} className={classes.button}>*/
  //verificar que hacia la linea de abajo
  /*<Route exact path={`${props.match.url}/`} component={Dataset} props={props}/>*/

  const onReloadTable = () => {
    props.dataset.list.setLoading();
    let settings = getSettings();
    const orderData = { orders: { descripcion: "asc" } };
    let params = createParams({ ...props.dataset.list, settings, orderData });

    //params.page = 5;
    //params.page = "all";
    //params.pageSize = props.dataset.list.pagingData.itemsCount;

    TipoActividadService.getList({
        params,
        signal: abortController.signal,
      })
      .then((resp) => {
        props.dataset.list.resetListData();
        props.dataset.list.dataLoaded(resp.data.data, {
          currentPage: resp.data.meta.currentPage - 1,
          pageSize: resp.data.meta.pageSize,
          itemsCount: resp.data.meta.total,
        });
      });
  };

  React.useEffect(() => {
    return () => abortController.abort();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <ListDataProvider
          listStore={props.dataset.list}
          render={(listProps) => (
            <DataTable
              //title="Conjuntos de Datos"
              Breads={Bread()}
              settings={getSettings()}
              onReloadTable={onReloadTable}
              {...listProps}
            />
          )}
        />

        <Link to="/nuevoTipoActividad" className={classes.addButtonContainer}>
          <AddCircleOutlineIcon className={classes.addButton} />
        </Link>
      </div>
    </React.Fragment>
  );
};
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D6EBFF",
    color: "#00529c",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 250,
    textAlign: "center",
  },
}))(Tooltip);

const styles = (theme) => ({
  tableContentDocTitle: {
    fontSize: "14px",
    width: "auto",
    //padding: "0px",
    margin: "10px",
  },
  tableMail: {
    fontSize: "14px",
    width: "120px",
    //padding: "0px",
    margin: "10px",
  },
  tableContent: {
    fontSize: "14px",
    width: "60px",
    //padding: "0px",
    margin: "5px",
  },
  tableCell: {
    fontSize: "14px",
    //width: "auto",
    //padding: "0px",
    margin: "10px",
  },
  tableTitle: {
    fontSize: "14px",
    //width: "auto",
    //padding: "0px",
    margin: "10px",
  },
  tableCant: {
    fontSize: "14px",
    textAlign: "center",
    width: "30px",
    //padding: "0px",
    margin: "10px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  layout: {
    position: "relative",
    width: "auto",
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    margin: theme.spacing(1),
    textDecoration: "none",
    //width: "20px",
  },
  addButtonContainer: {
    textDecoration: "none",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  addButton: {
    fontSize: "70px",
    width: "auto",
    color: "#00529c",
    zIndex: "99999",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
});

export default withStyles(styles)(
  inject("dataset")(observer(DatasetTipoActividad))
);
