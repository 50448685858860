import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";

async function changeStatus(id, signal) {
  return axios
    .put(
      `tipodocumento/estado/${id}`,
      {},
      { signal, "Content-Type": "application/json" }
    )
    .then((resp) => {
      snackbarStore.openSnackbar("Cambio de estado exitoso", "success");
      return resp;
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al obtener los datos del servidor",
        "error"
      );
    });
}

const getList = async ({ params, signal }) => {
  return axios
    .get("tipodocumento", {
      data: {},
      params,
      signal,
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al obtener el listado de tipo de documentos",
        "error"
      );
    });
};

const getAll = async (signal = undefined) => {
  return axios.get("tipodocumento/list", { data: {}, signal });
};

const get = (id) => {
  return axios.get(`tipodocumento/${id}`);
};

const save = async ({ payload, signal }) => {
  return axios
    .post("tipodocumento", payload, {
      signal,
      "Content-Type": "application/json",
    })
    .then((resp) => {
      snackbarStore.openSnackbar(
        "Tipo Documento registrado exitosamente.",
        "success"
      );
      return resp;
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al ingresar el tipo de documento",
        "error"
      );
    });
};

const update = async ({ id, payload, signal }) => {
  return axios
    .put(`tipodocumento/${id}`, payload, {
      signal,
      "Content-Type": "application/json",
    })
    .then((resp) => {
      snackbarStore.openSnackbar(
        "Tipo Documento actualizado exitosamente.",
        "success"
      );
      return resp;
    })
    .catch(() => {
      snackbarStore.openSnackbar(
        "Error al editar el tipo de documento",
        "error"
      );
    });
};

const TipoDocumentoService = {
  changeStatus,
  get,
  getAll,
  getList,
  save,
  update,
};

export default TipoDocumentoService;
