import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const EstadisticaDialog = ({ open, onClose, Table }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={false}
      onClose={() => onClose(false)}
    >
      <DialogTitle id="alert-dialog-title">Detalle de Comentarios</DialogTitle>
      <DialogContent>{Table}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(false)}
          variant="contained"
          color="primary"
          autoFocus
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EstadisticaDialog;
