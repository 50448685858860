import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VueAppBar from "../VueAppbar2";
//import createParams from "../../utils/tables-params";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import DescriptionIcon from "@material-ui/icons/Description";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import snackbarStore from "../../stores/snackbar-store";
import axios from "../../utils/axios";

const useStyles = makeStyles((theme) => ({
  layout: {
    //width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    //display: "flex",
    //flexDirection: "column",
    //alignItems: "auto",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  textfield: {
    width: "100%",
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    textAlign: "center",
  },
  txtp1: {
    textDecoration: "none",
    color: "#000000",
    fontWeight: "400",
    fontSize: "12px",
  },
  tables: {
    marginLeft: "0",
    paddingLeft: "0",
  },
  orgInfo: {
    fontSize: "12px",
    marginBottom: "10px",
    color: "#00529c",
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
}));

function Reportes(props) {
  const { goBack } = useHistory();
  const styles = useStyles();

  const [post, setPost] = useState([]);
  const [doc, setDoc] = useState([]);
  const idDocumento = props.match.params.idDocumento;
  const classes = props.classes;
  const imprimir = () => {
    const printContent = document.getElementById("table");
    // Imprimir en PDF
    const archivo = new Blob([printContent.innerHTML], {
      type: "text/html; charset=utf-8",
    });
    const url = URL.createObjectURL(archivo);
    const ventana = window.open(url, "_blank");
    ventana?.print();
  };
  useEffect(() => {
    axios
      .get(`comentarios/documentos/${idDocumento}`, {
        data: {},
      })
      .then((resp) => setPost(resp.data))
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
      });

    axios
      .get(`documento/${idDocumento}`, {
        data: {},
      })
      .then((resp) => setDoc(resp.data))
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
      });
  }, []);
  //__________________________________________________

  return (
    <React.Fragment>
      <VueAppBar>
        <main className={styles.layout}>
          <Breadcrumbs aria-label="breadcrumb" className={styles.paperBread}>
            <Link className={styles.txtlink} to="/inicio">
              Consulta Pública de Normativas
            </Link>
            <Link className={styles.txtlink} to={props.match.url}>
              <strong>Reporte</strong>
            </Link>
          </Breadcrumbs>

          <Paper className={styles.paper}>
            <Button
              margin="normal"
              variant="outlined"
              size="large"
              color="primary"
              className={classes?.button}
              startIcon={<DescriptionIcon />}
              onClick={imprimir}
            >
              GENERAR REPORTE
            </Button>
            <div id="table">
              <div align="left">
                <h3
                  style={{
                    color: "#00529c",
                    margin: "2px 0 2px 0",
                    padding: "2px 0 2px 0",
                  }}
                >
                  {doc.titulo}
                </h3>
              </div>
              <div className={styles.orgInfo}>
                {/* <div style={{ overflow: "auto" }}>
                  <h4
                    style={{
                      float: "left",
                      width: "200px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    ORGANIZACION:
                  </h4>
                  <h4
                    style={{
                      float: "left",
                      width: "100px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {{ ...{ ...doc.id_usuario }.organizacion }?.abreviatura}
                  </h4>
                </div> */}
                <div style={{ overflow: "auto" }}>
                  <h4
                    style={{
                      float: "left",
                      width: "200px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    FECHA DE PUBLICACION:
                  </h4>
                  <h4
                    style={{
                      float: "left",
                      width: "100px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {moment(doc.fecha_publicacion).format("DD-MM-YYYY")}
                  </h4>
                </div>
                <div style={{ overflow: "auto" }}>
                  <h4
                    style={{
                      float: "left",
                      width: "200px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    FECHA DEL REPORTE:
                  </h4>
                  <h4
                    style={{
                      float: "left",
                      width: "100px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {moment(Date.now()).format("DD-MM-YYYY")}
                  </h4>
                </div>
                <div style={{ overflow: "auto" }}>
                  <h4
                    style={{
                      float: "left",
                      width: "200px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    ESTADO:
                  </h4>
                  <h4
                    style={{
                      float: "left",
                      width: "100px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {doc.estado}
                  </h4>
                </div>
                <div style={{ overflow: "auto" }}>
                  <h4
                    style={{
                      float: "left",
                      width: "200px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    COMENTARIOS:
                  </h4>
                  <h4
                    style={{
                      float: "left",
                      width: "100px",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {post.length}
                  </h4>
                </div>
                <div align="left">
                  <h4
                    style={{
                      color: "#00529c",
                      fontSize: "14px",
                      marginTop: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    REPORTE
                  </h4>
                </div>
              </div>
              <TableContainer>
                <Table className={styles.tables}>
                  <TableBody>
                    {post.map((com) => (
                      <TableRow key={com.id}>
                        <TableCell>
                          <h4
                            style={{
                              float: "top",
                              width: "100px",
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            {com.usuario.nombre_apellido}
                          </h4>
                          <h4
                            style={{
                              float: "top",
                              width: "150px",
                              margin: "2px 0 2px 0",
                              padding: "2px 0 2px 0",
                              fontSize: "11px",
                            }}
                          >
                            {com.usuario.organizacion
                              ? com.usuario.organizacion?.abreviatura
                              : "Ciudadano"}{" "}
                            - {moment(com.fecha).format("DD-MM-YYYY")}
                          </h4>
                          <p className={styles.txtp1}>{com.comentario}</p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container>
                <Grid item>
                  <Button
                    onClick={goBack}
                    variant="contained"
                    color="primary"
                    autoFocus
                  >
                    Volver
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </main>
      </VueAppBar>
    </React.Fragment>
  );
}

export default Reportes;
