import React, { useEffect, useState } from "react";
//import {Table, TableContainer, TableHead, TableBody, TableRow, Button, TextField} from '@material-ui/core';
import { Button, InputAdornment, TextField } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useFormik } from "formik";
import moment from "moment";
import { Link, Redirect, useHistory } from "react-router-dom";
import * as Yup from "yup";
import AuthService from "../../services/auth-service";
import snackbarStore from "../../stores/snackbar-store";
import axios from "../../utils/axios";
import VueAppBar from "../VueAppbar2";

const denormalize = (data, post) => {
  const target = {
    comentario: data.comentario || "",
    fecha: new Date(),
    documento: idDocumento,
    content: content?.[1],
    archivoExtension: data.archivoExtension || "",
    filename: data.archivo || "",
  };

  return target;
};

const styles = (theme) => ({
  //const useStyles = makeStyles((theme) => ({
  layoutcomentar: {
    //width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  papercomentar: {
    marginTop: theme.spacing(4),
    //display: "flex",
    //flexDirection: "column",
    //alignItems: "auto",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  orgInfo: {
    marginBottom: "20px",
    color: "#00529c",
  },
  field: {
    marginTop: "20px",
    marginBottom: "10px",
    display: "block",
  },
  submit: {
    display: "flex",
    marginTop: "1rem",
    marginLeft: "auto",
    marginRight: "auto",
    //width: "15%"
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  container: {
    display: "flex",
    direction: "row",
    alignItems: "center",
  },
});

let idDocumento;
let content;

const Comentar = (props) => {
  // Render del archivo
  var reader = new FileReader();

  const currentUser = AuthService.getCurrentUser();

  const hiddenFileInput = React.useRef(null);
  const handleClickFile = (event) => {
    hiddenFileInput.current.click();
  };
  const [archivo, setArchivo] = useState({ undefined });
  const handleChangeFile = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size > 52428800) {
      snackbarStore.openSnackbar(
        "El tamaño del archivo no debe ser mayor a 50 MB.",
        "error"
      );
    } else {
      reader.readAsDataURL(fileUploaded);
      reader.onload = function () {
        const base64 = reader.result;
        content = base64.split(",");
        setArchivo(fileUploaded);
        const filename = fileUploaded.name;
        const pieces = filename.split(".");
        const extension = pieces[pieces.length - 1];
        formik.setFieldValue("adjuntar", fileUploaded.name);
        formik.setFieldValue("archivo", fileUploaded.name);
        formik.setFieldValue("archivoExtension", extension);
      };
    }
  };

  const hoy = Date.now();
  const history = useHistory();
  const Bread = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
        <Link className={classes.txtlink} to="/inicio">
          Consulta Pública de Normativas
        </Link>
        <Link className={classes.txtlink} to={props.match.url}>
          <strong>Realizar comentario</strong>
        </Link>
      </Breadcrumbs>
    );
  };

  const [abrirDialogoConfirmacion, setAbrirDialogoConfirmacion] =
    React.useState(false);
  const [estadoBtnGuardar, setEstadoBtnGuardar] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cerrarDialogoConfirmacion = () => {
    setAbrirDialogoConfirmacion(false);
  };

  const cerrar = () => {
    history.push("/");
    // window.location.reload();
  };

  const formik = useFormik({
    initialValues: {
      comentario: "",
      adjuntar: "",
      archivoExtension: "",
      acceptTerms: false,
    },
    validationSchema: Yup.object({
      comentario: Yup.string().required(),
      acceptTerms: Yup.bool().oneOf(
        [true],
        "Debe aceptar los Términos y Condiciones"
      ),
    }),
    onSubmit: (formdata) => {
      setIsLoading(true);
      setEstadoBtnGuardar(true);
      const payload = denormalize(formdata, currentUser);

      const t = AuthService.getCurrentUserT();

      axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;

      axios
        .post("comentarios/nuevo/", payload)
        .then((resp) => {
          setIsLoading(false);
          setAbrirDialogoConfirmacion(true);
        })
        .catch(() => {
          snackbarStore.openSnackbar(
            "Error al ingresar el comentario",
            "error"
          );
          setIsLoading(false);
        });
    },
  });

  const handleClickAcceptTerms = () => {
    if (!formik.values.acceptTerms) {
      snackbarStore.openSnackbar(
        "Debe aceptar los Términos y condiciones",
        "error"
      );
    }
  };

  const [post, setPost] = useState([]);
  idDocumento = props.match.params.idDocumento;

  useEffect(() => {
    axios
      .get(`documento/${idDocumento}`, {
        data: {},
      })
      .then((resp) => {
        setPost(resp.data);
        if (
          resp.data.fecha_limite <= hoy ||
          resp.data.estado === "Finiquitado"
        ) {
          cerrar();
        }
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
      });
  }, []);

  const { classes } = props;

  if (currentUser !== null) {
    return (
      <React.Fragment>
        <VueAppBar>
          <main className={classes.layoutcomentar}>
            <Bread />
            <Paper className={classes.papercomentar}>
              <LinearProgress color="primary" hidden={!isLoading} />
              <div align="left">
                <h3
                  style={{
                    color: "#00529c",
                    margin: "5px 2px 5px 2px",
                    padding: "2px 0 2px 0",
                  }}
                >
                  {post.titulo}
                </h3>
              </div>

              <div className={classes.orgInfo}>
                <div style={{ overflow: "auto" }}>
                  <h4
                    style={{
                      float: "left",
                      width: "200px",
                      margin: "2px 1px 1px 1px",
                      padding: "0",
                    }}
                  >
                    ORGANIZACION:
                  </h4>
                  <h4
                    style={{
                      float: "left",
                      width: "100px",
                      margin: "2px 1px 1px 1px",
                      padding: "0",
                    }}
                  >
                    {post.usuario?.organizacion?.abreviatura}
                  </h4>
                </div>
                <div style={{ overflow: "auto" }}>
                  <h4
                    style={{
                      float: "left",
                      width: "200px",
                      margin: "2px 1px 1px 1px",
                      padding: "0",
                    }}
                  >
                    FECHA LIMITE:
                  </h4>
                  <h4
                    style={{
                      float: "left",
                      width: "100px",
                      margin: "2px 1px 1px 1px",
                      padding: "0",
                    }}
                  >
                    {moment(post.fecha_limite).format("DD-MM-YYYY")}
                  </h4>
                </div>
                <div style={{ overflow: "auto" }}>
                  <h4
                    style={{
                      float: "left",
                      width: "200px",
                      margin: "2px 1px 1px 1px",
                      padding: "0",
                    }}
                  >
                    ESTADO:
                  </h4>
                  <h4
                    style={{
                      float: "left",
                      width: "100px",
                      margin: "2px 1px 1px 1px",
                      padding: "0",
                    }}
                  >
                    {post.estado}
                  </h4>
                </div>
              </div>

              <form
                className={classes.form}
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <FormControl margin="dense" fullWidth variant="outlined">
                  <TextField
                    className={classes.field}
                    variant="outlined"
                    color="primary"
                    multiline
                    minRows={10}
                    onChange={formik.handleChange}
                    value={formik.values.comentario}
                    id="comentario"
                    name="comentario"
                    fullWidth
                  />
                </FormControl>

                <FormControl margin="dense" fullWidth variant="outlined">
                  <TextField
                    margin="dense"
                    variant="outlined"
                    label="Adjuntar documento"
                    id="adjuntar"
                    name="adjuntar"
                    fullWidth
                    className={classes.inputS}
                    value={archivo ? archivo.name : "vacio"}
                    error={formik.errors.adjuntar && true}
                    helperText={formik.errors.adjuntar}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Button onClick={handleClickFile}>
                            <AttachFileIcon />
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    accept="application/pdf,.doc,.docx"
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChangeFile}
                    style={{ display: "none" }}
                  />
                  <span style={{ fontSize: "11px" }}>
                    {" "}
                    *El documento debe estar en formato PDF o MS Word y no
                    superar los 50MB
                  </span>
                </FormControl>

                <FormControl margin="dense" fullWidth variant="outlined">
                  <FormControlLabel
                    value="aceptar"
                    control={
                      <Checkbox
                        color="primary"
                        value={formik.values.acceptTerms}
                        onChange={formik.handleChange}
                        error={formik.errors.acceptTerms}
                        id="acceptTerms"
                        name="acceptTerms"
                      />
                    }
                    label="Al enviar el comentario acepto que el mismo estará sujeto a revisión previa de contenido apropiado
                                    según los Términos de Uso del sitio. Una vez aprobado el comentario estará público en este sitio,
                                    siendo lo expresado en el mismo entera responsabilidad de la persona quien publica."
                    labelPlacement="end"
                  />
                </FormControl>

                <div className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    //fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.submit}
                    onClick={handleClickAcceptTerms}
                    disabled={estadoBtnGuardar || isLoading}
                  >
                    GUARDAR
                  </Button>
                  <Button
                    type="submit"
                    //fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.submit}
                    onClick={() => history.replace("/")}
                    disabled={estadoBtnGuardar}
                  >
                    VOLVER
                  </Button>
                </div>
              </form>
            </Paper>

            <Dialog
              open={abrirDialogoConfirmacion}
              onClose={cerrarDialogoConfirmacion}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Comentario Enviado"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Su comentario ha sido enviado correctamente. Estará sujeto a
                  revisión y aprobación de contenido propiado, para su
                  publicación posterior.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={cerrar}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Cerrar
                </Button>
              </DialogActions>
            </Dialog>
          </main>
        </VueAppBar>
      </React.Fragment>
    );
  } else {
    return (
      snackbarStore.openSnackbar(
        "Para realizar un comentario, primero inicie sesión",
        "warning"
      ),
      (<Redirect to={"/login/" + encodeURIComponent(props.match.url)} />)
    );
  }
};

export default withStyles(styles)(Comentar);
