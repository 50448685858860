import { Button, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    borderTop: "5px solid rgb(0, 82, 156)",
    borderRadius: 0,
    textAlign: "center",
    width: "250px",
    padding: "1rem",
    overflow: "visible",
  },
  button: {
    width: "100%",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  icon: {
    padding: "50px 0 30px",
  },
  title: {
    fontSize: "24px",
    margin: "10px 0",
    fontWeight: "bold",
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: "18px",
  },
}));

export default function CardButton({ title, description, icon, onClick }) {
  const classes = useStyles();
  return (
    <Card className={classes.cardContainer}>
      <div>
        <div className={classes.icon}>{icon}</div>
        <h2 className={classes.title}>{title}</h2>
        <h3 className={classes.description}>{description}</h3>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onClick}
        >
          Ver más
        </Button>
      </div>
    </Card>
  );
}
