import {
  Button,
  Grid,
  OutlinedInput,
  TextField,
  withStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import OrganizationService from "../../services/organizacionservice";
import RolService from "../../services/rol-service";
import FilterIcon from "../icons/FilterIcon";
import DenseFormControl from "../material/DenseFormControl";
import "./UsuarioFiltro.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

function UsuarioFiltro({ classes, formik }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const abortController = new AbortController();
  const open = Boolean(anchorEl);
 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    handleClose();
    formik.handleSubmit();
  };
  const [organizationList, setOrganizationList] = React.useState([]);
  const [roleList, setRoleList] = React.useState([]);
  React.useEffect(() => {
    OrganizationService.getAll({ signal: abortController.signal }).then(
      (resp) => setOrganizationList(resp.data.data)
    );
    RolService.getAll({ signal: abortController.signal }).then((resp) =>
      setRoleList(resp.data)
    );
  }, []);
  return (
    <div>
      <Button
        variant="contained"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={classes.iconButton}
        startIcon={<FilterIcon className={classes.filterIcon} />}
        size="small"
        onClick={handleClick}
      >
        Busqueda Avanzada
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ top: "115px" }}
      >
        <li>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
              <DenseFormControl>
                <OutlinedInput
                  placeholder="Nombre y Apellido"
                  name="nombre_apellido"
                  value={formik.values.nombre_apellido}
                  onChange={formik.handleChange}
                />
              </DenseFormControl>
            </Grid>
            <Grid item xs={12}>
              <DenseFormControl>
                <OutlinedInput
                  placeholder="Documento"
                  name="nro_documento"
                  value={formik.values.nro_documento}
                  onChange={formik.handleChange}
                />
              </DenseFormControl>
            </Grid>
            <Grid item xs={12}>
              <DenseFormControl>
                <OutlinedInput
                  placeholder="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </DenseFormControl>
            </Grid>
            <Grid item xs={12}>
              <DenseFormControl>
                <Autocomplete
                  size="small"
                  multiple
                  options={organizationList}
                  value={formik.values?.organizaciones ?? []}
                  getOptionSelected={(option, value) => {
                    return option?.id === value?.id;
                  }}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("organizaciones", [...newValue]);
                  }}
                  getOptionLabel={(option) => option?.abreviatura}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.abreviatura}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Organizaciones"
                    />
                  )}
                />
              </DenseFormControl>
            </Grid>
            <Grid item xs={12}>
              <DenseFormControl>
                <Autocomplete
                  size="small"
                  multiple
                  options={roleList}
                  value={formik.values?.roles ?? []}
                  getOptionSelected={(option, value) => {
                    return option?.id === value?.id;
                  }}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("roles", [...newValue]);
                  }}
                  getOptionLabel={(option) => option?.nombre}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nombre}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Roles" />
                  )}
                />
              </DenseFormControl>
            </Grid>
            <Grid item xs={12} className={classes.btnContainer}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Aplicar
              </Button>
            </Grid>
          </Grid>
        </li>    


      </Menu>
    </div>
  );
}

const styles = () => ({
  iconButton: {
    backgroundColor: "#ffc31b",
    padding: "8px",
    marginLeft: "1.5rem",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgb(255, 195, 27, 0.7)",
    },
    "& MuiButton-startIcon.MuiButton-iconSizeSmall": {
      margin: 0,
    },
  },
  filterIcon: {
    margin: 0,
  },
  container: {
    width: "100%",
    maxWidth: "360px",
    margin: "0 8px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
  },
  dateInput: {
    "& input::before": {
      content: "attr(placeholder)",
      color: "#aaa",
      marginRight: "0.5em",
    },
    "& input::-webkit-datetime-edit": {
      color: "transparent",
    },
  },
  dateInputWithValue: {
    "& input::before": {
      content: "",
      color: "#aaa",
      marginRight: "0.5em",
    },
    "& input::-webkit-datetime-edit": {
      color: "black !important",
    },
  },
});

export default withStyles(styles)(UsuarioFiltro);
