import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import VueAppBar from "../VueAppbar2";
import CrearArchivo from "./CrearArchivo";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    display: "flex",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "center",
      marginRight: "center",
    },
  },
}));

const NuevoArchivo = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <VueAppBar>
        <div className={classes.root}>
          <CrearArchivo archivoId={props.match.params.idArchivo} />
        </div>
      </VueAppBar>
    </React.Fragment>
  );
};

export default NuevoArchivo;
