import { observable, decorate } from "mobx";
import ListData from "../toolkit/mobx-connectors/observables/list-data";

class RegistroStore {
  list = new ListData();
}

decorate(RegistroStore, {
  list: observable
});

const registroStore = new RegistroStore();
export default registroStore;
