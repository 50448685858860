import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React from "react";

function OrganizacionForm({ formik }) {
  const handleTypeOrgChange = (_, type) => {
    formik.setFieldValue("tipo_organizacion", type);
  };
  return (
    <>
      <FormControl component="fieldset" style={{ marginBottom: "1rem" }}>
        <FormLabel component="legend">Tipo de Organismo</FormLabel>
        <RadioGroup
          row
          aria-label="tipo_organizacion"
          name="tipo_organizacion"
          value={formik.values.tipo_organizacion}
          onChange={handleTypeOrgChange}
        >
          <FormControlLabel
            value="Privada"
            control={<Radio color="default" />}
            label="Privada"
          />
          <FormControlLabel
            value="Pública"
            control={<Radio color="default" />}
            label="Pública"
          />
        </RadioGroup>
      </FormControl>
      <div align="center">
        <TextField
          size="small"
          variant="outlined"
          color="primary"
          multiline
          onChange={formik.handleChange}
          fullWidth
          label="Descripción"
          name="descripcion"
          id="descripcion"
          value={formik.values.descripcion}
          error={formik.errors.descripcion && true}
          helperText={formik.errors.descripcion}
          autoFocus
        />
      </div>
      <div align="center">
        <TextField
          size="small"
          variant="outlined"
          color="primary"
          multiline
          onChange={formik.handleChange}
          fullWidth
          label="Abreviatura"
          name="abreviatura"
          id="abreviatura"
          value={formik.values.abreviatura}
          error={formik.errors.abreviatura && true}
          helperText={formik.errors.abreviatura}
          style={{ marginTop: "1rem" }}
        />
      </div>
    </>
  );
}

export default OrganizacionForm;
