import React from "react";
import OrganizacionesService from "../../services/organizacionservice";
import snackbarStore from "../../stores/snackbar-store";
import RangeDateField from "../material/RangeDateField";
import Bar from "./Bar";
import DetailTable from "./DetailTable";
import EstadisticaDialog from "./EstadisticaDialog";
import EstadisticaView from "./EstadisticaView";
import Pie from "./Pie";
import Tablero from "./Tablero";

const defaultData = {
  numberOfDocuments: 0,
  numberOfComments: 0,
  numberOfVersions: 0,
  comments: [],
  versions: [],
};

function Estadisticas(props) {
  const [data, setData] = React.useState(defaultData);
  const [detail, setDetail] = React.useState([]);
  const [ranking, setRanking] = React.useState({ info: [], comments: [] });
  const [desde, setDesde] = React.useState(null);
  const [hasta, setHasta] = React.useState(null);
  const [abrirDialogo, setAbrirDialogo] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const pieData = data.comments.map((v) => ({
    name: v.organization,
    value: v.quantity,
  }));

  React.useEffect(() => {
    setIsLoading(true);
    OrganizacionesService.getStatisticalData(desde, hasta)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => setIsLoading(false));
    OrganizacionesService.getStatisticalDataByOrg(desde, hasta)
      .then((response) => {
        const data = response.data;
        setRanking({
          info: data.map((v) => v.organization),
          comments: data.map((v) => v.quantity),
          abbreviations: data.map((v) => v.abbreviation),
        });
      })
      .finally(() => setIsLoading(false));
  }, [desde, hasta]);

  function onDesdeChange(event) {
    const fecha = new Date(event.target.value);
    !hasta
      ? setDesde(fecha)
      : fecha.getTime() < hasta.getTime()
      ? setDesde(fecha)
      : snackbarStore.openSnackbar("Especifique una fecha anterior", "error");
  }

  function onHastaChange(event) {
    const fecha = new Date(event.target.value);
    fecha.setDate(fecha.getDate() + 1);
    !desde
      ? setHasta(fecha)
      : fecha.getTime() > desde.getTime()
      ? setHasta(fecha)
      : snackbarStore.openSnackbar("Especifique una fecha posterior", "error");
  }

  function handleClick(type) {
    setIsLoading(true);
    OrganizacionesService.getStatisticalDataByOrgType(type, desde, hasta)
      .then((response) => {
        setDetail(response.data);
        setAbrirDialogo(true);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      <EstadisticaView
        url={props.match.url}
        Pie={<Pie data={pieData} onClick={handleClick} />}
        Tablero={<Tablero data={data} />}
        Ranking={
          <Bar
            info={ranking.info}
            comments={ranking.comments}
            abbreviations={ranking.abbreviations}
          />
        }
        DateRange={
          <>
            <RangeDateField label="Desde" onChange={onDesdeChange} />
            <RangeDateField label="Hasta" onChange={onHastaChange} />
          </>
        }
        isLoading={isLoading}
      />
      <EstadisticaDialog
        Table={abrirDialogo ? <DetailTable data={detail} /> : null}
        open={abrirDialogo}
        onClose={() => setAbrirDialogo(false)}
      />
    </>
  );
}

export default Estadisticas;
