import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React from "react";
import { useHistory } from "react-router-dom";
import cnfc from "../images/solologocnfc.png";
import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";
import { axiosUpdateUserPasswordElearning } from "../utils/axiosElearning";

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  txtaviso: {
    textAlign: "center",
    color: "#00529c",
    fontSize: "15px",
  },
  registrarse: {
    marginTop: "14px",
    textAlign: "left",
    fontSize: "19px",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "10px",
  },
  txtlink: {
    textDecoration: "none",
    color: "#00529c",
    fontWeight: "700",
  },
});

function NuevaContrasena(props) {
  const history = useHistory();
  const moodleId = props.match.params.moodleId;
  const userHash = props.match.params.userHash;
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const actualizarContrasenaBackend = () => {
    const payload = { hash: userHash, contrasena: password };
    axios
      .post("usuarios/nuevacontrasena", payload, {
        "Content-Type": "application/json",
      })
      .then((resp) => {
        snackbarStore.openSnackbar(
          "Contraseña actualiada correctamente.",
          "success"
        );
        history.push("/login");
        window.location.reload();
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al querer cambiar la contraseña",
          "error"
        );
      });
  };

  const handleNuevaContrasena = () => {
    if (password != "" && password2 != "") {
      if (password === password2) {
        if (moodleId) {
          const payloadElearning = new FormData();
          payloadElearning.append("users[0][id]", parseInt(moodleId));
          payloadElearning.append("users[0][password]", password);
          axiosUpdateUserPasswordElearning
            .post("", payloadElearning, { "Content-Type": "application/json" })
            .then((resp) => resp.data)
            .then((resp) => {
              if (!resp) {
                actualizarContrasenaBackend();
              } else if (resp.debuginfo) {
                snackbarStore.openSnackbar(resp.debuginfo, "error", 60000);
              } else if (resp.errorcode) {
                snackbarStore.openSnackbar(resp.errorcode, "error", 60000);
              } else {
                snackbarStore.openSnackbar(
                  "Error al intentar cambiar la contraseña en el Moodle",
                  "error",
                  60000
                );
              }
            });
        } else {
          actualizarContrasenaBackend();
        }
      } else {
        snackbarStore.openSnackbar("Las contraseñas no coinciden", "error");
      }
    } else {
      snackbarStore.openSnackbar(
        "Por favor, especfíque una contraseña",
        "error"
      );
    }
  };

  const { classes } = props;

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <a href="https://www.cnfc.gov.py">
            <img width="350px" src={cnfc} alt="CNFC" />
          </a>

          <p className={classes.txtaviso}>Especifique una nueva contraseña.</p>

          <FormControl margin="dense" fullWidth variant="outlined">
            <InputLabel htmlFor="contrasena">Contraseña</InputLabel>
            <OutlinedInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.inputS}
              id="contrasena"
              name="contrasena"
              type={values.showPassword ? "text" : "password"}
              label="Contraseña"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl margin="dense" fullWidth variant="outlined">
            <InputLabel htmlFor="repeatPassword">
              Confirmar contraseña
            </InputLabel>
            <OutlinedInput
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              className={classes.inputS}
              id="repeatPassword"
              name="repeatPassword"
              type={values.showPassword ? "text" : "password"}
              label="Confirmar contraseña"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            className={classes.submit}
            onClick={handleNuevaContrasena}
          >
            -ENVIAR-
          </Button>
        </Paper>
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(NuevaContrasena);
