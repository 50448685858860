import snackbarStore from "../stores/snackbar-store";
import axios from "../utils/axios";

const register = (username, email, password) => {
  return axios.post("signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post("jwt/token", {
      username,
      password,
      grantType: "password",
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.token}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;
    })
    .then(() =>
      axios.get("jwt/user", { data: {} }).then((response) => {
        if (response.data.estado === "Inactivo") {
        } else {
          localStorage.setItem("user", JSON.stringify(response.data));
          return response.data;
        }
      })
    );
};

const updateUser = () => {
  const t = getCurrentUserT();
  let user = {};
  axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
  axios.defaults.headers.common["Content-Type"] = `application/json`;
  axios.get("jwt/user", { data: {} }).then((response) => {
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(response.data));
    user = { ...response.data };
  });
};

const logout = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getCurrentUserT = () => {
  return JSON.parse(localStorage.getItem("userData"));
};

const checkCurrentUser = () => {
  const t = JSON.parse(localStorage.getItem("userData"));
  if (t) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
    axios.defaults.headers.common["Content-Type"] = `application/json`;

    axios.get("/jwt/verify", { data: {} }).then(
      (response) => {},
      (error) => {
        if (error) {
          logout();
          snackbarStore.openSnackbar(
            "Ha expirado su sesión, vuelva a iniciar su sesión. Por favor",
            "warning"
          );
          window.location.assign("/login/");
        }
      }
    );
  }
};

const iELogin = (token, state) => {
  return axios
    .post("auth/mitic", {
      token,
      state
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.token}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;
    })
    .then(() =>
      axios.get("jwt/user", { data: {} }).then((response) => {
        if (response.data.estado === "Activo") {
          localStorage.setItem("user", JSON.stringify(response.data));
          return response.data;
        }
      })
    );
}

export default {
  register,
  login,
  logout,
  getCurrentUser,
  checkCurrentUser,
  getCurrentUserT,
  updateUser,
  iELogin,
};
