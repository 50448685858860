import { observable, decorate } from "mobx";
import ListData from "../toolkit/mobx-connectors/observables/list-data";

class ArchivosStore {
  list = new ListData();
}

decorate(ArchivosStore, {
  list: observable
});

const archivosStore = new ArchivosStore();
export default archivosStore;
