import { useEffect, useState } from "react";
import useIsMobileScreen from "./useIsMobileScreen";

export function useLayout(defaultDrawerWidth) {
  const isMobile = useIsMobileScreen();
  const [drawerWidth, setDrawerWidth] = useState(
    isMobile ? 100 : defaultDrawerWidth
  );
  const [openMenu, setOpenMenu] = useState(!isMobile);
  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const isOpen = isMobile ? !openMenu : true;
    setOpenMenu(isOpen);
    setDrawerWidth(isOpen ? defaultDrawerWidth : 0);
  };

  useEffect(() => {
    setOpenMenu(!isMobile);
    setDrawerWidth(isMobile ? 0 : defaultDrawerWidth);
  }, [isMobile, defaultDrawerWidth]);
  return { drawerWidth, openMenu, isMobile, toggleDrawer };
}
