import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL = `${BASE_URL}/cnfcbackend/api`;
export const ASSETS_BASE_URL = `${BASE_URL}/assets/`;
export const ELEARNING_BASE_URL = process.env.REACT_APP_ELEARNING_BASE_URL;
export const ELEARNING_CREATE_TOKEN =
  process.env.REACT_APP_ELEARNING_CREATE_TOKEN;
export const ELEARNING_UPDATE_PASSWORD_TOKEN =
  process.env.REACT_APP_ELEARNING_UPDATE_PASSWORD_TOKEN;
export const ELEARNING_STATS_TOKEN =
  process.env.REACT_APP_ELEARNING_STATS_TOKEN;

export default axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000,
  headers: { "Content-type": "application/json" },
});

export const MITIC_AUTH_URL = process.env.REACT_APP_MITIC_AUTH_URL;
export const MITIC_AUTH_CLIENT_ID = process.env.REACT_APP_MITIC_AUTH_CLIENT_ID;
