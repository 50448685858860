import { observable, decorate, action } from "mobx";

class OrderData {
  orders = {};
  changeOrder(key) {
    let sort = !this.orders[key]
      ? "asc"
      : this.orders[key] === "desc"
        ? null
        : "desc";
    this.orders[key] = sort;
  }
}

decorate(OrderData, {
  orders: observable,
  changeOrder: action
});

export default OrderData;
