import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ElearningAppBar from "../../components/ElearningAppBar";
import MoodleStatsService from "../../services/moodle-service";
import snackbarStore from "../../stores/snackbar-store";
import RangeDateField from "../material/RangeDateField";
// import StatsByAge from "./StatsByAge";
import StatsBySector from "./StatsBySector";
// import StatsBySex from "./StatsBySex";

const useStyles = makeStyles((theme) => ({
  filtros: {
    display: "inline-block",
    marginTop: "0px",
    marginLeft: "auto",
    marginRight: "0px",
    borderRadius: "20px",
  },
  contenedorEstadistica: {
    borderRadius: "10px",
    border: "2px solid #E3E3E3",
    padding: "10px 10px 10px 10px",
  },
  contenedorEstadisticaTexto: {
    borderRadius: "10px",
    border: "2px solid #E3E3E3",
    padding: "10px 10px 10px 10px",
    textAlign: "center",
    height: "140px",
    color: "#00529c",
  },
  layout: {
    //width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  inputS: {
    borderRadius: "10px",
    marginLeft: "5px",
    marginTop: "0px",
    display: "inline-block",
    backgroundColor: "#FFFFFF",
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  paperBread: {
    width: "auto",
    display: "inline-block",
    fontSize: "16px",
    textDecoration: "none",
  },
  paperBreadContainer: {
    display: "flex",
  },
  txtlink: {
    textDecoration: "none",
    color: "#00529c",
  },
}));

function ElearningStats(props) {
  const styles = useStyles();
  const [stats, setStats] = useState(null);
  const [desde, setDesde] = React.useState(null);
  const [hasta, setHasta] = React.useState(null);
  const [loading, setLoading] = useState(false);

  function onDesdeChange(event) {
    if (!event.target.value) {
      setDesde(null);
      return;
    }
    const fecha = new Date(event.target.value);
    !hasta
      ? setDesde(fecha)
      : fecha.getTime() < hasta.getTime()
      ? setDesde(fecha)
      : snackbarStore.openSnackbar("Especifique una fecha anterior", "error");
  }

  function onHastaChange(event) {
    if (!event.target.value) {
      setHasta(null);
      return;
    }
    const fecha = new Date(event.target.value);
    fecha.setDate(fecha.getDate() + 1);
    !desde
      ? setHasta(fecha)
      : fecha.getTime() > desde.getTime()
      ? setHasta(fecha)
      : snackbarStore.openSnackbar("Especifique una fecha posterior", "error");
  }

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      try {
        const data = await MoodleStatsService.getStats(desde, hasta);
        setStats(data);
      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStats();
  }, [desde, hasta]);

  return (
    <ElearningAppBar>
      <main className={styles.layout}>
        <div className={styles.paperBreadContainer}>
          <Breadcrumbs aria-label="breadcrumb" className={styles.paperBread}>
            <a className={styles.txtlink} href="https://cursos.cnfc.gov.py/">
              E-Learning
            </a>
            <Link className={styles.txtlink} to={props.match.url}>
              <strong>Estadísticas</strong>
            </Link>
          </Breadcrumbs>

          <div className={styles.filtros}>
            <RangeDateField label="Desde" onChange={onDesdeChange} />
            <RangeDateField label="Hasta" onChange={onHastaChange} />
          </div>
        </div>

        <Paper className={styles.paper}>
          <Box sx={{ flexGrow: 1 }}>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="400px"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={styles.contenedorEstadistica}>
                    <h4>Personas capacitadas por sector</h4>
                    <StatsBySector stats={stats} />
                  </div>
                </Grid>
                {/* <Grid item xs={12} md={3} lg={3}>
                  <div className={styles.contenedorEstadistica}>
                    <h4>Personas capacitadas por sexo</h4>
                    <StatsBySex stats={stats} />
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <div className={styles.contenedorEstadistica}>
                    <h4>Personas capacitadas por rango etario</h4>
                    <StatsByAge stats={stats} />
                  </div>
                </Grid> */}

                <Grid item xs={12} md={4} lg={4}>
                  <div className={styles.contenedorEstadisticaTexto}>
                    <h1>{stats?.totalCourses || 0}</h1>
                    <h3>Cursos publicados</h3>
                  </div>
                </Grid>

                <Grid item xs={12} md={4} lg={2}>
                  <div className={styles.contenedorEstadisticaTexto}>
                    <h1>{stats?.totalUsers || 0}</h1>
                    <h3>Personas Capacitadas</h3>
                  </div>
                </Grid>

                <Grid item xs={12} md={4} lg={2}>
                  <div className={styles.contenedorEstadisticaTexto}>
                    <h1>{stats?.usersMultipleCourses || 0}</h1>
                    <h3>Personas Capacitadas más de 1 curso</h3>
                  </div>
                </Grid>
              </Grid>
            )}
          </Box>
        </Paper>
      </main>
    </ElearningAppBar>
  );
}

export default ElearningStats;
