import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    toolbarMain: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
    },
    mainFeaturedPost: {
      //backgroundColor: '#72616e',
      //color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
    },
    mainFeaturedPostContent: {
      padding: `${theme.spacing(6)}px`,
      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
      },
    },
    mainGrid: {
      marginTop: theme.spacing(3),
    },
    title: {
      color: '#e54b4b'
    },
    card: {
      display: 'flex',
    },
    cardDetails: {
      flex: 1,
    },
    cardMedia: {
      width: 160,
    },
    markdown: {
      padding: `${theme.spacing(3)}px 0`,
    },
    sidebarAboutBox: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
      marginTop: theme.spacing(3),
    }
});

class About extends React.Component {
  
  state = {};
  
  render() {

    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.layout}>
            <main>
            {/* Main featured post */}
            <Paper className={classes.mainFeaturedPost}>
                <Grid container>
                <Grid item md={11}>
                    <div className={classes.mainFeaturedPostContent}>
                      <Typography className={classes.title} component="h3" variant="h3" color="inherit" gutterBottom>
                          Acerca de
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                          ¿De qué se trata datos.vue.gov.py ?
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                          Los Datos Abiertos pueden ser libremente usados, re-usados y redistribuidos por cualquiera, sujeto solamente, a lo sumo, a requisitos de atribución y redistribución con la misma licencia. <br/>
                          Los Datos Abiertos Gubernamentales son un medio fundamental para fomentar la transparencia, facilitando a los ciudadanos el acceso a la información de la gestión del Gobierno. Los ciudadanos pueden así empoderarse de los procesos de decisión, ayudando al gobierno en la toma de mejores decisiones y colaborando en la propuesta de soluciones.
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                          ¿Cómo puedo usar los datos?
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                          Con los Datos Abiertos se pueden crear visualizaciones innovativas para comprender mejor datos complejos, fomentando la creación de nuevos conocimientos. También puede ser la base de oportunidades de negocios convirtiéndose en un importante motor económico.
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                          ¿Quiénes están involucrados en el proyecto?
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                          datos.vue.gov.py está desarrollado por la Ventanilla Única del Exportador (VUE) del Ministerio de Industria y Comercio (MIC).
                      </Typography>
                      <Typography className={classes.title} component="h3" variant="h6" color="inherit" gutterBottom>
                          ¿Quién es parte del equipo técnico?
                      </Typography>
                      <Typography variant="body1" color="inherit" paragraph>
                        Las personas trabajando en el portal son: Camilo Báez (VUE), Joan Ruax (VUE), Celso Bareiro (VUE), Denise Trevisan (VUE).<br/>
                        {/* Las visualizaciones de datos están basadas en frameworks de Google Developers y las librerias d3js.<br/> */}
                        En caso de necesidad, puedes contactar al administrador del sistema enviando un correo electrónico a: datos@vue.gov.py<br/>
                      </Typography>
                    </div>
                </Grid>
                </Grid>
            </Paper>
            {/* End main featured post */}
            </main>
        </div>
      </React.Fragment>
    );
  }
}

About.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(About);
