import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  Paper,
  TextField,
  withStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import React, { useState } from "react";
//import snackbarStore from "../../stores/snackbar-store";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import AuthService from "../../services/auth-service";
import descriptorService from "../../services/descriptorservice";
import organismoService from "../../services/organismoservice";
import snackbarStore from "../../stores/snackbar-store";
import axios from "../../utils/axios";
import DenseFormOutlinedInput from "../material/DenseFormOutlinedInput";
import LinearProgress from "@material-ui/core/LinearProgress";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

let content;

const denormalize = (data) => {
  const target = {
    titulo: data.tituloDocumento || "",
    fecha_publicacion:
      data.tipo === "Normativa Anticipada" ? Date.now() : data.fecha,
    fecha_actualizacion:
      data.tipo === "Normativa Anticipada" ? Date.now() : data.fecha,
    fecha_limite: data.fecha || "",
    tipo_documento: data.tipoDocumento?.id || "",
    estado: data.tipo === "Normativa Anticipada" ? "En Curso" : "Vigente",
    content: content[1],
    archivoExtension: data.archivoExtension,
    tipo_actividad: data.tipo_actividad || {},
    numeroNormativa: data.numeroNormativa,
    asiento: data.asiento,
    tituloAnexo: data.tituloAnexo,
    fechaEmisionSancion: data.fechaEmisionSancion,
    fechaPromulgacion: data.fechaPromulgacion,
    cantidadArticulos: data.cantidadArticulos,
    normasRelacionadas: data.normasRelacionadas,
    documentoDescriptorList: data.documentoDescriptorList?.map((x) => x.id),
    observaciones: data.observaciones,
    enlaceTextoOriginal: data.enlaceTextoOriginal,
    internalizaNormativaInternacional: data.internalizaNormativaInternacional,
    asientoNormativaQueInternaliza: data.asientoNormativaQueInternaliza,
    tiuloNormativaInternacional: data.tiuloNormativaInternacional,
    organismoInternacionalEmisor: data.organismoInternacionalEmisor?.id,
    vigente: data.vigente,
    fuente: data.fuente,
  };

  return target;
};

const styles = (theme) => ({
  layout: {
    width: "auto",
    //display: "flex",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginbotton: theme.spacing(100),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),

    //display: "row",
    //flexDirection: "column",
    //alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      2
    )}px`,
  },
  container: {
    display: "flex",
    direction: "row",
    alignItems: "center",
  },
  subtittle: {
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    //padding: "2rem",
    marginTop: theme.spacing(2),
    textDecoration: "none",
    borderRadius: "10px",
  },
  inputS: {},
  txttitulo: {
    textAlign: "left",
    color: "#0E1820",
    fontSize: "20px",
  },
  registrarse: {
    textAlign: "left",
  },
  txtlink: {
    textDecoration: "none",
    color: "#0E1820",
  },
  paperBread: {
    width: "100%",
    display: "inline",
    fontSize: "16px",
    textDecoration: "none",
    marginTop: "100px",
  },
});

const CrearDocumento = (props) => {
  const history = useHistory();

  const [tipoDoc, setTipoDocumento] = React.useState([]);
  const [listDescriptores, setListDescriptores] = React.useState([]);
  const [listOrganismos, setListOrganismos] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("tipodocumento", {
        data: {},
      })
      .then((resp) => setTipoDocumento(resp.data.data))
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado organizaciones",
          "error"
        );
      });
    descriptorService
      .getAll()
      .then((resp) => setListDescriptores(resp.data.data));
    organismoService.getAll().then((resp) => setListOrganismos(resp.data.data));
  }, []);

  const [tipoActi, setTipoActividad] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("tipoactividad", {
        data: {},
      })
      .then((resp) => setTipoActividad(resp.data.data))
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado organizaciones",
          "error"
        );
      });
  }, []);

  const currentUser = AuthService.getCurrentUser();
  var reader = new FileReader();
  const { classes } = props;
  const [archivo, setArchivo] = useState({ undefined });
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size > 52428800) {
      snackbarStore.openSnackbar(
        "El tamaño del archivo no debe ser mayor a 50 MB.",
        "error"
      );
    } else {
      reader.readAsDataURL(fileUploaded);
      reader.onload = function () {
        const base64 = reader.result;
        content = base64.split(",");
        setArchivo(fileUploaded);
        const filename = fileUploaded.name;
        const pieces = filename.split(".");
        const extension = pieces[pieces.length - 1];
        formik.setFieldValue("adjuntar", fileUploaded.name);
        formik.setFieldValue("archivo", fileUploaded.name);
        formik.setFieldValue("archivoExtension", extension);
      };
    }
  };

  //opciones para tipo de documento, y su handleChange
  const tipoNormativas = ["Normativa Anticipada", "Normativa Vigente"];
  const [values, setValues] = React.useState({
    vigente: false,
  });
  const formik = useFormik({
    initialValues: {
      tipo: "",
      tituloDocumento: "",
      fecha: "",
      adjuntar: "",
      archivoExtension: "",
      tipoDocumento: null,
      tipoActividad: [],
      numeroNormativa: "",
      asiento: "",
      tituloAnexo: "",
      fechaEmisionSancion: "",
      cantidadArticulos: "",
      normasRelacionadas: "",
      documentoDescriptorList: [],
      observaciones: "",
      enlaceTextoOriginal: "",
      internalizaNormativaInternacional: "",
      asientoNormativaQueInternaliza: "",
      tiuloNormativaInternacional: "",
      organismoInternacionalEmisor: "",
      vigente: "",
      fuente: "",
    },
    validationSchema: Yup.object({
      tipo: Yup.string().required("El Tipo de Normativa es requerido"),
      adjuntar: Yup.string().required("El archivo es requerido"),
      tituloDocumento: Yup.string().required("El título es requerido"),
      fecha: Yup.date().required("La fecha es requerida"),
      tipoDocumento: Yup.object()
        .nullable()
        .required("El tipo de documento es requerido"),
      tipoActividad: Yup.array()
        .of(Yup.object())
        .min(1, "El tipo de actividad es requerido")
        .required("El tipo de actividad es requerido"),
    }),
    onSubmit: (formdata) => {
      var ids = [];
      for (var i in formdata.tipoActividad) {
        ids.push(formdata.tipoActividad[i].id);
      }

      formik.values.tipo_actividad = ids;

      const payload = denormalize(formdata);
      const t = AuthService.getCurrentUserT();

      axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
      axios.defaults.headers.common["Content-Type"] = `application/json`;

      axios
        .post("documento/nuevo", payload, {
          "Content-Type": "application/json",
        })
        .then((resp) => {
          snackbarStore.openSnackbar(
            "Documento guardado exitosamente.",
            "success"
          );
          history.push("/");
          window.location.reload();
        })
        .catch(() => {
          snackbarStore.openSnackbar("Error al guardar el documento", "error");
        });
    },
  });

  return (
    <main className={classes.layout}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.paperBread}>
        <Link to="/" className={classes.txtlink}>
          Consulta Pública de Normativas
        </Link>
        <Link to="#" className={classes.txtlink}>
          Subir y moderar
        </Link>
        <Link to="#" className={classes.txtlink}>
          <strong>Gestionar documentos</strong>
        </Link>
      </Breadcrumbs>

      <Paper className={classes.paper}>
        <LinearProgress color="primary" hidden={!formik.isSubmitting} />
        <div align="left">
          <h3 style={{ color: "#0E1820" }}>Crear nuevo documento</h3>
        </div>

        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container className={classes.container} spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl margin="dense" fullWidth variant="outlined">
                <Autocomplete
                  id="tipo"
                  name="tipo"
                  size="small"
                  value={formik.values.tipo}
                  options={tipoNormativas}
                  onChange={(_, newTiponormativa) => {
                    formik.setFieldValue("tipo", newTiponormativa);
                    if (newTiponormativa === "Normativa Vigente") {
                      setValues({ ...values, vigente: true });
                    } else if (newTiponormativa === "Normativa Anticipada") {
                      setValues({ ...values, vigente: false });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputS}
                      variant="outlined"
                      id="tipo"
                      name="tipo"
                      label="Tipo de Normativa"
                      error={formik.errors.tipo && true}
                      helperText={formik.errors.tipo}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <DenseFormOutlinedInput
                label="Título del documento"
                name="tituloDocumento"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                margin="dense"
                variant="outlined"
                label={values.vigente ? "Fecha de Publicación" : "Fecha Límite"}
                value={formik.values.fecha}
                onChange={formik.handleChange}
                error={formik.errors.fecha && true}
                helperText={formik.errors.fecha}
                type="date"
                className={classes.inputS}
                id="fecha"
                name="fecha"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                disabled
                className={classes.inputS}
                margin="dense"
                value={currentUser.organizacion?.abreviatura}
                variant="outlined"
                id="organizacion"
                name="organizacion"
                label="Organismo y Entidad del Estado"
                labelwidth={100}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                margin="dense"
                variant="outlined"
                label="Adjuntar documento"
                id="adjuntar"
                name="adjuntar"
                fullWidth
                className={classes.inputS}
                value={archivo ? archivo.name : "vacio"}
                error={formik.errors.adjuntar && true}
                helperText={formik.errors.adjuntar}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Button onClick={handleClick}>
                        <AttachFileIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <input
                accept="application/pdf,.doc,.docx"
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <span style={{ fontSize: "11px" }}>
                {" "}
                *El documento debe estar en formato PDF o MS Word y no superar
                los 50MB
              </span>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <FormControl margin="dense" fullWidth className={classes.inputS}>
                <Autocomplete
                  id="tipoDocumento"
                  size="small"
                  options={tipoDoc}
                  value={formik.values.tipoDocumento}
                  onChange={(event, newTipoDocumento) => {
                    formik.setFieldValue("tipoDocumento", newTipoDocumento);
                  }}
                  getOptionLabel={(option) => option.descripcion ?? ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="tipoDocumento"
                      label="Tipo Documento"
                      error={formik.errors.tipoDocumento && true}
                      helperText={formik.errors.tipoDocumento}
                    />
                  )}
                />
              </FormControl>
              <span style={{ fontSize: "11px", opacity: 0 }}> "</span>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <FormControl margin="dense" fullWidth className={classes.inputS}>
                <Autocomplete
                  multiple
                  color="primary"
                  id="tipoActividad2"
                  options={tipoActi}
                  value={formik.values.tipoActividad}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("tipoActividad", [...newValue]);
                  }}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.descripcion}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.descripcion}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Tipo de Actividad"
                      placeholder="Tipo de Actividad"
                      error={formik.errors.tipoActividad && true}
                      helperText={formik.errors.tipoActividad}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Número de Normativas"
                name="numeroNormativa"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Asiento"
                name="asiento"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Título de Anexo"
                name="tituloAnexo"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                margin="dense"
                variant="outlined"
                label="Fecha de Emisión/Sanción"
                value={formik.values.fechaEmisionSancion}
                onChange={formik.handleChange}
                error={formik.errors.fechaEmisionSancion && true}
                type="date"
                className={classes.inputS}
                id="fechaEmisionSancion"
                name="fechaEmisionSancion"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Cantidad de Artículos"
                name="cantidadArticulos"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl margin="dense" fullWidth className={classes.inputS}>
                <Autocomplete
                  multiple
                  color="primary"
                  id="descriptores"
                  options={listDescriptores}
                  value={formik.values.documentoDescriptorList}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                  onChange={(_, newValue) => {
                    formik.setFieldValue("documentoDescriptorList", [
                      ...newValue,
                    ]);
                  }}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.descripcion}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.descripcion}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Descriptores"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Observaciones"
                name="observaciones"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Enlace al Texto Original"
                name="enlaceTextoOriginal"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Internaliza Normativa Internacional"
                name="internalizaNormativaInternacional"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Asiento de la Normativa que Internaliza"
                name="asientoNormativaQueInternaliza"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Título de la Normativa Internacional"
                name="tiuloNormativaInternacional"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {currentUser?.organismoInternacional && (
                <>
                  <input
                    type="hidden"
                    name="organismoInternacionalEmisor"
                    value={currentUser.organismoInternacional}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    label="Organismo Internacional"
                    margin="dense"
                    variant="outlined"
                    disabled
                    id="organismoInternacionalEmisor"
                    value={currentUser.organismoInternacional?.abreviatura}
                    className={classes.inputS}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              )}
              {!currentUser?.organismoInternacional && (
                <FormControl
                  margin="dense"
                  fullWidth
                  className={classes.inputS}
                >
                  <Autocomplete
                    id="organismos"
                    size="small"
                    options={listOrganismos}
                    value={formik.values.organismoInternacionalEmisor}
                    onChange={(_, newValue) => {
                      formik.setFieldValue(
                        "organismoInternacionalEmisor",
                        newValue
                      );
                    }}
                    getOptionSelected={(option, value) =>
                      option?.id === value?.id
                    }
                    getOptionLabel={(option) => option.descripcion ?? ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label="Organismos"
                      />
                    )}
                  />
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl margin="dense" fullWidth variant="outlined">
                <Autocomplete
                  id="vigente"
                  size="small"
                  options={["Sí", "No"]}
                  onChange={(_, vigente) =>
                    formik.setFieldValue("vigente", vigente)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Vigente" />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <DenseFormOutlinedInput
                label="Fuente"
                name="fuente"
                formik={formik}
              />
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6} md={2}>
                <Link to="/" className={classes.button}>
                  <Button
                    className={classes.submit}
                    fullWidth
                    variant="outlined"
                    //size="large"
                    color="primary"
                  >
                    Cancelar
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={6} md={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress color="secondary" size="1.8em" />
                  ) : (
                    "ENVIAR"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default withStyles(styles)(CrearDocumento);
