import React from 'react';
//import React, {useState} from 'react';
import VueAppBar from '../VueAppbar2';
//import {Button,Checkbox,FormControlLabel,FormControl,InputAdornment,IconButton,OutlinedInput} from "@material-ui/core";
//import {Visibility,VisibilityOff} from '@material-ui/icons';
//import {InputLabel,Paper,Typography,withStyles} from "@material-ui/core";
//import snackbarStore from "../../stores/snackbar-store";
import SubirVersion from "./subirNuevaVersion";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "auto",
        display: "flex",
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: '100%',
            marginLeft: "auto",
            marginRight: "auto"
        }

    },
}))



const NewVersion = props => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <VueAppBar>
                <div className={classes.root}>
                    <SubirVersion idDocumento={props.match.params.idDocumento} />
                </div>
            </VueAppBar>
        </React.Fragment>
    );

};


export default (NewVersion);
