import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import AuthService from "../../services/auth-service";
import snackbarStore from "../../stores/snackbar-store";
import { getOptionSelected } from "../../utils/autocomple-utils";
import axios from "../../utils/axios";

const denormalize = (data, post) => {
  const target = {
    id: parseInt(idUsuario, 10),
    nombre_apellido: data.nombre_apellido || post.nombre_apellido,
    email: data.email || post.email,
    cargo: data.cargo || "",
    tipo_documento: data.tipo_documento || post.tipo_documento,
    nro_documento: data.nro_documento || post.nro_documento,
    telefono: data.telefono || post.telefono,
    contrasena: data.contrasena || "",
    organizacion: data.organizacion?.id || post.organizacion?.id,
    organismoInternacional:
      data.organismoInternacional?.id || post.organismoInternacional?.id,
  };

  return target;
};

//para tipo de documento personal del usuario
const tipoDocumentos = ["CI", "RUC"];

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  subtittle: {
    textAlign: "center",
  },
  registrarse: {
    textAlign: "left",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  inputS: {
    borderRadius: "6px",
  },
  txttitulo: {
    textAlign: "right",
    color: "#00529c",
    fontSize: "20px",
  },
});

const goBack = () => {
  window.history.back();
};

let idUsuario;

const editarUsuarioInstitucional = (props) => {
  idUsuario = props.match.params.idUsuario;
  const [org, setOrg] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      nombre_apellido: "",
      email: "",
      cargo: "",
      telefono: "",
      nro_documento: "",
      tipo_documento: "",
      organizacion: null,
      contrasena: "",
      repeatPassword: "",
    },
    validationSchema: Yup.object({
      nombre_apellido: Yup.string(),
      email: Yup.string().email("Debe ser un email valido"),
      cargo: Yup.string(),
      organizacion: Yup.object()
        .nullable()
        .required("La organizacion es requerida"),
      contrasena: Yup.string(),
      repeatPassword: Yup.string(),
    }),
    onSubmit: (formdata) => {
      if (
        formik.values.contrasena === formik.values.repeatPassword ||
        (formik.values.contrasena !== "" && formik.values.repeatPassword !== "")
      ) {
        const payload = denormalize(formdata, formik.values);
        const t = AuthService.getCurrentUserT();
        axios.defaults.headers.common["Authorization"] = `Bearer ${t.token}`;
        axios.defaults.headers.common["Content-Type"] = `application/json`;
        axios.put("usuarios/modificacion", payload).then((resp) => {
          setValidado(true);
          //window.location.href = //opcion 1 camilo
          //history.push('/auth'); //opcion 2 camilo
        });
      } else {
        snackbarStore.openSnackbar("Las Contraseñas no son iguales", "error");
      }
    },
  });

  useEffect(() => {
    axios
      .get(`usuario/${idUsuario}`, {
        data: {},
      })
      .then((resp) => {
        const value = {
          nombre_apellido: resp.data.nombre_apellido,
          email: resp.data.email,
          cargo: resp.data.cargo,
          telefono: resp.data.telefono,
          nro_documento: resp.data.nro_documento,
          tipo_documento: resp.data.tipo_documento,
          organizacion: resp.data.organizacion,
          contrasena: "",
          repeatPassword: "",
        };
        formik.setValues(value);
      })
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener los datos del servidor",
          "error"
        );
      });
  }, []);

  React.useEffect(() => {
    axios
      .get("organizaciones/basic", {
        data: {},
      })
      .then((resp) => setOrg(resp.data))
      .catch(() => {
        snackbarStore.openSnackbar(
          "Error al obtener el listado organizaciones",
          "error"
        );
      });
  }, []);

  const { classes } = props;

  const [validado, setValidado] = useState(false);
  const [values, setValues] = useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  //para tipo de documento personal del usuario

  //let history = useHistory();

  if (validado) {
    return (
      <div>
        <Redirect
          to={{
            pathname: "/usuarios",
            state: {},
          }}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <h2 className={classes.txttitulo}>Editar Usuarios Institucionales</h2>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Nombre y Apellido"
              value={formik.values.nombre_apellido}
              onChange={formik.handleChange}
              error={formik.errors.nombre_apellido && true}
              helperText={formik.errors.nombre_apellido}
              id="nombre_apellido"
              name="nombre_apellido"
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email && true}
              helperText={formik.errors.email}
              id="email"
              name="email"
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Cargo"
              value={formik.values.cargo}
              onChange={formik.handleChange}
              error={formik.errors.cargo && true}
              helperText={formik.errors.cargo}
              id="cargo"
              name="cargo"
            />

            <FormControl
              margin="dense"
              fullWidth
              variant="outlined"
              className={classes.inputS}
            >
              <Autocomplete
                id="tipo_documento"
                name="tipo_documento"
                size="small"
                options={tipoDocumentos}
                value={formik.values.tipo_documento}
                onChange={(_, tipo) =>
                  formik.setFieldValue("tipo_documento", tipo)
                }
                getOptionSelected={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.inputS}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    label="Tipo de Documento"
                    error={formik.errors.tipo_documento && true}
                    helperText={formik.errors.tipo_documento}
                  />
                )}
              />
            </FormControl>

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label="Nro documento"
              value={formik.values.nro_documento}
              onChange={formik.handleChange}
              error={formik.errors.nro_documento && true}
              helperText={formik.errors.nro_documento}
              id="nro_documento"
              name="nro_documento"
            />

            <FormControl
              margin="dense"
              fullWidth
              variant="outlined"
              className={classes.inputS}
            >
              <Autocomplete
                id="organizacion"
                name="organizacion"
                size="small"
                options={org}
                value={formik.values.organizacion}
                onChange={(_, organizacion) =>
                  formik.setFieldValue("organizacion", organizacion)
                }
                getOptionLabel={(option) => option.abreviatura || ""}
                getOptionSelected={getOptionSelected}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    label="Organismo y Entidad del Estado"
                    error={formik.errors.organizacion && true}
                    helperText={formik.errors.organizacion}
                  />
                )}
              />
            </FormControl>

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              label="Teléfono"
              error={formik.errors.telefono && true}
              helperText={formik.errors.telefono}
              id="telefono"
              name="telefono"
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={formik.values.contrasena}
              onChange={formik.handleChange}
              label="Contraseña"
              error={formik.errors.contrasena && true}
              helperText={formik.errors.contrasena}
              id="contrasena"
              name="contrasena"
              type={values.showPassword ? "text" : "password"}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              label="Confirmar contraseña"
              error={formik.errors.repeatPassword && true}
              helperText={formik.errors.repeatPassword}
              id="repeatPassword"
              name="repeatPassword"
              type={values.showPassword ? "text" : "password"}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
            >
              Guardar Cambios
            </Button>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              onClick={goBack}
            >
              CANCELAR
            </Button>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default withStyles(styles)(editarUsuarioInstitucional);
