import axios from "axios";

import { ELEARNING_BASE_URL, ELEARNING_STATS_TOKEN } from "../http-common";

const STATS_FUNCTIONS = {
  GET_COURSES: "core_course_get_courses",
  GET_USERS: "core_enrol_get_enrolled_users",
  GET_SITE_INFO: "core_webservice_get_site_info",
};

class MoodleStatsService {
  constructor() {
    this.axios = axios.create({
      baseURL: ELEARNING_BASE_URL,
    });
  }

  // Método auxiliar para construir URLs
  buildURL(wsfunction) {
    return `server.php?wstoken=${ELEARNING_STATS_TOKEN}&moodlewsrestformat=json&wsfunction=${wsfunction}`;
  }

  // Obtener todos los cursos
  async getCourses() {
    const url = this.buildURL(STATS_FUNCTIONS.GET_COURSES);
    const response = await this.axios.get(url);
    return response.data ?? [];
  }

  // Obtener usuarios de un curso específico
  async getCourseUsers(courseId) {
    const url = this.buildURL(STATS_FUNCTIONS.GET_USERS);
    const response = await this.axios.get(url, {
      params: { courseid: courseId },
    });
    return response.data;
  }

  // Calcular rango de edad
  getAgeRange(birthDate) {
    if (!birthDate) return "No especificado";
    const age = new Date().getFullYear() - new Date(birthDate).getFullYear();
    const ranges = [
      { max: 18, label: "0-18" },
      { max: 25, label: "19-25" },
      { max: 35, label: "26-35" },
      { max: 45, label: "36-45" },
      { max: 55, label: "46-55" },
      { max: Infinity, label: "56+" },
    ];

    return ranges.find((range) => age <= range.max)?.label || "No especificado";
  }

  getCustomFieldValue(user, shortname) {
    return (
      user.customfields?.find((field) => field.shortname === shortname)
        ?.value || "No especificado"
    );
  }

  isUserInDateRange(user, desde, hasta) {
    if (!desde && !hasta) return true;

    const userFirstAccess = user.firstaccess * 1000; // Convertir a milisegundos
    const userLastAccess = user.lastaccess * 1000; // Convertir a milisegundos

    if (desde && hasta) {
      return (
        userLastAccess >= desde.getTime() && userFirstAccess <= hasta.getTime()
      );
    } else if (desde) {
      return userLastAccess >= desde.getTime();
    } else if (hasta) {
      return userFirstAccess <= hasta.getTime();
    }

    return true;
  }

  // Obtener todas las estadísticas
  async getStats(desde = null, hasta = null) {
    try {
      const courses = await this.getCourses();

      const stats = {
        totalCourses: courses.length,
        totalUsers: 0,
        usersByGender: { male: 0, female: 0, other: 0 },
        usersByAge: {},
        usersMultipleCourses: 0,
        usersBySector: {
          Público: 0,
          Privado: 0,
          "No especificado": 0,
        },
      };

      const userCourses = new Map();
      const processedUsers = new Set(); // Para evitar contar usuarios duplicados

      for (const course of courses) {
        const users = await this.getCourseUsers(course.id);

        users.forEach((user) => {
          if (!this.isUserInDateRange(user, desde, hasta)) {
            return; // Skip este usuario si no está en el rango
          }

          // Solo procesamos cada usuario una vez para las estadísticas
          if (!processedUsers.has(user.id)) {
            // Procesar sector
            const sector = this.getCustomFieldValue(user, "tipo_sector");
            stats.usersBySector[sector] =
              (stats.usersBySector[sector] || 0) + 1;

            // Procesar género
            const gender = this.getCustomFieldValue(user, "gender");
            const genderMapped =
              gender === "No especificado"
                ? "No especificado"
                : gender.toLowerCase() === "male"
                ? "masculino"
                : gender.toLowerCase() === "female"
                ? "femenino"
                : "No especificado";
            stats.usersByGender[genderMapped] =
              (stats.usersByGender[genderMapped] || 0) + 1;

            // Procesar edad
            const birthdate = this.getCustomFieldValue(user, "birthdate");
            const ageRange =
              birthdate === "No especificado"
                ? "No especificado"
                : this.getAgeRange(birthdate);
            stats.usersByAge[ageRange] = (stats.usersByAge[ageRange] || 0) + 1;

            processedUsers.add(user.id);
          }

          // Trackear cursos por usuario
          if (!userCourses.has(user.id)) {
            userCourses.set(user.id, new Set());
          }
          userCourses.get(user.id).add(course.id);
        });
      }

      stats.totalUsers = userCourses.size;
      stats.usersMultipleCourses = Array.from(userCourses.values()).filter(
        (courses) => courses.size > 1
      ).length;

      return stats;
    } catch (error) {
      console.error("Error al obtener estadísticas:", error);
      throw error;
    }
  }
}

export default new MoodleStatsService();
